<template>
  <!-- class="breadcrumb is-small is-centered has-background-primary has-text-white py-2 mb-0" -->
  <nav
    class="breadcrumb is-small is-centered has-background-primary has-text-white py-2 mb-0"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="openSidePanel('scenario')">Climate:</a>{{ climate }}</li>
      <li>
        <a @click="openSidePanel('scenario')">Forest Management:</a
        >{{ forestMgmt }}
      </li>
      <li><a @click="openSidePanel('scenario')">Year:</a>{{ year }}</li>
      <li><a @click="openSidePanel('layers')">Layer:</a>{{ layer }}</li>
    </ul>
  </nav>
</template>

<script>
// scenario store
import theStore from '../store/store.js'

export default {
  methods: {
    openSidePanel(whichPanel) {
      this.$emit('openSidePanel', whichPanel)
    }
  },
  computed: {
    climate() {
      return theStore.store.state.climate.name
    },
    forestMgmt() {
      return theStore.store.state.forestMgmt.name
    },
    year() {
      return theStore.store.state.year.name
    },
    layer() {
      return theStore.store.state.layer.name
    }
  },
  emits: ['openSidePanel']
}
</script>

<style scoped>
li {
  padding-right: 6px;
}
.breadcrumb {
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
</style>
