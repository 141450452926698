<template>
  <Head>
    <title>ForCAST: Forest Change Assessment Simulation Tool</title>
    <meta
      name="description"
      content="Mapping tool providing Minnesota forestry professionals and land managers future planning information"
    />
    <meta
      name="author"
      content="University of Minnesota, Natural Resources Research Institute"
    />
  </Head>
  <!-- <header class="has-background-warning has-text-centered" style="z-index: 10">
    <h2 class="is-size-5 has-text-black">Beta Testing - In Progress</h2>
  </header> -->
  <div id="skip"><a href="#maincontent">Skip to main content</a></div>
  <fo-header :class="{ tool: $route.name == 'Tool' }"></fo-header>
  <section
    id="maincontent"
    class="main column"
    :class="{ tool: $route.name == 'Tool', home: $route.name == 'Home' }"
    role="main"
  >
    <router-view
      :agreesToTerms="agreesToTerms"
      @agreed-to-terms="agreedToTerms"
      :showTour="showToolTour"
      v-slot="{ Component }"
    >
      <!-- 
        make sure Tool doesn't reset on navigation
        https://stackoverflow.com/a/65760822/6072959
       -->
      <keep-alive include="Tool">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </section>
  <fo-footer v-if="$route.name != 'Tool'"></fo-footer>
</template>

<script>
import FoHeader from './components/Header.vue'
import FoFooter from './components/Footer.vue'
// eslint-disable-next-line no-unused-vars
import { Head } from '@vueuse/head'

export default {
  name: 'App',
  components: {
    FoHeader,
    FoFooter,
    Head
  },
  data() {
    return {
      agreesToTerms: false,
      showToolTour: false
    }
  },
  mounted() {
    // Calculate viewport height (vh) based on actual window size.
    // This deals with iOS bug that includes address bar for vh, causing content overflow
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    // We listen to the resize event in case window is resized to new height
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  },
  methods: {
    agreedToTerms() {
      this.agreesToTerms = true
      console.log('update: agrees to terms:', this.agreesToTerms)
      this.showToolTour = true
    }
  }
}
</script>

<style>
/* 
  This body style is getting applied universally (across all pages) 
  Should confirm this is what we want
*/

body {
  padding: 0;
  margin: 0;
}
.is-fullheight-100vh {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
section.main.tool {
  padding-top: 0;
  padding-bottom: 0;
}
section.main.home {
  background-color: #f9f7f6 !important;
}
header.tool {
  padding-bottom: 0; /* note: this style should only be applied to map page; currently everywhere */
}
#skip a {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 10px;
}

#skip a:focus {
  position: static;
  width: auto;
  height: auto;
}
@media screen and (max-width: 1000px) {
  section.main.home {
    padding-top: 0;
    margin-top: -0.75rem;
  }
}
</style>
