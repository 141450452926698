<template>
  <Head>
    <title>ForCAST Help</title>
    <meta name="description" content="" />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">Help</h1>
      <p>
        For specific questions about how the ForCAST models were built, visit
        the
        <router-link to="/project-overview"
          >project overview description</router-link
        >
        and individual methods pages:
      </p>
      <ul>
        <li>
          <router-link to="/landscape-modeling"
            >Forest Landscape Modeling</router-link
          >
          (includes a discussion of the LANDIS-II model).
        </li>
        <li>
          <router-link to="/wildlife-modeling"
            >Wildlife Habitat Assessment</router-link
          >
        </li>
        <li>
          <router-link to="/water-modeling"
            >Water Quality and Quantity Modeling</router-link
          >
        </li>
        <li>
          <router-link to="/ecosystem-valuation"
            >Ecosystem Services Valuation</router-link
          >
        </li>
      </ul>
      <h2>Frequently Asked Questions</h2>
      <div class="container">
        <div class="card accordion-block">
          <a href="#" class="card-header" @click="toggleSubMenu($event)">
            Why must my AOI be at least 72 square miles?
            <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
          /></a>
          <div class="card-content">
            <p>
              Forest landscape change simulations were done using LANDIS-II: a
              Landscape Change Model. Model outputs predict large-scale changes
              across the landscape through time. The minimum Area of Interest is
              72 square miles due to concerns from the modeling teams that
              evaluations at smaller spatial scales would be artificially
              precise and could lead to incorrect conclusions about the effects
              of future changes in the forest landscape.
            </p>
          </div>
        </div>
        <div class="card accordion-block">
          <a href="#" class="card-header" @click="toggleSubMenu($event)"
            >What is HSI?
            <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
          /></a>
          <div class="card-content">
            <p>
              A HSI, or Habitat Suitability Index, is a quantitative estimate of
              the habitat suitability (quality) for a species. HSI values
              usually range from 0 to 1. One important thing to remember of HSI
              values is that they estimate habitat quality, but they do not
              estimate density or population size of a species.
            </p>
            <p>
              <router-link to="/wildlife-modeling"
                >More information on HSI calculations</router-link
              >
            </p>
          </div>
        </div>

        <div class="card accordion-block">
          <a href="#" class="card-header" @click="toggleSubMenu($event)"
            >How is Carbon Calculated?
            <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
          /></a>
          <div class="card-content">
            <p>
              Carbon is calculated by the LANDIS-II model. It includes only
              carbon contained within the above ground live biomass and is equal
              to 50% of the biomass by weight. The model does not account for
              carbon contained within belowground biomass, dead biomass, or
              forest products.
            </p>
            <p>
              <router-link to="/landscape-modeling"
                >More information on Landscape Modeling</router-link
              >
            </p>
          </div>
        </div>
      </div>

      <div class="block mt-5">
        If you don't see your question answered here or in the individual
        methods pages, then please
        <router-link to="/contact">contact us</router-link>.
      </div>
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  methods: {
    toggleSubMenu(item) {
      item.currentTarget.classList.toggle('is-open')
    }
  },
  components: {
    Head
  }
}
</script>

<style scoped>
a.card-header {
  text-decoration: none !important;
}
</style>
