<template>
  <div class="chart-description column is-one-third p-0">
    <p class="panel-heading">
      Charts
      <button class="delete is-pulled-right" @click="closeChartPanel">
        Close Panel
      </button>
    </p>
    <!-- chart type selection -->
    <!-- don't show if there is only one chart type available -->
    <div class="block px-3 mt-3 mb-0" v-if="chartTypes.length > 1">
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Available charts:</label>
        </div>
        <div class="control has-icons-left">
          <div class="select is-fullwidth is-small">
            <select v-model="type">
              <!-- placeholder -->
              <option value="" disabled hidden></option>
              <!-- dynamically render the remaining options -->
              <option
                v-for="option in chartTypes"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <span class="icon is-small is-left"
            ><fa :icon="['fa', 'chart-line']"
          /></span>
        </div>
      </div>
    </div>

    <!-- 
      descriptive summary 
      uses "median" value to compare how current scenario does for
      selected metric in comparison to baseline
      -->
    <div class="block pl-5 is-size-6-minus my-3">
      <div v-if="!isCategorical && !isEcon">
        Under the currently selected scenario
        <span class="has-text-grey">({{ getScenarioFormattedName }})</span>
        the <span v-if="isForest">mean </span
        ><span v-else-if="isWater">flow-weighted mean in </span
        ><span v-else>median </span>
        <span class="has-text-weight-semibold"
          >{{ layerUnitsDisplay }} for {{ layer.name }}
        </span>
        is forecasted to
        <span class="has-text-weight-semibold" v-if="isWater">
          {{ whichWay(layerStats, refStats) }} by
          {{ percentChangeAbs(layerStats, refStats) }}
        </span>
        <span class="has-text-weight-semibold" v-else-if="isForest">
          {{ whichWay(layerStats.mean, refStats.mean) }} by
          {{ percentChangeAbs(layerStats.mean, refStats.mean) }}
        </span>
        <span class="has-text-weight-semibold" v-else>
          {{ whichWay(layerStats.median, refStats.median) }} by
          {{ percentChangeAbs(layerStats.median, refStats.median) }}
        </span>
        relative to the baseline scenario
        <span class="has-text-grey">({{ refTitle }}{{ currentYear }})</span>.
      </div>

      <!-- descriptive summary for categorical data -->
      <div v-else-if="isCategorical">
        Under the currently selected scenario
        <span class="has-text-grey">({{ getScenarioFormattedName }})</span>
        these species are forecasted to change the most relative to the baseline
        scenario
        <span class="has-text-grey">({{ refTitle }}{{ currentYear }})</span>:
        <div class="block">
          <span class="is-underlined">Top three increases</span>
          <ul v-for="item in top3Increase" :key="item.y">
            <!-- y is the label -->
            <li class="has-text-success-dark">
              {{ item.y }}:
              {{ item.pctChange.toLocaleString('en', { style: 'percent' }) }}
            </li>
          </ul>
        </div>
        <div class="block">
          <span class="is-underlined">Top three decreases</span>
          <ul v-for="item in top3Decrease" :key="item.y">
            <!-- y is the label -->
            <li class="has-text-danger-dark">
              {{ item.y }}:
              {{ item.pctChange.toLocaleString('en', { style: 'percent' }) }}
            </li>
          </ul>
        </div>
      </div>

      <!-- economic valuation description -->
      <div v-else>
        <div class="block">
          Under the currently selected scenario
          <span class="has-text-grey">({{ getScenarioFormattedName }})</span>
          the overall willingness to pay is forecasted to
          <span class="has-text-weight-semibold">
            {{ whichWay(layerStats.sum, 0) }} by ${{ round(layerStats.sum, 2) }}
          </span>
          per household per month compared to the baseline scenario
          <span class="has-text-grey">({{ refTitle }}{{ currentYear }})</span>.
        </div>
        <div class="block">
          Here is a breakdown of the valuation by ecosystem service:
          <table id="econTable" class="mt-2">
            <tbody>
              <tr v-for="es in ecoservicesMap" :key="es.name">
                <td :style="{ color: es.chartColors.border }">
                  {{ es.title }}
                </td>
                <td
                  :style="{ color: es.chartColors.border }"
                  class="econAmount"
                >
                  ${{ roundWithPadding(layerStats[`${es.name}_value`], 2) }}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td class="econAmount">
                  ${{ roundWithPadding(layerStats.sum, 2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- export chart button -->
    <div class="field has-addons has-addons-centered mt-5">
      <div class="control">
        <button class="button" type="submit" @click="exportChart">
          <span class="icon"><fa :icon="['fa', 'download']" /></span
          ><span>Export Chart</span>
        </button>
      </div>
    </div>
  </div>
  <!-- end left third column -->

  <!-- charts -->
  <div class="chart-wrapper column is-two-thirds">
    <div v-if="type" class="block p-3">
      <!-- chart title -->
      <div class="">
        <p class="title is-6 has-text-centered">{{ this.title }}</p>
      </div>

      <!-- bar chart -->
      <bar-chart
        v-if="type == 'bar'"
        :current-scenario-data="layerData"
        :ref-scenario-data="refValues"
        :year="currentYear"
        :is-categorical="isCategorical"
        :chart-colors="chartColors"
        :ref-chart-colors="refColors"
        :cat-chart-data="catChartData"
        :layer-stats="layerStats"
        :layer-units-display="layerUnitsDisplay"
        :chart-labels="chartLabels"
        :chart-height="chartHeight"
        :precision="layerCat.sigdigs"
        ref="barChart"
      ></bar-chart>

      <!-- trend chart -->
      <line-chart
        v-if="type == 'timeline'"
        :current-scenario-data="layerData"
        :ref-scenario-data="refValues"
        :year="currentYear"
        :is-forest="isForest"
        :is-water="isWater"
        :is-econ="isEcon"
        :chart-colors="chartColors"
        :ref-chart-colors="refColors"
        :es-map="ecoservicesMap"
        :layer-units-display="layerUnitsDisplay"
        :chart-height="chartHeight"
        :precision="layerCat.sigdigs"
        ref="lineChart"
      ></line-chart>

      <!-- distribution stats - only show for bar/line charts -->
      <div
        v-if="isDistributionChart && !isCategorical"
        class="is-size-7 has-text-centered"
      >
        median: {{ round(layerStats.median, 0).toLocaleString() }} | median
        absolute deviation (mad):
        {{ round(layerStats.medianAbsoluteDeviation, 0).toLocaleString() }}
      </div>
    </div>
  </div>
</template>

<script>
// local chart imports
import BarChart from './BarChart.vue'
import LineChart from './LineChart.vue'

// simple statistics
import {
  extentSorted,
  medianSorted,
  modeSorted,
  mean,
  medianAbsoluteDeviation,
  extent as ssExtent
} from 'simple-statistics'

// utility functions
import utilities from '../../modules/utilities.js'

// map store
import theStore from '../../store/store.js'

export default {
  name: 'ChartPane',
  components: {
    BarChart,
    LineChart
  },
  props: {
    scenario: Object
  },
  mounted() {
    console.log('chart mounted', this.type)
  },
  created() {
    // set default chart type here since it's too late within data()
    this.type = this.isCategorical ? 'bar' : 'timeline'
    console.log('chart created', this.type)

    this.catChartData = this.calcCatChartData()
  },
  data() {
    return {
      debug: true,

      // chart type gets determined by user input
      // load bar chart initially
      type: this.isCategorical ? 'bar' : 'timeline',

      chartHeight: 130,

      // config options for reference data in charts
      refColors: {
        background: '#b3b3b3',
        border: '#8c8c8c'
      },

      // hardcoded reference scenario pretty name
      refTitle: 'Historic - No Change - ',

      catChartData: null
    }
  },
  computed: {
    layerData() {
      return theStore.store.state.layerData
    },
    layer() {
      return theStore.store.state.layer
    },
    layerCat() {
      return theStore.store.state.layerCat
    },
    currentYear() {
      return theStore.store.state.year.value
    },
    refValues() {
      return theStore.store.state.refData
    },

    // chart title
    title() {
      const desc = this.isDistributionChart ? 'Distribution' : '80-year Trend'
      return `${this.layer.name} ${desc}, ${this.currentYear}`
    },
    // chart file name - based on this.title
    exportFileName() {
      // remove all non-alphanumeric characters (commas, spaces, etc)
      return this.title.replace(/[^a-z0-9]/gi, '')
    },

    // categorical data type
    isCategorical() {
      return this.layer.type != 'continuous'
    },

    // water data category
    isWater() {
      return this.layerCat.value == 'w'
    },
    // forest data category
    isForest() {
      return this.layerCat.value == 'l' || this.layerCat.value == 'c'
    },
    // economic data category
    isEcon() {
      return this.layerCat.value == 'e'
    },
    // categorical data value mapping
    // valueMap() {
    //   return this.layer.valueMap.map(o => ({ ...o }))
    // },
    // colors for categorical data
    catColors() {
      return this.layer.colors
    },
    // ecosystem services mapping for econ data
    // add chart colors to data before returning it
    ecoservicesMap() {
      // only use for econ layer
      if (!this.isEcon) return []
      let tempArray = this.layer.esMap
      return tempArray.map(es => ({
        ...es,
        chartColors: utilities.getChartColorsFromScale(es.scale)
      }))
    },

    // what to display for layer units
    // display legend subtitle if it's declared, other use units long name
    layerUnitsDisplay() {
      return this.layer.legendSubTitle
        ? this.layer.legendSubTitle
        : this.layer.units.long
    },

    // how many clusters to group the data into
    // TODO: number of bins should probably be based on space available for chart
    // but also could be a user input
    bins() {
      return 10
    },

    chartTypes() {
      // allowed chart types for each type of data
      const continuousChartTypes = [
        { name: 'Bar', value: 'bar' },
        // { name: 'Polar Area', value: 'polarArea' },
        { name: 'Trend', value: 'timeline' }
      ]
      const categoricalChartTypes = [
        { name: 'Bar', value: 'bar' }
        // { name: 'Trend', value: 'timeline' }
        // { name: 'Pie', value: 'pie' }
      ]
      // gotta be a better way to do this;
      // this is why you shouldn't code after midnight
      if (this.isWater || this.isEcon) {
        return [{ name: 'Trend', value: 'timeline' }]
      } else {
        return this.isCategorical ? categoricalChartTypes : continuousChartTypes
      }
    },

    // is this is distribution type chart (eg. histograms using bar or area)
    isDistributionChart() {
      return this.type == 'bar'
    },

    // get top three increases for tree species
    top3Increase() {
      let data = this.catChartData.map(o => ({ ...o }))
      return data.sort((a, b) => b.pctChange - a.pctChange).slice(0, 3)
    },
    // get top three decreases for tree species
    top3Decrease() {
      let data = this.catChartData.map(o => ({ ...o }))
      return data.sort((a, b) => a.pctChange - b.pctChange).slice(0, 3)
    },

    // descriptive stats for current layer
    layerStats() {
      return this.descriptiveStats(
        this.valuesForYear(this.layerData, this.currentYear)
      )
    },

    // descriptive stats for current layer
    refStats() {
      // for econ data there is no reference
      if (this.isEcon) {
        return []
      } else {
        return this.descriptiveStats(
          this.valuesForYear(this.refValues, this.currentYear)
        )
      }
    },

    // return formatted name of current scenario
    getScenarioFormattedName() {
      return `${this.scenario.climate.name} - ${this.scenario.forestMgmt.name} - ${this.scenario.year.name}`
    },
    // return formatted name of current scenario, including the layer
    getScenarioPlusLayerFormattedName() {
      return `${this.scenario.climate.name} - ${this.scenario.forestMgmt.name} - ${this.scenario.year.name} - ${this.state.layer.name}`
    },

    // generate data for categorical trend chart
    // each tree species should be a separate "dataset"
    // with the data being the count of pixels for the tree for that year
    // catTrendData() {
    //   let valueMap = this.valueMap
    //   const years = utilities.range(2020, 2100, 10)
    //   // iterate over years
    //   const countCatYears = years.map(yr => {
    //     let valueMap = this.valueMap
    //     const values = this.valuesForYear(this.layerData, yr)
    //     values.forEach(val => {
    //       // loop over valueMap
    //       // find where value matches category
    //       let idx = valueMap.findIndex(cat => cat.value == val)
    //       // if category found update count
    //       if (idx >= 0) {
    //         // ref: https://stackoverflow.com/questions/13298232/how-to-idiomatically-initialize-to-zero-or-increment-a-property
    //         valueMap[idx].count = valueMap[idx].count + 1 || 1
    //       }
    //       // console.log(brk, countData)
    //     })

    //     return valueMap.map(cat => ({
    //       count: cat.count,
    //       name: cat.name,
    //       year: yr
    //     }))
    //   })

    //   const datasets = valueMap.map((cat, idx) => {
    //     const data = countCatYears
    //       .filter(d => (d.name = cat.name))
    //       .map(c => c.count)

    //     return {
    //       label: cat.name,
    //       data: data,
    //       borderColor: this.catColors[idx],
    //       // no fill
    //       fill: false,
    //       // slightly smooth the line
    //       tension: 0.1
    //     }
    //   })
    //   if (this.debug) console.log('catTrendData', datasets)

    //   return datasets
    // },

    // // generate data for categorical pie chart
    // // format: https://www.chartjs.org/docs/latest/charts/doughnut.html#data-structure
    // // array of labels and array of data need to be separate
    // catChartData() {
    //   // if it's not a categorical chart exit early
    //   if (!this.isCategorical) {
    //     return {}
    //   }

    //   // get copy of values and valueMap from layer data
    //   const values = this.valuesForYear(this.layerData, this.currentYear)
    //   const refValues = this.valuesForYear(this.refValues, this.currentYear)
    //   let valueMap = this.valueMap.map(o => ({ ...o }))
    //   console.log('catChartData initial', values, valueMap)

    //   // group into categories
    //   // loop over values
    //   values.forEach(val => {
    //     // loop over valueMap
    //     // find where value matches category
    //     let idx = valueMap.findIndex(cat => cat.value == val)
    //     // if category found update count
    //     if (idx >= 0) {
    //       // ref: https://stackoverflow.com/questions/13298232/how-to-idiomatically-initialize-to-zero-or-increment-a-property
    //       valueMap[idx].count = valueMap[idx].count + 1 || 1
    //     }
    //     // console.log(brk, countData)
    //   })

    //   // do the same for refValues
    //   refValues.forEach(val => {
    //     // loop over valueMap
    //     // find where value matches category
    //     let idx = valueMap.findIndex(cat => cat.value == val)
    //     // if category found update count
    //     if (idx >= 0) {
    //       // ref: https://stackoverflow.com/questions/13298232/how-to-idiomatically-initialize-to-zero-or-increment-a-property
    //       valueMap[idx].refCount = valueMap[idx].refCount + 1 || 1
    //     }
    //     // console.log(brk, countData)
    //   })
    //   // iterate over valueMap
    //   const data = valueMap
    //     // filter out those with zero counts
    //     .filter(val => val.count > 0)
    //     // and then calculate percent change
    //     // add category color
    //     // map to chart axis
    //     .map((cat, idx) => {
    //       const pctChange = (cat.count - cat.refCount) / cat.refCount
    //       return {
    //         x: cat.count,
    //         y: cat.name,
    //         pctChange: pctChange,
    //         color: this.catColors[idx]
    //       }
    //     })
    //     // finally sort it by count (descending)
    //     .sort((a, b) => b.x - a.x)
    //   console.log('catChartData end', data)
    //   return data
    // },

    chartColors() {
      return utilities.getChartColorsFromScale(this.layerCat.scale)
    },

    chartLabels() {
      // values for current year
      const values = this.valuesForYear(this.layerData, this.currentYear)
      const refValues = this.valuesForYear(this.refValues, this.currentYear)
      // find range of values between current year values and reference values
      const extent = ssExtent(values.concat(refValues))
      // find step that should be used
      // by dividing the range by the number of desired bins
      const step = utilities.round((extent[1] - extent[0]) / this.bins, 0)
      // return rounded range of values
      return utilities.range(
        utilities.round(extent[0], 0),
        utilities.round(extent[1], 0),
        step
      )
    }
  },

  methods: {
    // calculate descriptive stats for data
    descriptiveStats(values) {
      // just return the value if there's only one (as is the case with water)
      if (!Array.isArray(values)) {
        return values
      }
      // if it's an array with just a single item, just return that item
      else if (values.length == 1) {
        return values[0]
      }

      values.sort((a, b) => a - b)
      // return object with various stats (using simple-statistics)
      return {
        extent: extentSorted(values),
        median: medianSorted(values),
        mode: modeSorted(values),
        mean: mean(values),
        medianAbsoluteDeviation: medianAbsoluteDeviation(values)
      }
    },

    // generate data for categorical pie chart
    // format: https://www.chartjs.org/docs/latest/charts/doughnut.html#data-structure
    // array of labels and array of data need to be separate
    calcCatChartData() {
      // if it's not a categorical chart exit early
      if (!this.isCategorical) {
        return {}
      }

      // get copy of values and valueMap from layer data
      const values = this.valuesForYear(this.layerData, this.currentYear)
      const refValues = this.valuesForYear(this.refValues, this.currentYear)
      let valueMap = this.layer.valueMap.map(o => ({ ...o }))
      console.log('catChartData initial', values, valueMap)

      // group into categories
      // loop over values
      values.forEach(val => {
        // loop over valueMap
        // find where value matches category
        let idx = valueMap.findIndex(cat => cat.value == val)
        // if category found update count
        if (idx >= 0) {
          // ref: https://stackoverflow.com/questions/13298232/how-to-idiomatically-initialize-to-zero-or-increment-a-property
          valueMap[idx].count = valueMap[idx].count + 1 || 1
        }
        // console.log(brk, countData)
      })

      // do the same for refValues
      refValues.forEach(val => {
        // loop over valueMap
        // find where value matches category
        let idx = valueMap.findIndex(cat => cat.value == val)
        // if category found update count
        if (idx >= 0) {
          // ref: https://stackoverflow.com/questions/13298232/how-to-idiomatically-initialize-to-zero-or-increment-a-property
          valueMap[idx].refCount = valueMap[idx].refCount + 1 || 1
        }
        // console.log(brk, countData)
      })
      // iterate over valueMap
      const data = valueMap
        // filter out those with zero counts
        .filter(val => val.count > 0)
        // and then calculate percent change
        // add category color
        // map to chart axis
        .map((cat, idx) => {
          const pctChange = (cat.count - cat.refCount) / cat.refCount
          return {
            x: (cat.count / values.length) * 100,
            y: cat.name,
            pctChange: pctChange,
            color: this.catColors[idx]
          }
        })
        // finally sort it by count (descending)
        .sort((a, b) => b.x - a.x)
      console.log('catChartData end', data)
      return data
    },

    closeChartPanel() {
      this.$parent.closePanelClicked()
    },

    // export chart as png
    // ref: https://github.com/J-T-McC/vue3-chartjs#exporting-chart-as-png
    exportChart() {
      let a = document.createElement('a')
      if (this.type == 'bar') {
        a.href =
          this.$refs.barChart.$refs.chartRef.chartJSState.chart.toBase64Image(
            'image/png',
            1
          )
      } else {
        a.href =
          this.$refs.lineChart.$refs.chartRef.chartJSState.chart.toBase64Image(
            'image/png',
            1
          )
      }
      a.download = `${this.exportFileName}.png`
      a.click()
      a = null
    },

    // add utility functions here so they are available in template
    ...utilities
  }
}
</script>

<style scoped>
.field-label {
  margin-right: 0.75rem;
}
.field-label .label {
  font-size: 0.75rem;
  line-height: 1.75rem;
}

/* econ data summary table */
#econTable {
  table-layout: auto;
  width: 100%;
  font-size: 90%;
  border-collapse: collapse;
}
#econTable > tbody > tr:last-child {
  border-top: 1pt solid black;
}
#econTable > tbody > tr:last-child > td {
  padding-top: 4px;
}
.econAmount {
  text-align: right;
}
</style>
