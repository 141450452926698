<template>
  <figure class="image is-96x96 is-clickable" @click="onBasemapClick(basemap)">
    <div class="is-overlay has-text-centered">
      {{ basemap.name }}
    </div>
    <img class="is-rounded" :src="getImagePath(basemap.thumbnail)" />
  </figure>
</template>

<script>
export default {
  props: {
    basemap: Object
  },
  methods: {
    // generate path to image dynamically
    // ref: https://stackoverflow.com/a/47480286/6072959
    getImagePath(imageName) {
      return require('@/assets/images/' + imageName)
    },
    // triggers when basemap is clicked
    onBasemapClick(basemap) {
      this.$emit('selectBasemap', basemap)
    }
  },
  emits: ['selectBasemap']
}
</script>

<style scoped>
figure {
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
}
/*
  Styling for basemap label on top of thumbnail
*/
figure > div {
  /* move text to the middle: 50% of image height minus half font size of 16px */
  line-height: 1em;
  margin-top: calc(50% - 8px);
  color: #000;
  font-weight: 600;
  /* text outline generated from https://html-css-js.com/css/generator/text-shadow/ */
  text-shadow: 2px 2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
    -2px -2px 0 #fff, 2px 0px 0 #fff, 0px 2px 0 #fff, -2px 0px 0 #fff,
    0px -2px 0 #fff;
}
figure.is-clickable img {
  border: solid 3px #fff;
  transition: all 0.2s ease-in-out;
}
figure.is-clickable:hover img {
  border-color: #ffcc33;
}
</style>
