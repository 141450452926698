<template>
  <Head>
    <title>About ForCAST: Our People</title>
    <meta
      name="description"
      content="The professionals behind the ForCAST tool"
    />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">People</h1>
      <h2>John DuPlissis M.S. - Principal Investigator</h2>
      <p class="role">
        Interim Forest and Land Research Group Manager - NRRI<br />
        M.S., Forest Resources, University of Minnesota
      </p>
      <p>
        John leads the Institute's silviculture research group focused on
        developing cutting-edge applied forest management research leading to
        stabilization and expansion of forest-based industry in Minnesota. This
        includes development of a full range of silvicultural strategies from
        intensive forest management and multiple-use forestry to conservation
        forestry. Existing programs include research on intermediate stand
        treatments for aspen and red pine ecosystems, regional resource analyses
        to assess timber availability, field evaluations of logging operation
        efficiency and management of an internationally-renowned program on
        genetic improvement of hybrid poplar.
      </p>
      <hr />
      <h2>George Host Ph.D. - Principal Investigator (retired)</h2>
      <p class="role">
        Initiative Director - Forest and Land - NRRI (retired)<br />
        Ph.D., Forest Ecology, Michigan State University
      </p>
      <p>
        George is a landscape ecologist and was the Director of the Forest and
        Land Initiative as well as NRRI&rsquo;s Geographic Information System
        Laboratory. Areas of expertise include development and application of
        ecological classification systems for resource management,
        quantification of environmental stressors, and visualization of complex
        data for scientific and public audiences.
      </p>
      <hr />
      <h2>Ron Moen Ph.D. - Co-Investigator</h2>
      <p class="role">
        Senior Research Associate - NRRI<br />
        Ph.D., Wildlife Conservation, University of Minnesota
      </p>
      <p>
        Ron&rsquo;s research interests include vertebrate studies, ecology and
        mammalogy. Recent research projects studied the effects of moose
        browsing on forests, tracking the Canada lynx in Minnesota and teaching
        at the University of Minnesota Duluth.
      </p>
      <hr />
      <h2>Chris Wright Ph.D. - Co-Investigator</h2>
      <p class="role">
        Research Associate - NRRI<br />
        Ph.D., Montana State University
      </p>
      <p>
        Chris is a landscape ecologist with technical expertise in satellite
        remote sensing, land use change, and forest carbon modeling. He has
        worked on sustainability concerns across a range of natural resource
        applications including sustainable agriculture, grassland conservation,
        and the design of mixed-use landscapes resilient to climate change. His
        recent research program is centered on natural climate solutions, where
        he is interested in the marketing of multiple ecosystem services to
        enhance the economic well-being of rural communities in Minnesota.
      </p>
      <hr />
      <h2>Lucinda Johnson Ph.D. - Co-Investigator</h2>
      <p class="role">
        Director of Research - NRRI<br />
        Ph.D., Zoology, Michigan State University
      </p>
      <p>
        Lucinda&rsquo;s areas of expertise include aquatic and landscape
        ecology. Current research interests include translation of science for
        policymakers and managers, impacts of climate change, decision making in
        the environmental sciences, environmental assessment and bioindicators.
        Research projects include: predicting impacts of climate change on
        stream communities, climate change adaptation science and early warning
        systems.
      </p>
      <hr />
      <h2>Stephen Polasky - Co-Investigator</h2>
      <p class="role">
        Regents Professor, Fesler-Lampert Professor of Ecological/Environmental
        Economics - University of Minnesota, College of Food, Agricultural, and
        Natural Resource Sciences<br />
        Ph.D., Economics, University of Michigan
      </p>
      <p>
        Stephen&rsquo;s research focuses on issues at the intersection of
        ecology and economics and includes the impacts of land use and land
        management on the provision and value of ecosystem services and natural
        capital, biodiversity conservation, sustainability, environmental
        regulation, renewable energy, and common property resources.
      </p>
      <hr />
      <h2>William Herb Ph.D. - Water Modeling</h2>
      <p class="role">
        St. Anthony Falls Laboratory Research Associate - University of
        Minnesota, College of Science and Engineering<br />
        Ph.D., Mechanical Engineering, University of Minnesota
      </p>
      <p>
        Bill has been a research associate at the St. Anthony Falls Laboratory
        for 20 years, researching environmental hydraulic, hydrologic, and heat
        transfer processes. His research interests include lake and river water
        quality modeling, hydrology, climate change, and environmental
        hydraulics. He has a strong knowledge of software tools and has
        developed a modeling package to analyze thermal pollution from urban
        surface runoff. Recently, Bill has been researching the storm
        vulnerability of drainage infrastructure across Minnesota, the transport
        of road de-icers in the environment, the interaction of wind-driven
        waves with shoreline habitat in lakes, and the impact of climate change
        on cold water fish habitat in lakes and streams.
      </p>
      <hr />
      <h2>Meijun Cai Ph.D. - Water Modeling</h2>
      <p class="role">
        Research Technical Manager, Environmental Engineer - NRRI<br />
        Ph.D., Environmental Engineering, University of Tennessee - Knoxville
      </p>
      <p>
        Mei&rsquo;s research interests include water chemistry, stormwater
        treatment, climate change and watershed and environmental statistics.
        Current research focuses on using cost-effective technologies/materials
        to treat wastewater and stormwater to sustain resources and protect the
        environment for resilient communities. She is also an environmental
        statistician and GIS analyst, capable of multiple statistical methods
        and softwares, and ArcGIS.
      </p>
      <hr />
      <h2>Alexis Grinde Ph.D. - Habitat Modeling</h2>
      <p class="role">
        Avian Ecologist - NRRI<br />
        Ph.D., Integrated Biological Sciences, University of Minnesota
      </p>
      <p>
        Alexis&rsquo;s research focuses on conservation ecology including
        studying the large-scale impacts of environmental change on wildlife,
        biodiversity, and ecosystem function. Applications of her research
        include informing forest management decisions in relation to changing
        land use patterns and providing recommendations for conservation plans
        for species of conservation concern.
      </p>
      <hr />
      <h2>Katya Kovalenko Ph.D. - Habitat Modeling</h2>
      <p class="role">
        Research Associate and Data Scientist - NRRI<br />
        Ph.D., Ecology, Mississippi State University
      </p>
      <p>
        Katya&rsquo;s areas of expertise include aquatic ecology and data
        science. Current projects focus on aquatic invasive species, food webs,
        habitat complexity, functional diversity, bioassessment, response of
        tidal wetlands to sea-level rise, and include modeling and statistical
        support for a wide variety of other projects.
      </p>
      <hr />
      <h2>Steve Kolbe M.S. - Habitat Modeling</h2>
      <p class="role">
        Avian Ecologist - NRRI<br />
        M.S., Integrated Biosciences, University of Minnesota - Duluth
      </p>
      <p>
        Steve is an avian ecologist with a special interest in bird migration
        and movement, testing novel survey methodologies, and acoustic
        identification. His research focuses on the ways in which birds use
        their environment at varying scales throughout the annual cycle.
      </p>
      <hr />
      <h2>Josh Bednar M.S. - Habitat Modeling</h2>
      <p class="role">
        Senior Research Staff Scientist, Wildlife Ecologist - NRRI<br />
        M.S., University of Minnesota - Duluth
      </p>
      <p>
        Josh is a wildlife ecologist with a primary focus on birds.
        Specifically, he is interested in identifying and developing
        conservation solutions for species in greatest conservation need. Other
        areas of interest include: breeding ecology and habitat associations,
        migration, land use, climate change and agriculture.
      </p>
      <hr />
      <h2>Saleh Mamun Ph.D. - Economic Modeling</h2>
      <p class="role">
        Postdoctoral Associate - NRRI<br />
        Ph.D., Economics, University of New Mexico
      </p>
      <p>
        Saleh is a Postdoctoral Associate at the University of Minnesota. He is
        jointly appointed at the Applied Economics Department and Natural
        Resources Research Institute. His research interest lies in the field of
        natural resource and ecological economics. His research focuses on the
        behavioral and market responses to environmental amenities and hazards.
        He also researches land-use optimization integrating ecosystem services
        into the decision matrix. He is a team member of The Natural Capital
        Project, a collaborative initiative between Stanford University and
        University of Minnesota.
      </p>
      <hr />
      <h2>Kris Johnson M.S. - GIS Analysis, Application Development</h2>
      <p class="role">
        GIS Developer - NRRI<br />
        M.S., Geographic Information Science, University of Minnesota
      </p>
      <p>
        Kris is the lead application developer for the GIS lab at NRRI. His
        primary role at NRRI is to support research projects by providing
        database and application development and to deliver public-facing web
        applications for these projects. His research focus is in GIS modeling
        and analysis using dasymetric mapping techniques. Currently he splits
        his time between NRRI and teaching in the Geography program at the
        University of Minnesota - Duluth.
      </p>
      <hr />
      <h2>Jane Reed M.S. - Application, Website Development</h2>
      <p class="role">
        Web Developer - NRRI<br />
        M.S., Geology, University of Delaware
      </p>
      <p>
        Jane is a website and web application developer at NRRI. Jane works with
        the Data Collection and Delivery team developing and maintaining several
        websites and web application tools. She also works with the Marketing
        and the Communications team, providing technical and content support for
        the NRRI website and overseeing NRRI digital entities for content and
        accessibility.
      </p>
      <hr />
      <h2>Kristi Nixon M.S. - GIS Analysis, Data Management</h2>
      <p class="role">
        GIS Specialist - NRRI<br />
        M.S., Conservation Biology and Sustainable Development, University of
        Wisconsin - Madison
      </p>
      <p>
        Kristi is a GIS specialist and is involved in various Land and Water
        research projects. She is interested in landscape ecology, spatial
        analysis, modeling, remote sensing, and web map development to help
        inform sustainable natural resource management. She also pilots a
        fixed-wing and quad-copter drone for remote sensing of NRRI project
        sites.
      </p>
      <hr />
      <h2>Mae Davenport Ph.D. - Needs Assessment</h2>
      <p class="role">
        Professor, Director-Center for Changing Landscapes - University of
        Minnesota, College of Food, Agricultural, and Natural Resource
        Sciences<br />
        Ph.D., 2003, University of Minnesota
      </p>
      <p>
        Mae&rsquo;s research interests are focused on the human dimensions of
        natural resource management, specifically sustainable land use planning;
        community-based ecosystem management; recreation planning; and human
        beliefs, attitudes and behaviors associated with landscape change. Her
        research program has investigated community capacity for watershed
        conservation, stakeholder attitudes toward wetlands restoration,
        residents&rsquo; perceptions of the re-colonization of cougars in the
        Midwest, and recreational boating practices associated with aquatic
        invasive species and fish diseases. Much of her work is
        interdisciplinary and integrates biophysical and social sciences to
        better understand and address natural resource management problems.
      </p>
      <hr />
      <h2>Sarah Roth - Needs Assessment</h2>
      <p class="role">
        Researcher, Center for Changing Landscapes - University of Minnesota<br />
        M.S., 2015, Natural Resources Science &amp; Management, University of
        Minnesota
      </p>
      <p>
        Sarah is a researcher with interests in environmental outreach,
        conservation behavior, and decision making, specifically understanding
        the beliefs, drivers, and barriers associated with conservation action.
        Recent work has investigated how to support community-centered planning
        and policy around urban water management - specifically, bringing racial
        and ethnically diverse voices and values to the forefront. She also
        trains other agencies and organizations in how to conduct their own
        qualitative research.
      </p>
      <hr />

      <h2>Will Bartsch M.S. - Project Management</h2>
      <p class="role">
        Senior Research Scientist - NRRI<br />
        M.S., Water Resources Science, University of Minnesota
      </p>
      <p>
        Will&rsquo;s research focuses on the development and analysis of spatial
        data to better understand aquatic and terrestrial environments. He is
        particularly interested in increasing access to spatial data in an
        effort to both facilitate communication about Minnesota&rsquo;s natural
        resources and improve the decisions that impact those resources.
      </p>
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  components: {
    Head
  }
}
</script>

<style scoped>
.general-content h2 {
  margin-bottom: 0.5rem;
}
p.role {
  font-size: 0.9rem;
  font-weight: 600;
}
</style>
