<template>
  <div class="container m-3">
    <!-- download data section -->
    <div class="card mb-3">
      <header class="card-header has-background-primary has-text-light">
        <p class="card-header-title has-text-light">Download Data</p>
        <p class="card-header-icon">
          <span class="icon"><fa :icon="['fa', 'download']" /></span>
        </p>
      </header>
      <div class="card-content">
        <!-- display warning if scenario is not selected -->
        <div v-if="!isScenarioSelected" class="notification is-warning">
          A scenario must be selected first before exporting data
        </div>
        <!-- display warning if aoi is not selected -->
        <div v-if="!isScenarioSelected" class="notification is-warning">
          An Area of Interest (AOI) be determined first before exporting data
        </div>
        <!-- if scenario and aoi is selected download can proceed -->
        <div v-else class="block">
          <p>
            Download data layers based on your selected scenario and area of
            interest (AOI)
          </p>
          <!-- TODO: clean these up so they look better -->
          <p>Scenario: {{ fullLayerName }}</p>
          <p>AOI: {{ aoiBounds }}</p>
        </div>

        <!-- select layers to be downloaded -->
        <!-- 
          using vue-multiselect - https://vue-multiselect.js.org
          vue3 branch: https://github.com/shentao/vue-multiselect/tree/next
        -->
        <label class="label">Select data layers</label>
        <VueMultiselect
          v-model="selected"
          :options="layers"
          :multiple="true"
          group-values="layers"
          group-label="title"
          :group-select="true"
          placeholder="Type to search"
          track-by="name"
          label="name"
        ></VueMultiselect>
      </div>

      <!-- card footer -->
      <footer class="card-footer has-background-primary">
        <a href="#" class="card-footer-item" @click="downloadData"
          >Download Data<span class="icon"
            ><fa :icon="['fa', 'download']" /></span
        ></a>
        <a href="#" class="card-footer-item">Clear Selection</a>
      </footer>
    </div>
    <!-- reporting section -->
    <article class="panel is-primary">
      <div class="panel-heading">Select Report</div>
    </article>
  </div>
</template>

<script>
// store
import theStore from '../store/store.js'

// scenario data
import scenarioData from '../data/scenarioData.js'

// multiple select component
import VueMultiselect from 'vue-multiselect'

export default {
  components: { VueMultiselect },
  // data() {
  //   return {
  //     fullLayerName: ''
  //   }
  // },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    // get pretty scenario name from store
    fullLayerName() {
      return theStore.store.getFullLayerName()
    },

    // check if scenarios are selected
    isScenarioSelected() {
      return theStore.store.isScenarioSelected()
    },

    // AOI bounds
    aoiBounds() {
      return theStore.store.state.aoi
    },

    // scenario data layers
    layers() {
      return scenarioData.components
    },

    printSelected() {
      console.log('selected :>> ', this.selected)
      return this.selected
    }
  },
  methods: {
    // download selected data layers
    downloadData() {
      // could maybe use getGeoTiffURL() from foRasterLayer.js
      // or use WPS download plugin:
      // https://docs.geoserver.org/stable/en/user/extensions/wps-download/index.html
      // one issue is that it's not clear if multiple files can be downloaded at once
      // ideally these would be wrapped up into a single zip file
      // that will likely require some server-side service to step in
      // and package the separate downloads together.
    }
  }
}
</script>

<!-- vue multi select styling -->
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
/* reduce padding on options */
.select select[multiple] option {
  padding: 0.25em 1em;
}
/* does container really need to be that big? */
.container {
  max-width: 450px;
}
.panel-heading {
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  /* color: #ededed;
  background-color: #363636; */
  border-radius: 6px 6px 0 0;
  padding: 0.75em 1em;
}
</style>
