<template>
  <Head>
    <title>About ForCAST: Funding</title>
    <meta
      name="description"
      content="Funding for this project was provided by the Minnesota Environment and
        Natural Resources Trust Fund as recommended by the Legislative-Citizen
        Commission on Minnesota Resources (LCCMR)"
    />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">Credits and Funding</h1>

      <p>
        The project was a collaborative effort led by the Natural Resources
        Research Institute with substantial contributions from University of
        Minnesota&rsquo;s College of Science and Engineering and College of
        Food, Agriculture and Natural Resources. Guidance was provided by an
        Advisory Committee with representatives from industry, non-governmental
        organizations, and local, state, and federal governments.
      </p>
      <img
        src="../assets/images/enrtf_logo.svg"
        width="300"
        class="is-pulled-right ml-5"
        alt="Environment and Natural Resources Trust Fund"
      />
      <p>
        Funding for this project was provided by the Minnesota Environment and
        Natural Resources Trust Fund as recommended by the Legislative-Citizen
        Commission on Minnesota Resources (LCCMR).
      </p>

      <p>
        The Trust Fund is a permanent fund constitutionally established by the
        citizens of Minnesota to assist in the protection, conservation,
        preservation, and enhancement of the state&rsquo;s air, water, land,
        fish, wildlife, and other natural resources.
      </p>
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  components: {
    Head
  }
}
</script>

<style></style>
