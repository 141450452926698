<template>
  <Head>
    <title>About ForCAST: Project Overview</title>
    <meta name="description" content="" />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">Project Overview</h1>
      <h2>Goal</h2>
      <p>
        Forest management is an increasingly complex discipline that requires
        the balancing of economics and ecology in the face of changing markets
        and climate. Beyond providing lumber, pulp and other forest products,
        forests provide many additional goods and services that benefit society.
        These include sequestering carbon, providing habitat for wildlife,
        maintaining water quality and quantity and others. Understanding both
        how forests will change over time and how society values the goods and
        services they provide is critical to the successful management of
        Minnesota's forests.
      </p>

      <p>
        <strong
          >The goal of the Forest Change Assessment Tool (ForCAST) is to provide
          forestry professionals and land managers with the information they
          need to better understand and plan for an uncertain future.</strong
        >
      </p>

      <h2>Design</h2>
      <p>
        ForCAST is designed to provide projections of how forest composition and
        the goods and services that forests provide will change from 2020 to
        2100 under different management and climate scenarios. It also helps
        users understand how Minnesotans value those forest goods and services.
        Foundational landscape change modeling was done using the LANDIS-II
        model, allowing for a better understanding of forest composition and
        carbon. Subsequent wildlife habitat and water quality and quantity
        modeling were done using the WHINGS and HSPF models, respectively. All
        models were run for each of the 12 unique combinations of the management
        and climate scenarios. Focus groups, a discrete choice survey and
        statistical models were used to quantify the value of forest goods and
        services.
      </p>
      <figure>
        <img
          src="../assets/images/ForCAST_Map.jpg"
          class="is-pulled-right ml-3"
          width="450"
          alt="map of Northern Minnesota with four watersheds highlighted: Mississippi River Grand Rapids, St. Louis River, Cloquet River and Lake Superior South"
        />
      </figure>
      <h3>Study Area</h3>

      <p>
        The ForCAST project extent includes 3,800,000 acres in northern
        Minnesota spanning four major watersheds and encompassing private,
        public (county, state, federal), and tribal ownership. These watersheds
        are the St. Louis River, Cloquet River, Mississippi River - Grand Rapids
        and Lake Superior - South.
      </p>

      <h3>Time Frame</h3>
      <p>
        Modeling was completed in 10 year time-steps over an 80 year period from
        2020 to 2100.
      </p>

      <h3>Forest Management Scenarios</h3>
      <p>
        Four management scenarios were chosen that reflect a range of harvest
        intensities and goals. They include:
      </p>
      <ol>
        <li>
          "No change", which represents current management regimes based on
          typical harvest practices for each timber type by management
          organizations (landowner type)
        </li>
        <li>Increased harvest by 30% from current</li>
        <li>Decreased harvest by 30% from current</li>
        <li>
          &ldquo;Max landscape carbon sequestration&rdquo;, the optimum for
          sequestering carbon in biomass on the landscape - a decrease in
          harvest volume by 30% from current, as well as increased time between
          harvests.
        </li>
      </ol>

      <p>
        <router-link to="/landscape-modeling#forest">
          More information about Forest Management Scenarios.
        </router-link>
      </p>

      <h3>Climate Scenarios</h3>
      <p>
        Three climate scenarios were chosen that represent recent historic
        conditions and a range of expected future conditions. They differ in
        their inputs of air temperature and precipitation, which influence tree
        growth throughout the model and include:
      </p>

      <ol>
        <li>Historical Climate</li>
        <li>Low Emissions</li>
        <li>High Emissions</li>
      </ol>

      <p>
        <router-link to="/landscape-modeling#climate"
          >More information about Climate Modeling.</router-link
        >
      </p>

      <h2>Process</h2>

      <h3>Landscape Modeling</h3>
      <p>
        The modeling process started with forest composition modeling using the
        LANDIS-II landscape change model. LANDIS-II is a spatialtemporal, tree
        species-level, cell-based forest model that simulates forest growth,
        succession, and disturbances over large landscapes and time periods at a
        moderate spatial resolution. Model output was at a resolution of a 200
        meter grid and included biomass above ground, harvest, stand age, and
        cover type for each cell. Biomass above ground was used to calculate
        carbon on the landscape in trees. The landscape modeling provided the
        foundational forest composition data on which all other models were
        developed.
      </p>

      <p>
        <router-link to="/landscape-modeling"
          >More information about Landscape and Carbon Modeling.</router-link
        >
      </p>

      <h3>Habitat Modeling</h3>
      <p>
        Wildlife habitat quality was modeled using the Wildlife Habitat
        Indicator for Native Genera and Species (WHINGS) Habitat Suitability
        Index (HSI) model. Using the forest cover type and size classes
        predictions from the landscape modeling, HSI models were run to predict
        the relative habitat suitability of an area for 26 mammal species and 85
        bird species present in northeastern Minnesota with a base model output
        of a quarter township (28.3 square kilometers). The results were
        aggregated into groups based on species characteristics or wildlife
        management and monitoring practices. A high HSI value indicates that a
        habitat is very suitable for a species or group, but does not predict
        population levels.
      </p>

      <p>
        <router-link to="/wildlife-modeling"
          >More information about Wildlife Habitat Assessment.</router-link
        >
      </p>

      <h3>Water Modeling</h3>
      <p>
        Modeling of the water quality and quantity was performed with the
        Hydrologic Simulation Program-Fortran software, WinHSPF. LANDIS-II
        simulation outputs and climate data were used as inputs to WinHSPF.
        Models are calibrated at the Hydrologic Unit Code (HUC) 8 watershed
        level with the base modeling output being HUC 12 watersheds. Model
        outputs were the 2-year flood rate, mean annual flow rate, spring high
        flow rate, summer low flow rate, total suspended solids concentration,
        total phosphorus concentration, and total nitrogen concentration.
      </p>

      <p>
        <router-link to="/water-modeling"
          >More information about Hydrologic Modeling and Water Quality
          Assessment.</router-link
        >
      </p>

      <h3>Ecosystem Services Valuation</h3>
      <p>
        Residents across 11 counties in northern Minnesota were surveyed to
        determine their willingness to pay/invest each month to reach certain
        levels of timber production, water quality, game and non-game wildlife
        habitat, and carbon sequestration (collectively referred to as ecosystem
        services). Using the survey responses, regression modeling was performed
        to determine the non-market value of these ecosystem services. Modeling
        results were applied to summarized output data from other model
        components to calculate each scenario&rsquo;s overall value in US
        Dollars per household per month over the study period of 80 years.
      </p>

      <p>
        <router-link to="/ecosystem-valuation">
          More information about Ecosystem Services Valuation.</router-link
        >
      </p>

      <h2>Delivery</h2>
      <p>
        All model results are delivered to users through a custom, interactive
        mapping tool. The tool allows users to visualize and summarize how
        forest composition and goods and services change through time under
        different management and climate scenarios.
        <a :href="dataDownloadURL" target="_blank"
          >Data is available to download for use within a desktop Geographic
          Information System.</a
        >
      </p>

      <h2>Limitations</h2>
      <p>
        ForCAST provides data to allow evaluation of the impacts of changing
        climate and management on Minnesota forests at a broad scale or regional
        level; however, it does not provide definitive answers about what a
        management unit will or will not look like at some point in the future.
        Forest Landscape Modeling cannot be expected to accurately depict how
        climate change or proposed management activities will impact specific
        sites or individual stands or parcels. In applying vegetation models to
        inform management adaptations to changing climate and markets, it is
        important to match the spatial and temporal scale of the model with that
        of the question being asked.
      </p>
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
// get download data url from map data configurations
import mapData from '@/data/mapData.js'
export default {
  components: {
    Head
  },
  computed: {
    dataDownloadURL() {
      return mapData.dataDownloadURL
    }
  }
}
</script>

<style></style>
