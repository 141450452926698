// debugging
const DEBUG = {
  sidebar: false,
  agreeToTermsModal: true
}

// other imports (not sure what these are)
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// import fontawesome icons from external file
import FontAwesomeIcon from '@/assets/utilities/fontawesome-icons'

// vue3-tour styles
import 'vue3-tour/dist/vue3-tour.css'

// import bulma from cust scss file
require('@/assets/styles/main.scss')

// vue import
import { createApp } from 'vue'
import router from './router'
import Vue3Tour from 'vue3-tour'
import { createHead } from '@vueuse/head'
import VueGtag from 'vue-gtag'
import { createGtm } from '@gtm-support/vue-gtm'
// our app
import App from './App.vue'

// create app
const app = createApp(App)
// add fontawesome icons component
app.component('fa', FontAwesomeIcon)
// use vue-router
app.use(router)
// global declaration of vue3-tour
app.use(Vue3Tour)
//set up vueuse/head meta tag manager
const head = createHead()
app.use(head)
//set up vue-gtag for Google Analytics
app.use(
  VueGtag,
  {
    config: { id: 'G-ETMEWGR7XP' }
  },
  router
)
//setup vue-gtm to track events with Google Tag Manager
app.use(
  createGtm({
    id: 'GTM-5G42SP3', // Your GTM single container ID
    // enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    //loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  })
)
// add debug variable
app.config.globalProperties.$DEBUG = DEBUG
// finally, mount the app
app.mount('#app')
