<template>
  <!-- bar chart -->
  <vue3-chart-js
    type="bar"
    :id="chart.id"
    :data="chart.data"
    :options="chart.options"
    :height="chartHeight"
    ref="chartRef"
  ></vue3-chart-js>
</template>

<script>
// charting library
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
// chartjs plugins
import annotationPlugin from 'chartjs-plugin-annotation'
Vue3ChartJs.registerGlobalPlugins([annotationPlugin])

// utility functions
import utils from '../../modules/utilities.js'

export default {
  name: 'BarChart',
  components: {
    Vue3ChartJs
  },
  props: {
    currentScenarioData: Object,
    refScenarioData: Object,
    year: String,
    isCategorical: Boolean,
    chartColors: Object,
    refChartColors: Object,
    catChartData: Object,
    layerStats: Object,
    layerUnitsDisplay: String,
    chartLabels: Array,
    chartHeight: Number,
    precision: Number
  },
  data() {
    return {
      debug: true
    }
  },
  computed: {
    // chart configuration
    chart() {
      const config = {
        data: this.formattedData,
        options: this.options
      }
      if (this.debug) console.log('chart config', config)
      return config
    },

    // values for current year
    currentYearValues() {
      return utils.valuesForYear(this.currentScenarioData, this.year)
    },
    // refernce values for current year
    currentYearRefValues() {
      return utils.valuesForYear(this.refScenarioData, this.year)
    },

    /* generate data for charts
      data --> {
        labels: labels,
        datasets: [{
          data: { x: y, ... }
        }]
      }
      ref: https://www.chartjs.org/docs/latest/general/data-structures.html
    */
    formattedData() {
      let data = {}

      // check for categorical type
      if (!this.isCategorical) {
        // find chart labels for current range of data
        data = {
          labels: this.chartLabels,
          datasets: [
            // current scenario
            {
              label: 'Current Scenario',
              // chart labels dictate the breakpoints
              data: this.chartDataFromBreaks(
                this.chartLabels,
                this.currentYearValues
              ),
              backgroundColor: this.chartColors.background,
              categoryPercentage: 0.8,
              barPercentage: 1.0
            },
            // reference dataset
            {
              label: 'Reference Data',
              data: this.chartDataFromBreaks(
                this.chartLabels,
                this.currentYearRefValues
              ),
              backgroundColor: this.refChartColors.background,
              categoryPercentage: 0.8,
              barPercentage: 1.0
            }
          ]
        }
      }
      // categorical bar chart
      else {
        const chartData = this.catChartData
        data = {
          datasets: [
            {
              axis: 'y',
              data: chartData,
              backgroundColor: chartData.map(d => d.color)
            }
          ]
        }
      }

      return data
    }, // end data()

    // configuration for charts - axis, title, etc.
    options() {
      let opt = {}

      // continuous data bar charts
      if (!this.isCategorical) {
        opt = {
          plugins: {
            legend: {
              display: true
            },
            // options for annotation plugin
            // only used with bar or line
            annotation: {
              annotations: {
                median: {
                  type: 'line',
                  xMin: this.layerStats.median,
                  xMax: this.layerStats.median,
                  borderColor: 'rgb(66, 66, 66)',
                  borderWidth: 2
                },
                lowerMad: {
                  type: 'line',
                  xMin:
                    this.layerStats.median -
                    this.layerStats.medianAbsoluteDeviation,
                  xMax:
                    this.layerStats.median -
                    this.layerStats.medianAbsoluteDeviation,
                  borderColor: 'rgb(66, 66, 66)',
                  borderWidth: 1,
                  borderDash: [5, 5]
                },
                upperMad: {
                  type: 'line',
                  xMin:
                    this.layerStats.median +
                    this.layerStats.medianAbsoluteDeviation,
                  xMax:
                    this.layerStats.median +
                    this.layerStats.medianAbsoluteDeviation,
                  borderColor: 'rgb(66, 66, 66)',
                  borderWidth: 1,
                  borderDash: [5, 5]
                }
              }
            }
          },
          scales: {
            x: {
              type: 'linear',
              grace: '10%',
              title: {
                display: true,
                text: this.layerUnitsDisplay
              },
              // hardcode min and max to set range of data
              // min: 0,
              // max: 100,
              // should the scale begin at zero
              // `grace` option above could set it to negative
              // so it really depends on minimum values (plus grace margin)
              beginAtZero:
                this.layerStats.extent[0] * 1.1 - this.layerStats.extent[0] < 1
                  ? true
                  : false,
              // suggestedMin: 0,
              // beginAtZero: true,
              stacked: false,
              // if layer has significant digits specified use them,
              // otherwise round to whole numbers
              ticks: {
                precision: this.precision ? this.precision : 0
              }
            },
            y: {
              type: 'linear',
              grace: '10%',
              title: {
                display: true,
                text: 'Relative Frequency (%)'
              },
              beginAtZero: true
            }
          }
        }
      }

      // categorical data bar chart
      else {
        opt = {
          plugins: {
            legend: {
              display: false
            }
          },
          indexAxis: 'y',
          scales: {
            x: {
              type: 'linear',
              title: {
                display: true,
                text: 'Relative Frequency (%)'
              },
              beginAtZero: true
            },
            y: {
              title: {
                display: true,
                text: this.layerUnitsDisplay
              },
              // get all the species labels to display
              // ref: https://stackoverflow.com/a/57179235/6072959
              ticks: {
                // callback: this.modifySpeciesLabels(),
                callback: function (value) {
                  // ref: https://www.chartjs.org/docs/latest/samples/scale-options/ticks.html
                  value = this.getLabelForValue(value)
                  // check string length and shorten if needed
                  if (value.length < 20) {
                    return value
                  } else {
                    // modify string: split on back slash
                    // and take final word joined back together with back slashes
                    return value
                      .split(' / ')
                      .map(s => {
                        let b = s.split(' ')
                        return b[b.length - 1]
                      })
                      .join(' / ')
                  }
                },
                maxRotation: 50,
                minRotation: 30,
                padding: 6,
                autoSkip: false,
                fontSize: 8
              }
            }
          }
        }
      }

      // disabling tooltips for the time being
      opt.plugins.tooltip = {
        enabled: false
      }

      // add percent sign to tooltips
      // opt.plugins.tooltip = {
      //   callbacks: {
      //     label: function (context) {
      //       var label = context.dataset.label || ''

      //       if (label) {
      //         label += '%'
      //       }
      //     }
      //   }
      // }

      // format tooltip for wildlife groups
      // Add "HSI" to title
      // ref: https://www.chartjs.org/docs/3.0.2/configuration/tooltip.html
      // if (this.isWildlife) {
      // opt.plugins.tooltip.callbacks.title = function (context) {
      //   var title = context[0].label || ''

      //   if (title) {
      //     title = `HSI: ${title}`
      //   }
      //   return title
      // }
      // }

      return opt
    } // end options()
  },

  methods: {
    // generate data for chart using specified breaks
    // format should be:
    // data: [1, 2, 3, 4, ....]
    chartDataFromBreaks(breaks, values) {
      // use Array.reduce() to do this efficiently with a single go
      const data = breaks.reduce(function (prev, brk, idx, array) {
        // get number of values within that break
        // eg. greater than previous break and less than current break
        const count = values.filter(
          val => array[idx - 1] <= val && val < array[idx]
        ).length
        // get percent from count
        const perc = (count / values.length) * 100
        // add count of values to return data
        prev.push(utils.round(perc, 0))
        return prev
      }, [])

      console.log('chartDataFromBreaks data', data)

      return data
    },

    // add utility functions here so they are available in template
    ...utils
  }
}
</script>
