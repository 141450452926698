/* 
  geoRasterLayer.js

  Manages functionality for rendering GeoTiff (ie. raster) data on a leaflet map
  Relies on libraries from geotiff.js:
    - georaster
    - georaster-layer-for-leaflet
  Source: https://github.com/GeoTIFF/georaster-layer-for-leaflet
*/

// geotiff.js imports
// ref: https://github.com/GeoTIFF/georaster
const parse_georaster = require('georaster')
// lite version: https://github.com/GeoTIFF/georaster-layer-for-leaflet/blob/master/ADVANCED.md#reducing-your-build-size
import GeoRasterLayer from 'georaster-layer-for-leaflet/dist/georaster-layer-for-leaflet.lite.min.js'
// load source for debugging
// import GeoRasterLayer from 'georaster-layer-for-leaflet'
// raster processing
// import geoblaze from 'geoblaze/src/index.js'
import rasterCalculator from 'geoblaze/src/raster-calculator/index.js'

// imports

// symbolizers for raster layers
import { continuousColor, categoricalColor } from './layerSymbology'

// get geoserver url from map data
import mapData from '@/data/mapData.js'

// get scenario data for reference layer info
import scenarioData from '@/data/scenarioData.js'

// utility functions
import utils from '@/modules/utilities.js'

// flatten float32Arrays
const flatten = require('flatten-vertex-data')

// DEEEEEEEEE-bugging
const debug = true
if (debug) {
  console.log('deeee-bugging foRasterLayer')
}

// generate URL to get geojson data back from geoserver via sqlview
function _generateWFSURL(sqlview, viewparams) {
  const options = new URLSearchParams({
    service: 'WFS',
    version: '2.0.0',
    request: 'GetFeature',
    typeName: `foropt:${sqlview}`,
    srsName: 'EPSG:4326',
    // maxFeatures: '50',
    outputFormat: 'application/json',
    viewparams: viewparams
  })
  return mapData.geoserverURL + options
}

// generate viewparam WFS param
// ref: https://docs.geoserver.org/master/en/user/data/database/sqlview.html
function _setViewParams(layerName, bounds) {
  let viewparams = ''

  // set view params for AOI
  viewparams += `minx:${bounds[0].lng};`
  viewparams += `miny:${bounds[0].lat};`
  viewparams += `maxx:${bounds[2].lng};`
  viewparams += `maxy:${bounds[2].lat};`
  // add table name
  viewparams += `table:${layerName};`

  return viewparams
}

// private functions
// generate WCS URL
// https://docs.geoserver.org/latest/en/user/services/wcs/requestbuilder.html
function _generateWCSUrl(layerName, bounds) {
  console.log('_generateWCSUrl', layerName, JSON.stringify(bounds))
  const options = new URLSearchParams({
    service: 'WCS',
    version: '2.0.1',
    request: 'GetCoverage',
    coverageId: `foropt:${layerName}`,
    // coverageId: 'foropt:l_s_cc_2020_forest_type',
    format: 'geotiff',
    subsettingCrs: 'http://www.opengis.net/def/crs/EPSG/0/4326',
    outputCrs: 'http://www.opengis.net/def/crs/EPSG/0/4326',
    subset: `Lat(${bounds[0].lat},${bounds[2].lat})`
  })
  // workaround for duplicate keys ("subset") in URLSearchParams
  // ref: https://stackoverflow.com/a/43208627/6072959
  options.append('subset', `Long(${bounds[0].lng},${bounds[2].lng})`)
  return mapData.geoserverURL + options
}

// return layer name (as seen in Geoserver and original GeoTIFF)
// based on scenario data
// file naming scheme doc:
// https://docs.google.com/document/d/1jQ_Hhs-xcSU2K-hlA4UXjRVTfzImIH_pkdG0sloOjT8
function _getLayerName(scenario, year) {
  return `l_${scenario.forestMgmt.value}_${scenario.climate.value}_${year}_${scenario.layer.value}`
}

// flatten out values and put them in a single array for chart
// nested array (matrix, really) from geotiff is in the format of float32Array
function _flattenValues(values, noDataValue) {
  // use special flatten function, convert to regular single level array
  let flattened = flatten(values[0], 'array')
  // filter it to remove nodata values
  return flattened.filter(val => val != noDataValue)
}

/**
 * Get a random subset of an array
 * ref: https://stackoverflow.com/a/68504062/6072959
 * @param {Array} arr - Array to take a smaple of.
 * @param {Number} sample_size - Size of sample to pull.
 * @param {Boolean} return_indexes - If true, return indexes rather than members
 * @returns {Array|Boolean} - An array containing random a subset of the members or indexes.
 */
function getArraySample(arr, sample_size, return_indexes = false) {
  if (sample_size > arr.length) return false
  const sample_idxs = []
  const randomIndex = () => Math.floor(Math.random() * arr.length)
  while (sample_size > sample_idxs.length) {
    let idx = randomIndex()
    while (sample_idxs.includes(idx)) idx = randomIndex()
    sample_idxs.push(idx)
  }
  sample_idxs.sort((a, b) => (a > b ? 1 : -1))
  if (return_indexes) return sample_idxs
  return sample_idxs.map(i => arr[i])
}

// module
class RasterLayer {
  // constructor
  constructor(scenario, bounds, opacity, carbon) {
    // bounds is extent used to clip raster
    this.bounds = bounds
    // opacity for layer
    this.opacity = opacity
    // carbon layer?
    this.carbon = carbon
    // use biomass if it's a carbon layer
    scenario.layer.value = this.carbon ? 'biomass' : scenario.layer.value
    // scenarios managed by store
    this.layerData = scenario.layer
    // store scenario
    this.scenario = scenario

    // store layer category with layer
    this.layerCat = scenario.layerCat

    // mean value for all years
    this.allYears = []
    // baseline values
    this.refValues = []

    // should the full array of values be returned or just a sample?
    this.sample = false
    this.sampleSize = 5
  }

  // just return geotiff url from geoserver
  // getGeoTiffURL() {
  //   return _generateWCSUrl(this.layerName, this.bounds)
  // }

  async _getGeorasterForYear(scenario, year, ref = false) {
    // create georaster for the current year
    const layerName = _getLayerName(scenario, year)

    // create georaster from geotiff (via Geoserver)
    const georaster = await this.loadRasterData(layerName)

    // flatten values and store for current year
    let values = _flattenValues(georaster.values, georaster.noDataValue)

    // if taking sample of whole dataset
    if (this.sample) {
      let sampleSize = values.length / this.sampleSize
      values = getArraySample(values, sampleSize)
    }

    // if it's not reference data return the georaster as well
    if (!ref) {
      if (debug) {
        console.log(`getting ${layerName} data....`)
      }
      // store values with class
      this.georaster = georaster
    }
    return values
  }

  // load geotiff via Geoserver WCS as georaster
  async loadRasterData(layerName) {
    if (debug) {
      console.log(`loading ${layerName} raster data....`)
    }
    const geotiff_url = _generateWCSUrl(layerName, this.bounds)
    const geotiff = await fetch(geotiff_url)
    const arrayBuffer = await geotiff.arrayBuffer()
    let georaster = await parse_georaster(arrayBuffer)
    // if it's a carbon layer modify its values
    // carbon = above ground biomass * 0.5
    if (this.carbon) {
      if (debug) console.log('updating biomass values for carbon')
      georaster = await rasterCalculator(georaster, a => a * 0.5)
    }

    if (debug) {
      console.log('georaster', georaster)
    }
    return georaster
  }

  // actually create georaster layer (extends gridlayer)
  async createGeoRasterLayer() {
    // store the urls so they can be run at once
    const urls = []
    let layerName

    // setup reference scenario
    const refScenario = scenarioData.referenceScenario
    refScenario.layer = this.layerData

    // iterate over each of the years and grab the data for it
    // for each year we need data for the current and baseline scenarios
    const years = utils.range(2020, 2100, 10)
    for (let i = 0; i < years.length; i++) {
      // if it's the current year, actually get georasters
      // for the current scenario and the baseline scenario
      if (years[i] == this.scenario.year.value) {
        // current year georaster
        const values = await this._getGeorasterForYear(
          this.scenario,
          years[i],
          false
        )
        // set layer name
        layerName = _getLayerName(this.scenario, years[i])

        // get baseline georaster and store values with layer
        const refValues = await this._getGeorasterForYear(
          refScenario,
          years[i],
          true
        )

        this.allYears.push({
          year: years[i],
          values: values
        })
        this.refValues.push({
          year: years[i],
          values: refValues
        })
      }

      // generate the url to get the data from the SQL view
      else {
        urls.push({
          year: years[i],
          url: _generateWFSURL(
            mapData.forestConfig.sqlview,
            _setViewParams(_getLayerName(this.scenario, years[i]), this.bounds)
          ),
          ref: false
        })
        urls.push({
          year: years[i],
          url: _generateWFSURL(
            mapData.forestConfig.sqlview,
            _setViewParams(_getLayerName(refScenario, years[i]), this.bounds)
          ),
          ref: true
        })
      }
    }

    // run all the SQL view requests synchronously
    // ref: https://stackoverflow.com/a/66635513/6072959
    Promise.all(
      // iterate through the urls
      urls.map(async d => {
        // fetch the data from postgis via geoserver
        const response = await fetch(d.url)
        // parse the response
        const data = await response.json()
        // get mean value from geojson response
        let meanValue = data.features[0].properties.mean
        // apply carbon formula if necessary
        meanValue = this.carbon ? meanValue * 0.5 : meanValue
        // add the data to yearly data arrays
        if (d.ref) {
          this.refValues.push({
            year: d.year,
            values: meanValue
          })
        } else {
          this.allYears.push({
            year: d.year,
            values: meanValue
          })
        }

        // update progress bar
        // const progress = 10 + (idx + 1) * 6
        // document.getElementsByTagName('progress')[0].value = progress
        // console.log(progress, JSON.stringify(d))
        // doesn't work (too much work)
        // https://stackoverflow.com/questions/50400219/bulma-progress-text-in-middle
        // document.getElementsByTagName('progress')[0].innerHTML = `${progress}%`
      })
    )

    let layerData = this.layerData
    let scale = this.layerCat.scale
    let georaster = this.georaster
    let opacity = this.opacity

    this.geoRasterLayer = new GeoRasterLayer({
      debugLevel: 1,
      opacity: opacity,
      georaster: georaster,
      // opacity: 0.7,
      pixelValuesToColorFn: function (values) {
        // determine which set of symbology to use based on whether
        // layer is categorical or continuous raster
        return layerData.type == 'categorical'
          ? categoricalColor(values, georaster, layerData)
          : continuousColor(values, georaster, scale)
      }
      // resolution: 256
    })

    if (debug) {
      console.log(`${layerName} geoRasterLayer created`)
    }
  }
}

// setup factory
const foRasterLayer = (scenario, bounds, opacity, carbon) => {
  return new RasterLayer(scenario, bounds, opacity, carbon)
}

export default foRasterLayer
