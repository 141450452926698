const mapData = {
  // address to hit for geoserver services
  // use http://localhost:5000/geoserver/foropt/ows? if you have local geoserver
  // use https://gis.nrri.umn.edu/for-opt/geoserver/foropt/ows?
  // for the remote geoserver
  geoserverURL: 'https://gis.nrri.umn.edu/for-opt/geoserver/foropt/ows?',

  dataDownloadURL:
    'https://data.nrri.umn.edu/group/forest-change-assessment-and-simulation-tool-forcast',

  // configuration for the landscape rasters
  forestConfig: {
    sqlview: 'raster_stats'
  },

  // configuration for economic values
  econConfig: {
    sqlview: 'econ_values'
  },

  // configuration for mammals feature group
  mammalConfig: {
    // name of sql view as stored in geoserver
    sqlview: 'mammals_allyears',
    // view parameter utility function as specified in foVectorLayers.js
    viewparamFn: 'setWildlifeViewParams',
    // style as specified in layerSymbology.js
    style: 'stylePoly'
  },

  // configuration for birds feature group
  birdConfig: {
    // name of sql view as stored in geoserver
    sqlview: 'birds_allyears',
    // view parameter utility function as specified in foVectorLayers.js
    viewparamFn: 'setWildlifeViewParams',
    // style as specified in layerSymbology.js
    style: 'stylePoly'
  },

  // configuration for water feature group
  waterConfig: {
    // name of sql view as stored in geoserver
    sqlview: 'reaches_allyears',
    dsView: 'ds_reaches',
    // view parameter utility function as specified in foVectorLayers.js
    viewparamFn: 'setWaterViewParams',
    // style as specified in layerSymbology.js
    style: 'styleLine'
  },

  // sqlviews for obtaining reference values, by modeling group
  refValueSqlViews: {
    b: 'habitat_refvalues',
    m: 'habitat_refvalues',
    w: 'water_refvalues'
  },

  // map layers to show on area of interest map
  aoiMapLayers: [
    {
      name: 'Subcatchments',
      value: 'subcatchments',
      color: '#00468c'
    },
    {
      name: 'Township-Block',
      value: 'tb',
      color: '#4d801a'
    }
  ],

  // foropt extent mask - served as a WMS
  foroptExtent: {
    layerType: 'base',
    name: 'Study Area Extent',
    version: '1.3.0',
    format: 'image/png',
    transparent: true,
    layers: 'foropt:foropt_extent_mask',
    // crs: 'EPSG:4326',
    zIndex: 99
  },
  basemap: [
    // open street map
    {
      name: 'Streets',
      value: 'streets',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>contributors',
      thumbnail: 'osm.jpg'
    },
    // CARTO positron light
    {
      name: 'Light',
      value: 'light',
      url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      thumbnail: 'light.jpg'
    },
    // USGS topo
    {
      name: 'Topo',
      value: 'topo',
      url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
      attribution:
        'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>',
      thumbnail: 'topo.jpg'
    },
    // Aerial Imagery
    // consider using mapbox satellite
    {
      name: 'Imagery',
      value: 'imagery',
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attribution:
        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      thumbnail: 'imagery.jpg'
    }
  ]
}
export default mapData
