<template>
  <Head>
    <title>ForCAST Methods: Wildlife Habitat Assessment</title>
    <meta name="description" content="" />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">Wildlife Habitat Assessment</h1>
      <p>
        Habitat quality was assessed for 26 mammal species and 85 bird species
        present in northeastern Minnesota. Habitat quality is an estimate of how
        suitable the habitat in an area is for an animal to live in. One method
        to estimate habitat quality is to calculate a Habitat Suitability Index
        (HSI) value. HSI values can be calculated for individual species, and in
        some cases when several species use the same habitat types, species can
        be grouped together into guilds to calculate an HSI value for a guild.
        In Phase 1 of ForCAST, bird species were grouped into guilds instead of
        having an HSI value calculated for an individual species.
      </p>
      <p>
        Beaver could be an example species to illustrate how an HSI would be
        estimated, using a scale of 0 to 100 for the HSI values. An aspen forest
        next to a stream would have high habitat suitability for a beaver, and
        would be rated 100. If that same aspen forest was a mile from water, the
        habitat suitability would be of no value to beaver, and the HSI would be
        0. Calculating an HSI value becomes a little more complicated when there
        are multiple forest types in an area. We use an area-weighted method.
        For example, if the area is 50% aspen forest with a stream (HSI = 100),
        and 50% conifer forest that is of no value to beaver (HSI = 0), the HSI
        value for the area would be 50.
      </p>
      <p>
        It is also important to know that HSI values estimate habitat
        suitability for a species, but HSI values do not estimate population
        density for a species. A good example illustrating the difference would
        be the gray wolf. Prior to the range expansion of wolves throughout
        northern Minnesota, wolves were only present in the northeastern corner
        of Minnesota. However, a wolf HSI value would have been high throughout
        northern Minnesota because deer were present, even though wolves were
        only present in the northeastern corner of Minnesota.
      </p>
      <h2>WHINGS Model</h2>
      <p>
        HSI values were calculated using the Wildlife Habitat Indicator for
        Native Genera and Species (WHINGS) HSI model developed by Zobel and Ek
        at the University of Minnesota-Twin Cities (Zobel 2013, Zobel and Ek
        2014). The WHINGS HSI model calculates HSI values for bird and mammal
        species that live in the forested area of northern Minnesota. HSI values
        are predicted based on the forest cover type and size class structure in
        the analysis unit. We used an analysis unit of 2 townships as specified
        in the WHINGS model.
      </p>
      <h3>Forest Cover Types and Size Classes</h3>
      <p>
        Forest types are logical ecological groupings of species mixes as
        defined by Eyre (1980), and as implemented in the USFS FIA. Forest cover
        types in LANDIS output are mapped to FIA forest cover types
        (<router-link to="/landscape-modeling#forest_community"
          >additional detail here</router-link
        >) These FIA forest cover types were cross-walked to cover types used in
        WHINGS (Zobel and Ek 2014, Table A1.1). Size classes are calculated
        based on the Diameter at Breast Height (DBH) predicted in the LANDIS
        model. Size classes in the original WHINGS model included
        Seedling/Sapling, Poletimber, and Sawtimber:
      </p>
      <ul>
        <li>
          Seedlings: Live trees smaller than 1.0 inch (2.5 cm) DBH/DRC that are
          at least 6 inches (15.2 cm) in height for softwoods and 12-inches
          (30.5 cm) in height for hardwoods.
        </li>
        <li>
          Saplings: Live trees 1.0 to 4.9 inches (2.5-12.5 cm) in diameter
          (DBH/DRC).
        </li>
        <li>
          Poletimber trees: Live trees at least 5.0 inches in DBH, but smaller
          than sawtimber trees.
        </li>
        <li>
          Sawtimber tree: A live tree of commercial species containing at least
          a 12-foot sawlog or two noncontiguous saw logs 8 feet or longer, and
          meeting regional specifications for freedom from defect. Softwoods
          must be at least 9.0 inches DBH Hardwoods must be at least 11.0 inches
          diameter outside bark (DOB).
        </li>
      </ul>
      <p>
        We added an Overmature size class for older stands to indicate that
        their age was indicative of a stand that could be becoming decadent,
        which would decrease its value for timber. The reason we added this size
        class was because many bird and mammal species rely on tree cavities
        which are more likely to be present in old stands. Tree cavities would
        not be present in stands that are in the seedling and sapling size
        classes, and only small cavities would be present in poletimber stands.
      </p>
      <h3>Landscape-Level HSI Values for Species</h3>
      <p>
        For most mammal and bird species, each combination of forest cover type
        and size class (Sapling, Pole Timber, and Saw Timber) was assigned a
        WHINGS HSI value that ranges from 0 to 10 for mammals, and a scale to
        represent potential abundance for birds (Frelich et al. 2012). To
        calculate a landscape-level HSI value for a species, the area in each
        cover type/size class combination on the landscape is calculated. The
        fraction of each cover type/size class combination in the landscape is
        multiplied by the HSI value for that cover type/size class, and then
        summed across all cover type/size class combinations to calculate the
        overall HSI value for the landscape. Non-forested cover types are
        assigned an HSI value of 0 and are considered to provide no wildlife
        habitat.
      </p>
      <p>
        For ruffed grouse, spruce grouse, black bear, moose, and white-tailed
        deer, HSI values are calculated based on presence and relative abundance
        of specific cover type/size class combinations in the landscape, and the
        HSI for wolves is based on the HSI values for moose and deer (Zobel and
        Ek 2014).
      </p>
      <p>
        In the original WHINGS, HSI values range from 0 to 10 for grouse, black
        bear, moose, and white-tailed deer. HSI values were scaled to range from
        0 to 100 in the ForCAST output. There is also a geographic modifier for
        some species that was not needed in phase 1 of ForCAST because the study
        area was limited to northeastern Minnesota.
      </p>
      <p>
        HSI values are calculated for each climate-harvest LANDIS scenario as
        described above. In the original WHINGS, a minimum mapping unit (MMU) of
        2 townships was used (Zobel and Ek 2014). In ForCAST output, we have
        reduced the MMU to a ¼ township area (3 miles by 3 miles). In Phase 2 of
        ForCAST, we may adjust the MMU to be larger or smaller, and we may scale
        it to the size of a specie&rsquo;s home range.
      </p>
      <p>
        In the past, HSI&rsquo;s were usually created for individual species,
        and interpretation of HSI output would be limited to that species. In
        contrast, in WHINGS an HSI is calculated for 172 species, which makes
        interpretation of output difficult. In the original evaluation of
        WHINGS, model output was aggregated into birds (136 species), grouse (2
        species), small/medium sized mammals (22 species), and large mammals (4
        species). While HSIs can still be evaluated individually, we also
        aggregated HSI values into species groups that could be useful to
        ForCAST users. For example, some pre-defined groups for mammals are game
        species, nongame species, species in greatest conservation need (SGCN),
        and species of special concern (SSC). Game species include species that
        are harvested, while nongame species are not hunted. SGCN species are
        species that can be prioritized for projects when funding is limited,
        and SSC species are species that are uncommon in Minnesota, or have such
        unique or uncommon habitat requirements that the species requires
        careful monitoring (<a
          href="https://www.dnr.state.mn.us/rsg/definitions.html"
          >https://www.dnr.state.mn.us/rsg/definitions.html</a
        >).
      </p>
      <p>
        The WHINGS HSI values could be considered a minimum estimate of habitat
        suitability. One reason for this is that non-forested cover types
        include habitat that would be used by both mammal and bird species, and
        would likely increase the HSI value for an analysis unit. For example,
        wetlands and woody wetlands comprise some of the non-forested cover
        types that are used by many mammal and bird species.
      </p>
      <h3>Example Study Area HSI for Fisher</h3>

      <figure class="image">
        <img
          src="../assets/images/wildlife_fig_1.jpg"
          alt="Map of NE Minnesota with square section delineated with dotted line"
        />
        <figcaption>
          <strong>Figure 1.</strong>
          We used HSI calculations for the fisher to illustrate how ForCAST
          results would appear. The Area of Interest is about 440 square miles
          north and east of Duluth.
        </figcaption>
      </figure>
      <p></p>
      <figure class="image">
        <img
          src="../assets/images/wildlife_fig_2.jpg"
          alt="Map area from above broken up into different colored squares, with legend indicating corresponding HSI values"
        />
        <figcaption>
          <strong>Figure 2.</strong>
          HSI values for fisher range from about 50 to 70 in the minimum mapping
          units (MMUs) in this AOI in 2020.
        </figcaption>
      </figure>

      <figure class="image">
        <img
          src="../assets/images/wildlife_fig_3.png"
          alt="Trend chart showing decreasing HSI values over time for the reference scenario, and slightly increasing values for the scenario with decreased harvest "
        />
        <figcaption>
          <strong>Figure 3.</strong>
          By selecting Chart, it is possible to compare a climate-harvest
          scenario to a reference scenario. In Phase 1, the reference scenario
          is always historic climate and no change in forest harvest. Comparing
          this to historic climate and a 30% decrease in harvest, LANDIS
          projections indicate an increasing HSI for fisher, largely driven by
          the aging of the forest. Note that the increase is not as large as it
          appears, because the y-axis scale only goes from HSI values of 40 to
          70.
        </figcaption>
      </figure>

      <figure class="image">
        <img
          src="../assets/images/wildlife_fig_4.png"
          alt="Trend chart showing decreasing HSI values over time for the reference scenario, and values decreasing more for the scenario with increased harvest"
        />
        <figcaption>
          <strong>Figure 4.</strong>
          In Phase 1, it is only possible to show one current scenario. If you
          change the current scenario to historic climate and a 30% increase in
          harvest, the fisher HSI declines. Note that the y-axis changes
          dynamically, so it now ranges from 35 to 60, and again the apparent
          change is larger than the actual change because the y-axis does not go
          down to zero. The biological reason for the decline in HSI is a
          decrease in the average age of forest stands because of an increase in
          harvest. For the decrease 30% harvest scenario the average age of
          forest stands was 73 years, compared to 53 years for the reference
          data and 48 years for the increase 30% harvest scenario.
        </figcaption>
      </figure>
      <h2>References</h2>
      <p>
        Eyre, F.H. ed. 1980. Forest cover types of the United States and Canada.
        Washington DC: Society of American Foresters; 148 p.
      </p>
      <p>
        Frelich, Lee E.; Ek, Alan R.; Page, Kristen M.. (2012). Wildlife
        database for Minnesota species. Retrieved from the University of
        Minnesota Digital Conservancy,
        <a href="https://hdl.handle.net/11299/169548"
          >https://hdl.handle.net/11299/169548.
        </a>
      </p>
      <p>
        Zobel, John M.. (2013). Modeling forest growth, yield, and wildlife
        habitat in the Lake States. Retrieved from the University of Minnesota
        Digital Conservancy,
        <a href="https://hdl.handle.net/11299/163535"
          >https://hdl.handle.net/11299/163535</a
        >
      </p>
      <p>
        Zobel, John M.; Ek, Alan R.. (2014). The Wildlife Habitat Indicator for
        Native Genera and Species (WHINGS): Methodology and Application.
        University of Minnesota. Retrieved from the University of Minnesota
        Digital Conservancy,
        <a href="https://hdl.handle.net/11299/170677"
          >https://hdl.handle.net/11299/170677</a
        >
      </p>
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  components: {
    Head
  }
}
</script>

<style></style>
