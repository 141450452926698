<template>
  <article class="message is-info info-panel has-background-dark">
    <div class="message-header panel-heading has-background-dark">
      <p>{{ info.title }}</p>
      <button
        class="delete"
        aria-label="delete"
        @click="closePanelClicked()"
      ></button>
    </div>
    <div class="message-body content has-text-white">
      <div v-html="info.body"></div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    info: Object
  },
  methods: {
    // call method on Tool.vue to close slide panel
    closePanelClicked() {
      this.$emit('closePanelClick')
    }
  }
}
</script>

<style scoped>
.info-panel {
  position: relative;
  float: right;
  height: 100%;
  z-index: 1100;
  max-width: 330px;
  overflow: auto;
}
.info-panel .message-body {
  font-size: 0.9375em; /*15px*/
}

/* this width may need to be changed with future development
degrade nicely on smaller screens */
@media (max-width: 740px) {
  .info-panel {
    float: none;
  }
}
.info-panel .delete {
  background-color: #868686;
}
</style>
<style>
/* incoming text files can't be styled with scoped */
.info-panel .message-body a:hover {
  text-decoration: none !important;
}
.info-panel .message-body li {
  line-height: 1.3em;
}
.info-panel .message-body cite {
  display: block;
  font-size: 0.8667em; /*13px*/
  margin-bottom: 1em;
  line-height: 1.3em;
}
</style>
