<template>
  <div class="panel-wrapper">
    <!-- side panel -->
    <aside class="panel slide-panel" :class="show">
      <p class="panel-heading" v-if="show != 'charts'">
        {{ heading }}
        <button class="delete is-pulled-right" @click="closePanelClicked()">
          Close Panel
        </button>
      </p>
      <!-- scenario selection -->
      <div v-if="show == 'scenario'">
        <scenario-selection-pane
          @infoButtonClick="toggleInfoPanel"
          @saveButtonClick="closeInfoPanel"
        ></scenario-selection-pane>
      </div>
      <div v-if="show == 'layers'" class="menu-wrapper">
        <layer-selection-pane-menu
          class="layer-selection-pane-menu"
          :scenario="scenario"
          @onLayerMouseEnter="openInfoPanel($event)"
          @onLayerMouseLeave="closeInfoPanel($event)"
        ></layer-selection-pane-menu>
      </div>
      <!-- charts -->
      <div v-if="show == 'charts'" class="columns m-0">
        <chart-pane :scenario="scenario"></chart-pane>
      </div>
      <!-- map tools -->
      <div v-if="show == 'tools'">
        <tool-pane></tool-pane>
      </div>
      <!-- data and reports -->
      <div v-if="show == 'reports'">
        <data-report-pane></data-report-pane>
      </div>
    </aside>
    <!-- information panel -->
    <transition name="slide">
      <info-panel
        :info="infoData"
        v-if="showInfo"
        @closePanelClick="closeInfoPanel()"
      ></info-panel>
    </transition>
  </div>
</template>

<script>
import ScenarioSelectionPane from './ScenarioSelectionPane.vue'
import LayerSelectionPaneMenu from './LayerSelectionPaneMenu.vue'
import ChartPane from './charts/ChartPane.vue'
import ToolPane from './ToolPane.vue'
import DataReportPane from './DataReportPane.vue'
import InfoPanel from './InfoPanel.vue'
export default {
  components: {
    ScenarioSelectionPane,
    LayerSelectionPaneMenu,
    ChartPane,
    ToolPane,
    DataReportPane,
    InfoPanel
  },
  props: {
    show: String,
    scenario: Object
  },
  data() {
    return {
      debug: true,
      // info panel
      panelOpen: false,
      showInfo: false,
      infoData: {
        title: '',
        body: ''
      }
    }
  },
  watch: {
    // watch which panel should be shown because the
    // info panel should be reset and closed automatically when panel changes
    show(oldShow, newShow) {
      if (this.debug) {
        console.log('watch show', oldShow, newShow)
      }
      if (oldShow != newShow) {
        this.closeInfoPanel()
      }
    }
  },
  computed: {
    // map heading to panel showing
    heading() {
      let panelHeadingMap = {
        scenario: 'Scenario Selection',
        aoi: 'Area of Interest Selection',
        layers: 'Select Model Output',
        tools: 'Tools',
        charts: 'Charts',
        reports: 'Data and Reports'
      }
      let panel = this.show
      // filtering js object
      // ref: https://masteringjs.io/tutorials/fundamentals/filter-object
      return Object.fromEntries(
        Object.entries(panelHeadingMap).filter(function ([key]) {
          return key == panel
        })
      )[panel]
    }
  },
  methods: {
    // call method on Tool.vue to close slide panel
    closePanelClicked() {
      this.$emit('closePanelClick', this.show)
    },
    // on info button click
    openInfoPanel({ data, toggle }) {
      if (this.debug) {
        console.log('openInfoPanel :>> ', this.infoData, this.showInfo, toggle)
      }

      // only toggle for info panels triggered by info buttons
      if (toggle) {
        this.infoData = data
        this.panelOpen = true
        this.showInfo = true
      }
    },
    // on info panel close click
    closeInfoPanel() {
      this.panelOpen = false
      this.showInfo = false
      // reset info panel....info
      this.infoData = {
        title: '',
        body: ''
      }
    },

    // toggle info panel
    toggleInfoPanel({ data, toggle }) {
      if (toggle) {
        if (this.infoData.title == data.title) {
          //if the title of the open panel matches the title sent from button
          //you need to toggle the window and toggle panelOpen
          this.showInfo = !this.showInfo
          this.panelOpen = !this.panelOpen
        } else if (this.panelOpen) {
          //you have clicked on a different title
          //if the panel is already open, just switch data
          this.infoData = data
        } else {
          // the panel is not open, open the panel and switch the data
          this.infoData = data
          this.showInfo = true
          this.panelOpen = true
        }
      }
    }
  },
  emits: ['closePanelClick']
}
</script>

<style scoped>
.panel-wrapper {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
}
.slide-panel {
  position: relative;
  float: right;
  z-index: 1100;
  background-color: white;
  width: auto;
  min-width: 300px;
  max-width: 450px;
  height: 100%;
  margin-bottom: 0 !important;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
}
.slide-panel.charts {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  height: auto;
}
.menu-wrapper {
  height: 100%;
  overflow: hidden;
}
.layer-selection-pane-menu {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>
