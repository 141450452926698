<template>
  <!-- line chart -->
  <vue3-chart-js
    type="line"
    :id="chart.id"
    :data="chart.data"
    :options="chart.options"
    :height="chartHeight"
    ref="chartRef"
  ></vue3-chart-js>
</template>

<script>
// charting library
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
// chartjs plugins
import annotationPlugin from 'chartjs-plugin-annotation'
Vue3ChartJs.registerGlobalPlugins([annotationPlugin])

// utility functions
import utils from '../../modules/utilities.js'

// simple statistics
import { median, mean } from 'simple-statistics'

export default {
  name: 'LineChart',
  components: {
    Vue3ChartJs
  },
  props: {
    currentScenarioData: Object,
    refScenarioData: Object,
    year: String,
    isForest: Boolean,
    isWater: Boolean,
    isEcon: Boolean,
    chartColors: Object,
    refChartColors: Object,
    esMap: Array,
    layerUnitsDisplay: String,
    chartHeight: Number,
    precision: Number
  },
  data() {
    return {
      debug: true
    }
  },
  computed: {
    // chart configuration
    chart() {
      const config = {
        // econ data is a bit different
        data: this.isEcon ? this.econChartData : this.formattedData,
        options: this.options
      }
      if (this.debug) console.log('chart config', config)
      return config
    },

    /* generate data for charts
      data --> {
        labels: labels,
        datasets: [{
          data: { x: y, ... }
        }]
      }
      ref: https://www.chartjs.org/docs/latest/general/data-structures.html
    */
    formattedData() {
      let data = {
        labels: utils.range(2020, 2100, 10),
        datasets: [
          {
            label: 'Current Scenario',
            data: this.timelineChartData(this.currentScenarioData),
            backgroundColor: this.chartColors.background,
            borderColor: this.chartColors.border,
            // no fill
            fill: false,
            // slightly smooth the line
            tension: 0.1
          },
          // reference dataset
          {
            label: 'Reference Data',
            data: this.timelineChartData(this.refScenarioData),
            backgroundColor: this.refChartColors.background,
            borderColor: this.refChartColors.border,
            // no fill
            fill: false,
            // slightly smooth the line
            tension: 0.1
          }
        ]
      }

      return data
    },

    econChartData() {
      let data = {
        labels: utils.range(2020, 2100, 10),
        // generate datasets for econ data
        // should be one for each ecosystem service
        datasets: this.esMap.map(es => {
          return {
            label: es.title,
            data: this.chartDataForES(es),
            backgroundColor: es.chartColors.background,
            borderColor: es.chartColors.border,
            // no fill
            fill: false,
            // slightly smooth the line
            tension: 0.1
          }
        })
      }

      return data
    },

    options() {
      let opt = {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Year'
            }
          },
          y: {
            title: {
              display: true,
              text: (() => {
                if (this.isForest) {
                  return `Mean ${this.layerUnitsDisplay}`
                } else if (this.isWater) {
                  return `Flow-weighted mean in ${this.layerUnitsDisplay}`
                } else if (this.isEcon) {
                  return this.layerUnitsDisplay
                } else {
                  return `Median ${this.layerUnitsDisplay}`
                }
              })(),
              padding: 6
            },
            grace: '10%',
            // if layer has significant digits specified use them,
            // otherwise round to whole numbers
            ticks: {
              precision: this.precision ? this.precision : 0
            }
          }
        },
        elements: {
          point: {
            // used to highlight the current year
            radius: this.highlightCurrentYearPoint,
            display: true
          }
        }
      }

      // econ only options
      if (this.isEcon) {
        // add solid line at zero for econ data
        opt.plugins = {
          annotation: {
            annotations: {
              zero: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: '#000',
                borderWidth: 2
              }
            }
          },

          // add dollar sign to tooltip
          // ref: https://www.chartjs.org/docs/3.0.2/configuration/tooltip.html
          tooltip: {
            callbacks: {
              label: function (context) {
                var label = context.dataset.label || ''

                if (label) {
                  label += ': '
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(context.parsed.y)
                }
                return label
              }
            }
          }
        }

        // bold the zero
        // ref: https://stackoverflow.com/a/63208940/6072959
        opt.scales.y.ticks = {
          major: {
            enabled: true,
            fontStyle: 'bold',
            fontColor: '#000'
          }
        }
        opt.scales.y.afterBuildTicks = scale => {
          scale.ticks.forEach(t => {
            t.major = t.value == 0
          })
          return scale
        }
      }

      return opt
    },

    // sets point radius for trend chart - used to highlight the current year
    highlightCurrentYearPoint() {
      const year = parseInt(this.year)
      const years = utils.range(2020, 2100, 10)
      return function (context) {
        // assign color based on value
        // for use with chroma.js color scale
        // scale to 0 - 1 used by chroma
        const index = context.dataIndex
        return index == years.indexOf(year) ? 8 : 2
      }
    }
  },

  methods: {
    // generate data for timeline (trend) chart
    // data arg is an array in the format:
    //  [ year: xxxx, values: [xx,xx,xx]]
    timelineChartData(data) {
      // make sure data is sorted
      const sortedData = utils.sortArrayOfObjects(data, 'year')
      // iterate over data and find median (mean?) for each year
      return sortedData.map(yr => {
        let returnVal
        // if it's an array of values return the median value (or mean for forest)
        // otherwise just return the single value (as in the case with water)
        if (this.isForest) {
          returnVal = Array.isArray(yr.values) ? mean(yr.values) : yr.values
        } else {
          returnVal = Array.isArray(yr.values) ? median(yr.values) : yr.values
        }
        console.log('timelineChartData', yr.year, returnVal)
        return returnVal
      })
    },

    // return array of values for specific ecosystem service
    chartDataForES(es) {
      // make sure data is sorted
      const sortedData = utils.sortArrayOfObjects(
        this.currentScenarioData,
        'year'
      )
      // iterate over data and return the ecosystem service value
      return sortedData.map(yr => yr.values[`${es.name}_value`])
    },

    // add utility functions here so they are available in template
    ...utils
  }
}
</script>
