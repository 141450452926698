<template>
  <!-- legend container - based on bulma card -->
  <div class="card" v-if="layer">
    <header class="card-header">
      <p class="card-header-title">Legend</p>
      <button
        class="card-header-icon"
        aria-label="toggle"
        @click="toggleLegend"
      >
        <span class="icon" v-if="showLegend">
          <fa :icon="['fa', 'angle-up']" />
        </span>
        <span class="icon" v-if="layer && !showLegend">
          <fa :icon="['fa', 'angle-down']" />
        </span>
      </button>
    </header>
    <div class="card-content" v-if="layer && showLegend">
      <div class="title">{{ layer.layerData.name }}</div>
      <div v-if="layer.layerData.legendSubTitle" class="subtitle">
        {{ layer.layerData.legendSubTitle }}
      </div>
      <!-- categorical data -->
      <div
        class="categorical-container"
        v-if="layer.layerData.type == 'categorical'"
      >
        <div
          v-for="(category, index) in layer.layerData.valueMap"
          :key="category.name"
          class="my-1"
        >
          <i :style="{ 'background-color': layer.layerData.colors[index] }"></i
          >{{ category.name }}
        </div>
      </div>
      <!-- continuous data -->
      <div class="columns" v-if="layer.layerData.type == 'continuous'">
        <div class="column is-narrow pr-0 gradient">
          <div
            v-for="color in colors"
            :key="color"
            :style="{ 'background-color': color }"
            class="grad-step"
          ></div>
        </div>
        <div class="column legend-labels is-flex is-flex-direction-column">
          <label class="top"
            >&mdash;&nbsp;{{ max.toLocaleString() }}
            <span v-if="layer.layerData.units">{{
              layer.layerData.units.short
            }}</span></label
          >
          <label class="bottom"
            >&mdash;&nbsp;{{ mid }}
            <span v-if="layer.layerData.units">{{
              layer.layerData.units.short
            }}</span></label
          >
          <!-- ideally this shouldn't be hardcoded -->
          <label class="bottom"
            >&mdash;&nbsp;{{ min.toLocaleString() }}
            <span v-if="layer.layerData.units">{{
              layer.layerData.units.short
            }}</span></label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ref: https://gka.github.io/chroma.js
// for color scaling
import chroma from 'chroma-js'

import utils from '@/modules/utilities.js'

export default {
  props: {
    layer: Object
  },
  data() {
    return {
      showLegend: true
    }
  },
  computed: {
    // color scale
    scale() {
      return chroma.scale(this.layer.layerCat.scale)
    },

    // generate array of colors for gradient
    // generate 100 colors
    // ref: https://github.com/gka/chroma.js/blob/master/docs/src/footer.inc.html#L70-L80
    colors() {
      let colors = []
      for (let i = 100; i > 0; i--) {
        colors.push(this.scale(i / 100).hex())
      }
      return colors
    },

    // determine if layer is raster
    isRaster() {
      return this.layer.georaster ? true : false
    },

    // minimum value for legend
    min() {
      if (this.isRaster) {
        // for raster just return the minimum for the first (and only) band
        return this.layer.georaster.mins[0].toLocaleString()
      } else {
        // check for significant digits
        if (this.layer.layerCat.sigdigs) {
          return utils.significantDigits(
            Math.min(...this.layer.values),
            this.layer.layerCat.sigdigs
          )
        }
        return utils.round(Math.min(...this.layer.values), 0)
      }
    },

    // maximum value for legend
    max() {
      if (this.isRaster) {
        // for raster just return the maximum for the first (and only) band
        return this.layer.georaster.maxs[0].toLocaleString()
      } else {
        // check for significant digits
        if (this.layer.layerCat.sigdigs) {
          return utils.significantDigits(
            Math.max(...this.layer.values),
            this.layer.layerCat.sigdigs
          )
        }
        return utils.round(Math.max(...this.layer.values), 0)
      }
    },

    // midway value for legend
    mid() {
      let midVal
      if (this.isRaster) {
        midVal =
          (this.layer.georaster.maxs[0] - this.layer.georaster.mins[0]) / 2 +
          this.layer.georaster.mins[0]
      } else {
        midVal = (this.max - this.min) / 2 + this.min
      }
      // check for significant digits
      if (this.layer.layerCat.sigdigs) {
        return utils.significantDigits(midVal, this.layer.layerCat.sigdigs)
      }
      return utils.round(midVal, 0).toLocaleString()
    }
  },
  methods: {
    // toggle legend
    // only show if layer is supplied
    toggleLegend() {
      this.showLegend = this.layer && !this.showLegend ? true : false
    }
  }
}
</script>
<style>
.leaflet-top.leaflet-left {
  /* this is here so that we can add a scroll to the legend */
  bottom: 40px;
  overflow: hidden;
}
</style>
<style scoped>
.card {
  /* min-width: 125px; */
  max-width: 250px;
}
.card-content {
  padding: 0.5rem;
  /* set the max height of the legend content allow to scroll */
  max-height: calc(100vh - 250px);
  overflow: auto;
}
.card-header-icon {
  padding: 0;
}
.card-header-title {
  padding: 0.5rem;
}
.card .columns {
  margin: 0;
  display: flex !important;
}
.card .column {
  padding: 0.5rem 0.5rem 0.5rem 0;
}
.card .column.is-narrow {
  flex: none;
}

.card .title {
  font-size: 0.875rem;
}

.card .card .title:not(.is-spaced) + .subtitle {
  margin-top: -1.4rem;
}
.subtitle {
  margin-bottom: 0 !important;
  font-size: 0.75rem;
}

/*
  color gradient
  ref: https://gka.github.io/chroma.js/#chroma-scale 
*/
.gradient {
  height: 150px;
}
/* gradient step */
.grad-step {
  line-height: 1px;
  font-size: 1px;
  height: 1%;
  width: 40px;
}
.legend-labels {
  line-height: 6px;
  font-weight: bold;
}
.bottom {
  margin-top: auto;
}
.categorical-container i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 1;
}
</style>
<style>
.leaflet-top.leaflet-left {
  /* this is here so that I can add a scroll to the legend */
  bottom: 40px;
  overflow: hidden;
}
</style>
