/*
  tourData.js
  Configuration for the tool introduction or tour
  ref: https://github.com/alexandreDavid/vue3-tour
*/

const tourData = {
  options: {
    useKeyboardNavigation: true,
    labels: {
      buttonSkip: 'Skip tour',
      buttonPrevious: 'Previous',
      buttonNext: 'Next',
      buttonStop: 'Finish'
    },
    highlight: true
  },
  steps: [
    {
      target: 'ul.menu-list.is-size-3 li:nth-child(1)',
      header: {
        title: 'Step 1. Create an Area of interest'
      },
      content: `Your area of interest (AOI) will be used to render the modeling data and 
    create statistical summaries. You can modify or create a new AOI 
    clicking the <em>Map Area</em> button.`,
      params: {
        placement: 'left'
      }
    },
    {
      target: 'ul.menu-list.is-size-3 li:nth-child(2)',
      header: {
        title: 'Step 2. Scenario Selection'
      },
      content: `Here, you will choose a climate and 
    forest management scenario, and additionally, a year in the future, that 
    will be used to determine which data is selected.`,
      params: {
        placement: 'left'
      }
    },
    {
      target: 'ul.menu-list.is-size-3 li:nth-child(3)',
      header: {
        title: 'Step 3. Model Output Selection'
      },
      content: `Finally, you can select which model output you would like to view. 
      These are the modeled metrics generated based on the scenario you selected above. 
      They are broken down by categories: 
      forest, mammal habitat quality, bird habitat quality, water, carbon, and economic.`,
      params: {
        placement: 'left'
      }
    },
    {
      target: 'ul.menu-list.is-size-3 li:nth-child(4)',
      header: {
        title: 'Charts and Data Summaries'
      },
      content: `After you have selected a model output, charts and data summaries
      provide additional insight into the patterns for that metric, 
      how it compares to the baseline scenario, 
      and how things are forecasted to change over time.`,
      params: {
        placement: 'left'
      }
    },
    {
      target: 'ul.menu-list.is-size-3 li:nth-child(5)',
      header: {
        title: 'Tools'
      },
      content: `Clicking this button will pull up map tools allowing you to 
    change the basemap and alter map layer opacity.`,
      // download the model output data, and save your work on the tool.`,
      params: {
        placement: 'left'
      }
    },
    {
      target: '#helpDropdown',
      header: {
        title: 'Help'
      },
      content: `You can always revisit the information provided here by 
    clicking the <em>Help</em> button.
    Enjoy the show!`,
      params: {
        placement: 'bottom'
      }
    }
  ]
}

export default tourData
