<template>
  <Head>
    <title>ForCAST Methods: Landscape and Carbon Modeling</title>
    <meta name="description" content="" />
  </Head>
  <section class="section">
    <div id="top" class="container general-content">
      <h1 class="title">Forest Landscape Modeling</h1>
      <h2>LANDIS-II</h2>
      <p>
        LANDIS-II is a species-level, cell-based forest landscape model that
        simulates forest growth, succession, and disturbances over large
        landscapes at moderate spatial resolution. Various model extensions
        allow simulation of both natural and harvest disturbances, though only
        harvest disturbances were considered here. Further, input climate
        factors impact tree growth, mortality, and establishment; thus, LANDIS
        can be used to analyze climate-change impacts on forest growth and
        succession.
      </p>
      <p>
        LANDIS code and required input files were obtained from the authors of
        two previous studies in the region (Lucash et al. 2017; Duveneck et al.
        2014). Data and code from Lucash et al. (2017) covered the western half
        of the study area, while comparable inputs from Duveneck et al. (2014)
        covered the eastern half. For simplicity, we will refer to these sources
        as the Lucash model and the Duveneck model, respectively.
      </p>
      <p>
        LANDIS simulations for the period 2020-2100 were conducted across four
        management scenarios and three climate scenarios for a total of 24 runs
        (4 mgt. scenarios-by-3 climate scenarios-by-2 halves of the study area).
        Lucash models were run at 200 m spatial resolution (4 ha cells) while
        Duveneck models were run at 140 m spatial resolution (2 ha cells).
        Output from the Duveneck model was then re-projected to 200 m resolution
        by nearest-neighbor resampling. Subsequently, model outputs were
        stitched together across the entire study area at 200 m resolution.
      </p>

      <h2 id="forest">Forest Management Scenarios</h2>

      <p>
        The management activities for each timber type were developed based on
        scientific literature as well as surveys of public and private land
        managers to understand what practices are commonly applied when
        harvesting (thinning and rotational harvests) Minnesota&lsquo;s
        commercial timber types.
      </p>
      <p>
        The “likelihood” of whether a stand is harvested is a random process. A
        forest stand&lsquo;s eligibility for harvest is based on ownership type,
        forest type, tree species&lsquo; ages, and time since last harvest
        (rotation length). The amount harvested is governed by land ownership.
        State and county lands have a much higher likelihood of being harvested
        than private lands.
      </p>
      <p>
        Stands must meet one or more qualifications to be eligible for harvest
        including composition (forest type) and time (minimum age, maximum age,
        minimum time since last harvest)
      </p>
      <p>
        The four management scenarios that we evaluated as a part of this
        process were:
      </p>
      <ol>
        <li>
          "Business as usual", which represents current management regimes based
          on typical harvest practices for each forest type by landowner type in
          the study area. The business-as-usual management scenarios were
          obtained from similar work done by Lucash on the Chippewa National
          Forest (Lucash et al 2017).
        </li>
        <li>
          Increased harvest by 30% from current. Harvest amount increases were
          commensurate with the established likelihood to harvest parameters
          established in the business-as-usual case.
        </li>
        <li>
          Decreased harvest by 30% from current. Harvest amount was decreased
          commensurate with the established likelihood to harvest parameters
          established in the business-as-usual case.
        </li>
        <li>
          "Max carbon sequestration", in which harvest was decreased by 30% from
          current, as well as increased time between harvests. Harvest amount
          was decreased commensurate with the established likelihood to harvest
          parameters established in the business-as-usual case.
        </li>
      </ol>
      <p>
        Note: The management scenarios were obtained from the Lucash model but
        were applied across the entire study area in order to maintain
        consistency. In general, harvest rates were higher on state and county
        lands, as compared to National Forest and private non-industrial forest.
      </p>

      <h2 id="climate">Climate Scenarios</h2>

      <p>
        The three climate scenarios included historical climate, &ldquo;low
        emissions&rdquo;, and &ldquo;high emissions&rdquo;. For historical
        climate, monthly minimum and maximum air temperature and monthly
        precipitation from the period 1979-2010 were randomly sampled to
        generate 2020-2100 time series&rsquo; for LANDIS runs. Low and high
        emissions scenarios came from the Intergovernmental Panel on Climate
        Change (IPCC) Special Report on Emissions Scenarios and represent the B1
        scenario (approximately 50% decrease in energy production) and the A1FI
        scenario (approximately 400% increase in energy production),
        respectively. Future projected climate data from General Circulation
        Models (GCMs) were used as inputs for these scenarios; specifically, the
        Parallel Climate Model under the B1 scenario and the Global Fluid
        Dynamics Laboratory GCM under the A1FI scenario.
      </p>
      <h2>Initial Conditions</h2>
      <p>
        Initial conditions for all model runs included initial vegetation
        communities, stand maps, ecoregions (areas with similar climates and
        soils), and land ownership type (private industrial, private
        non-industrial, county, state, and federal). Vegetation communities at
        each grid cell were composed of species age cohorts. For example, a
        community classified as &ldquo;Aspen&rdquo;, might be composed of a
        dominant 60 year-old aspen cohort with 30-year black spruce and 20-year
        balsam fir as subordinate cohorts. Changes in tree communities over time
        (growth and succession) were simulated by the &ldquo;Century
        Succession&rdquo; extension module in the area covered by the Lucash
        model and by the &ldquo;Biomass Succession&rdquo; extension in the area
        covered by the Duveneck model. Harvest was simulated by the &ldquo;Leaf
        Biomass Harvest&rdquo; extension. Changes in forest community
        composition due to forest succession or harvest were analyzed by the
        &ldquo;Leaf Biomass Reclass&rdquo; extension. For instance, in our Aspen
        example, changes in relative biomass with stand age (with no harvest)
        could lead to reclassification as Black Spruce or Balsam Fir forest
        types. Conversely, a harvested Aspen community would likely remain Aspen
        due to re-sprouting following clear-cut harvest.
      </p>

      <h3 id="forest_community">Forest Community Types</h3>
      <p>
        Characterized by dominant species, there were 16 forest community types
        designated: Aspen, Aspen-Birch, Balsam Fir, Balsam Poplar, Black Spruce,
        Elm-Ash-Cottonwood, Jack Pine, Maple-Basswood, Northern White Cedar,
        Oak, Paper Birch, Red Pine, Tamarack, White Pine, White Spruce, and Red
        Maple. For purposes of illustration, the initial forest communities in
        2020 can be further generalized to seven classes (Figure 1). This shows
        the dominance of Aspen and Spruce-Fir in eastern portions of the study
        area, with more Northern Hardwoods, Tamarack, Cedar, and pine species in
        other parts of the study area.
      </p>
      <figure class="image">
        <img
          src="../assets/images/landis_fig_1.jpg"
          alt="map of watersheds NW of Lake Superior with colored dots indicating areas with the tree species groups of aspen-poplar-birch, elm-ash-cottonwood, northern hardwoods, tamarack, spruce-fir, jack-red-white pine and white cedar"
        />
        <figcaption>
          <strong>Figure 1.</strong>
          Initial forest community types in year 2020 of model simulations.
        </figcaption>
      </figure>
      <h2>Model Output</h2>
      <p>
        At ten year intervals from 2020 to 2100, four geospatial data layers
        were generated for broader use by the project team. These included two
        biomass variables:
      </p>
      <ol>
        <li>
          Standing live biomass reported for 2020, 2030, 2040, etc. (Biomass
          above ground) and
        </li>
        <li>
          Biomass harvested over ten-year intervals; i.e., from 2020-2030,
          2030-2040, 2040-2050, etc. reported in years 2030, 2040, 2050, etc.
          (Harvest)
        </li>
      </ol>
      <p>Two attributes of forest stands were generated:</p>
      <ol>
        <li>Stand age and</li>
        <li>Forest community type (Cover type).</li>
      </ol>
      <p>
        Stand age equaled the age of the oldest tree species cohort in each grid
        cell.
      </p>
      <p>
        Initial aboveground biomass for the year 2020 is shown in Figure 2. When
        compared with Figure 1, biomass levels were generally higher in the
        Aspen, Spruce-Fir, and Northern Hardwoods portions of the study area,
        with lower levels in the wetter, central part of the study area.
      </p>
      <figure class="image">
        <img
          src="../assets/images/landis_fig_2.jpg"
          alt="map of watersheds NW of Lake Superior with colored dots indicating concentrations of biomass in grams per meter squared"
        />
        <figcaption>
          <strong>Figure 2.</strong>
          Initial aboveground biomass (g/m&sup2;) in year 2020 of model
          simulations.
        </figcaption>
      </figure>
      <p>
        By year 2100 of simulations, biomass levels increased appreciably under
        the current climate (Figure 3), with the highest levels under scenarios
        that decreased harvest levels (decrease 30% and maximize carbon
        sequestration; Figure 3C, D). By contrast, under the high climate change
        scenario, biomass in Aspen-dominated or Spruce-Fir stands remained
        fairly static, or declined (Figure 4). This effect was slightly reduced
        under lower harvest levels (Figure 4C, D). At the same time, northern
        hardwoods in the western half of the study area exhibited similar
        biomass increases, regardless of climate change (Figure 4).
      </p>

      <div class="columns is-multiline m-0">
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img
              src="../assets/images/landis_fig_3a.jpg"
              alt="maps of watersheds NW of Lake Superior with colored dots indicating concentrations of biomass in grams per meter squared"
            />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_3b.jpg" alt="" />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_3c.jpg" alt="" />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_3d.jpg" alt="" />
          </figure>
        </div>
        <figcaption>
          <strong>Figure 3.</strong> Aboveground biomass (g/m&sup2;) at the end
          of simulations (year 2100) as a function of management scenario, with
          current climate. A) Business-as-usual; B) Increase harvest 30%; C)
          Decrease harvest 30%; D) Maximize carbon sequestration.
        </figcaption>
      </div>
      <div class="columns is-multiline m-0">
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img
              src="../assets/images/landis_fig_4a.jpg"
              alt="maps of watersheds NW of Lake Superior with colored dots indicating concentrations of biomass in grams per meter squared"
            />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_4b.jpg" alt="" />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_4c.jpg" alt="" />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_4d.jpg" alt="" />
          </figure>
        </div>
        <figcaption>
          <strong>Figure 4.</strong> Aboveground biomass, as in Figure 3, but
          under the A1FI (high) climate change scenario.
        </figcaption>
      </div>
      Figure 5 shows differences in the extent of harvest between the max carbon
      scenario (Figure 5A) and the increase 30% scenario (Figure 5B), both with
      current climate. Under high climate change (Figure 6), the extent of
      harvest remained similar, but total harvest dropped.
      <div class="columns is-multiline m-0">
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img
              src="../assets/images/landis_fig_5a.jpg"
              alt="maps of watersheds NW of Lake Superior with colored dots indicating concentrations of biomass in grams per meter squared"
            />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_5b.jpg" alt="" />
          </figure>
        </div>
        <figcaption>
          <strong>Figure 5.</strong> Total harvest (g/m&sup2;) over the entire
          simulation, 2020-2100, under current climate for the: A) Max carbon
          scenario, and B) Increase harvest 30% scenario.
        </figcaption>
      </div>
      <div class="columns is-multiline m-0">
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img
              src="../assets/images/landis_fig_6a.jpg"
              alt="maps of watersheds NW of Lake Superior with colored dots indicating concentrations of biomass in grams per meter squared"
            />
          </figure>
        </div>
        <div class="column is-half pb-0 pl-0">
          <figure class="image">
            <img src="../assets/images/landis_fig_6b.jpg" alt="" />
          </figure>
        </div>
        <figcaption>
          <strong>Figure 6.</strong> Total harvest (g/m&sup2;) over the entire
          simulation, 2020-2100, under the A1FI (high) climate change scenario
          for the: A) Max carbon scenario, and B) Increase harvest 30% scenario.
        </figcaption>
      </div>
      <p>
        The examples in Figures 3-6, show how LANDIS captures species-level
        differences in responses to climate change. These differences are most
        apparent for Aspen, the main economic species in the region, which may
        take up less carbon and produce less timber harvest under drier/hotter
        summers. Models which do not incorporate climate change, like Figure 3,
        may severely overestimate tree growth over the next eighty years.
      </p>
      <h2>References</h2>
      <p>
        D&lsquo;Amato, Anthony W.; Bolton, Nicholas W.; Blinn, Charles R.; Ek,
        Alan R.. (2009). Current Status and Long-term Trends of Silvicultural
        Practices in Minnesota: A 2008 Assessment. University of Minnesota.
        Retrieved from the University of Minnesota Digital Conservancy,
        <a href="https://hdl.handle.net/11299/107773"
          >https://hdl.handle.net/11299/107773</a
        >.
      </p>
      <p>
        Duveneck MJ, Scheller RM, White MA, Handler SD, Ravenscroft C. 2014.
        Climate change effects on northern Great Lake (USA) forests: A case for
        preserving diversity. Ecosphere 5:23.
      </p>
      <p>
        Lucash MS, Scheller RM, Gustafson EJ, Sturtevant BR. 2017. Spatial
        resilience of forested landscapes under climate change and management.
        Landscape Ecology 32:953-969.
      </p>
      <p>
        Nakicenovic, Nebojsa., Joseph Alcamo, Gerald Davis, Bert d. Vries,
        Joergen Fenhann, Stuart Gaffin, Kenneth Gregory, Arnulf Gr&uuml;bler,
        Tae Y. Jung, Tom Kram, Emilio Lebre L. Rovere, Laurie Michaelis,
        Shunsuke Mori, Tsuneyuki Morita, William Pepper, Hugh Pitcher, Lynn
        Price, Keywan Riahi, Alexander Roehrl, Hans-Holger Rogner, Alexei
        Sankovski, Michael Schlesinger, Priyadarshi Shukla, Steven Smith, Robert
        Swart, Sascha v. Rooijen, Nadejda Victor, and Zhou Dadi. IPCC: Special
        Report on Emissions Scenarios., 2000. Available at
        <a href="https://www.ipcc.ch/report/emissions-scenarios/"
          >https://www.ipcc.ch/report/emissions-scenarios/</a
        >
      </p>
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  components: {
    Head
  }
}
</script>

<style></style>
