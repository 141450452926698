<template>
  <div class="block">
    Click below to generate a link that can be used to share/load the current
    tool settings
  </div>
  <div class="block">
    <!-- button to generate link -->
    <button
      class="button"
      :class="[link ? 'is-success' : 'is-primary']"
      @click="generateLink"
      :disabled="!activateSave"
    >
      <span class="icon is-small">
        <fa :icon="['fa', 'link']" />
      </span>
      <span>Generate Link</span>
    </button>
    <div
      class="notification is-warning is-light p-3 is-size-7-plus"
      v-if="!activateSave"
    >
      You must select Scenarios and Model Outputs before you can generate a
      link.
    </div>
  </div>

  <!-- where generated link appears -->
  <div class="block" v-if="link">
    <div class="level has-text-info">
      <div class="level-left level-item">
        {{ link }}
      </div>
      <!-- copy button -->
      <div class="level-left level-item" v-if="canCopy">
        <button class="button is-info" @click="copy(link)">
          <span class="icon is-small">
            <fa :icon="['fa', 'copy']" />
          </span>
          <span>Copy</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import stores to get current app state
import theStore from '../store/store.js'

// import URL serialize functionality
import serialize from '@/modules/serializer.js'

export default {
  props: {
    //this is set to false until a layer has been selected.  This disables the share generate link button until all options set.
    activateSave: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      link: null,
      canCopy: false
    }
  },
  created() {
    // check if clipboard is available
    this.canCopy = !!navigator.clipboard
  },
  methods: {
    // copy to clipboard
    // src: https://www.raymondcamden.com/2020/03/04/vue-quick-shot-copy-to-the-clipboard
    async copy(s) {
      await navigator.clipboard.writeText(s)
    },
    // generate link that captures app state
    async generateLink() {
      // convert store objects to url query params
      // ref: https://stackoverflow.com/a/57529723/6072959

      // ignore some state properties
      const ignore = ['layerData', 'refData']
      // filter out out the ignore properties from the store
      // ref: https://masteringjs.io/tutorials/fundamentals/filter-key
      let state = Object.fromEntries(
        Object.entries(theStore.store.state).filter(
          ([key]) => !ignore.includes(key)
        )
      )

      // we don't need everything from most of the stored data,
      // just the "value" prop
      // these are the data where we want everything
      const notJustValue = [
        'aoi',
        'subcatchments',
        'townshipBlocks',
        'layerOpacity'
      ]
      for (const key in state) {
        // don't alter the items listed in notJustValue
        if (notJustValue.includes(key)) {
          continue
        }
        // only keep "value"
        // ref: https://stackoverflow.com/questions/22202766/keeping-only-certain-properties-in-a-javascript-object
        state[key] = (({ value }) => ({ value }))(state[key])
      }

      // create new URL
      const url = new URL(window.location.href)
      // set url params equal to searlized state
      const params = { p: state }
      url.search = serialize(params)

      console.log('generateLink', JSON.stringify(params), url.href)

      // this.link = url.href
      this.link = await this.getBitLink(url.href)
    },

    // shorten link using bitly
    // ref: https://dev.bitly.com/api-reference#createBitlink
    async getBitLink(url) {
      const data = {
        // replace localhost with 127.0.0.1
        // ref: https://stackoverflow.com/questions/64920574/bitly-convert-localhost-url-cause-error-invalid-arg-long-url-is-it-expected
        long_url: url.replace('localhost', '127.0.0.1')
      }
      const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer 676796786ee97ee3eaae687d1a51e4fb91a914a2',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      const shorten = await response.json()
      console.log('getBitLink', shorten)
      return shorten.link
    }
  }
}
</script>

<style scoped></style>
