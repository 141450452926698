// file name variables
// scenario data values
// ref: https://docs.google.com/spreadsheets/d/1SFGeUyi9d4Do3GaR1t_3PmlyhWFmVMfO0BhozBxh48U
// or: https://docs.google.com/document/d/1jQ_Hhs-xcSU2K-hlA4UXjRVTfzImIH_pkdG0sloOjT8
const scenarioData = {
  // mgmt scenarios
  forestMgmt: [
    { value: 's', name: 'No Change' },
    { value: 'i30', name: '30% Increase Harvest' },
    { value: 'd30', name: '30% Decrease Harvest' },
    { value: 'mc', name: 'Max Carbon Sequestration' }
  ],

  // climate scenarios
  climate: [
    { value: 'cc', name: 'Historical Climate' },
    { value: 'low', name: 'Low Emissions' },
    { value: 'high', name: 'High Emissions' }
  ],

  // years
  year: [
    { name: '2020', value: '2020' },
    { name: '2030', value: '2030' },
    { name: '2040', value: '2040' },
    { name: '2050', value: '2050' },
    { name: '2060', value: '2060' },
    { name: '2070', value: '2070' },
    { name: '2080', value: '2080' },
    { name: '2090', value: '2090' },
    { name: '2100', value: '2100' }
  ],

  // baseline or reference scenario
  referenceScenario: {
    climate: { value: 'cc', name: 'Historic' },
    forestMgmt: { value: 's', name: 'No Change' },
    year: { name: '2020', value: '2020' }
  },

  // type is either categorical or continuous
  components: [
    // forest
    {
      name: 'forest',
      title: 'Forest',
      value: 'l',
      // color scale used by chroma.js (eg. chroma.scale('Viridis'))
      scale: 'YlGn',
      layers: [
        {
          value: 'biomass',
          name: 'Biomass above ground',
          type: 'continuous',
          units: {
            short: 'mT/ha',
            long: 'metric tonnes per hectare'
          }
        },
        {
          value: 'harvest',
          name: 'Harvest',
          type: 'continuous',
          units: {
            short: 'grams/sq.m.',
            long: 'grams per square meter'
          }
        },
        {
          value: 'standage',
          name: 'Stand age',
          type: 'continuous',
          units: {
            short: 'years',
            long: 'age in years'
          }
        },
        {
          value: 'whngs',
          name: 'Cover type',
          type: 'categorical',
          units: {
            short: 'species',
            long: 'tree species'
          },

          // tree species value - code mapping
          // value ref: https://drive.google.com/file/d/19E_ZbhG5PwYyTOF4zngeM3omBXXg7RnD/view?usp=sharing
          // code lookup: https://www.fia.fs.fed.us/library/database-documentation/historic/ver6/FIADB_User_Guide_P2_6-1-1_final.pdf
          valueMap: [
            { value: 1, name: 'Aspen', fiaCode: 'Aspen_901' },
            {
              value: 2,
              name: 'Black Ash / American Elm / Red Maple',
              fiaCode: 'BlAshElmRedMap_701'
            },
            { value: 3, name: 'Tamarack', fiaCode: 'Tamarack_126' },
            { value: 4, name: 'Black Spruce', fiaCode: 'BlackSpruce_125 ' },
            { value: 5, name: 'Paper Birch', fiaCode: 'PaperBirch_902' },
            { value: 6, name: 'Red Pine', fiaCode: 'RedPine_102' },
            {
              value: 7,
              name: 'Hard Maple / Basswood / Mixed Upland Hardwoods',
              fiaCode: 'HardMaplBassw_805_MixedUplHardwds_520'
            },
            { value: 8, name: 'Oak Group', fiaCode: 'Oaks_50X' },
            { value: 9, name: 'Jack Pine', fiaCode: 'JackPine_101' },
            { value: 10, name: 'White Pine', fiaCode: 'WhitePine_103' },
            { value: 11, name: 'Balsam Fir', fiaCode: 'BalsamFir_121' },
            { value: 12, name: 'White Spruce', fiaCode: 'WhiteSpruce_122' },
            { value: 13, name: 'Cedar', fiaCode: 'Cedar_127' },
            {
              value: 14,
              name: 'White Pine Group',
              fiaCode: 'WhitePineGroup_40X'
            },
            {
              value: 15,
              name: 'Elm / Ash / Black Locust',
              fiaCode: 'ElmAsh_517'
            },
            { value: 16, name: 'Red Maple / Oak', fiaCode: 'RedMapleOak_519 ' },
            {
              value: 17,
              name: 'Sugar Maple / Beech / Yellow Birch',
              fiaCode: 'SugMapBeechYB_801'
            },
            { value: 18, name: 'Balsam Poplar', fiaCode: 'BalsamPop_904' }
          ],
          // FIXME: this shouldn't be hard-coded but should be re-coded in the geotiff
          noDataValues: [-1, 0],
          // colors generated using https://medialab.github.io/iwanthue/
          colors: [
            '#ce95c6',
            '#88d046',
            '#6f50c4',
            '#cfbb43',
            '#cb4fbd',
            '#5fd48c',
            '#c9467a',
            '#558933',
            '#673e76',
            '#d1792f',
            '#6485c0',
            '#d1433c',
            '#62c1cb',
            '#813937',
            '#aac389',
            '#d7947a',
            '#3f6a47',
            '#7e672d'
          ]
        }
      ]
    },
    // mammals
    {
      name: 'mHabQual',
      title: 'Mammal Habitat Quality',
      value: 'm',
      // color scale used by chroma.js (eg. chroma.scale('Viridis'))
      scale: 'OrRd',
      layers: [
        {
          name: 'Game species',
          value: 'IsGame',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Small game species',
          value: 'IsSmallGame',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Nongame species',
          value: 'IsNonGame',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Furbearer species',
          value: 'IsFurbearer',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Species of special concern',
          value: 'IsSSC',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Species in greatest conservation need',
          value: 'IsSGCN',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'break'
        },
        {
          name: 'American marten',
          value: 'MAAM',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Beaver',
          value: 'CACA',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Black bear',
          value: 'URAM',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Bobcat',
          value: 'LYRU',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Canada lynx',
          value: 'LYCA',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Deer mouse',
          value: 'PEMA',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Eastern chipmunk',
          value: 'TAST',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Fisher',
          value: 'PEPE',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Fox squirrel',
          value: 'SCNI',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Gray fox',
          value: 'URCI',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Gray squirrel',
          value: 'SCCA',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Gray wolf',
          value: 'CALU',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Least chipmunk',
          value: 'TAMI',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Meadow jumping mouse',
          value: 'ZAHU',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Meadow vole',
          value: 'MIPE',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Moose',
          value: 'ALAL',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Northern flying squirrel',
          value: 'GLSA',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Porcupine',
          value: 'ERDO',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Red fox',
          value: 'VUVU',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Red squirrel',
          value: 'TAHU',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Snowshoe hare',
          value: 'LEAM',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Southern flying squirrel',
          value: 'GLVO',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Southern red-backed vole',
          value: 'MYGA',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'White-footed mouse',
          value: 'PELE',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'White-tailed deer',
          value: 'ODVI',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },

        {
          name: 'Woodland jumping mouse',
          value: 'NAIN',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        }
      ]
    },
    // birds
    {
      name: 'bHabQual',
      title: 'Bird Habitat Quality',
      value: 'b',
      // color scale used by chroma.js (eg. chroma.scale('Viridis'))
      scale: 'RdPu',
      layers: [
        {
          name: 'Birds of conservation concern',
          value: 'consStatusYforest',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Birds of coniferous forests',
          value: 'ConiferousForest',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Birds of deciduous forests',
          value: 'DeciduousForest',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Birds of early successional mixed forests',
          value: 'EarlySuccessionalMixed',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Birds of fields and meadows',
          value: 'FieldsMeadows',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Birds of lowland coniferous forests',
          value: 'LowlandConiferousForest',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Birds of mixed forests',
          value: 'MixedForest',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Birds of shrub swamps',
          value: 'ShrubSwamp',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Bird game species',
          value: 'gameSpp',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'High vulnerability to climate change',
          value: 'HV',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Low vulnerability to climate change',
          value: 'LV',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Moderate vulnerability to climate change',
          value: 'MV',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        },
        {
          name: 'Stable to climate change',
          value: 'S',
          type: 'continuous',
          legendSubTitle: 'Habitat Suitability Index'
        }
      ]
    },
    // water
    {
      name: 'water',
      title: 'Water',
      value: 'w',
      // color scale used by chroma.js
      // customize color scale so linear water features are more prominent on map
      scale: [
        '#c8bed4',
        '#a5b3d6',
        '#8da5ce',
        '#7798c4',
        '#628ab8',
        '#4f7dad',
        '#3a6fa0',
        '#246294',
        '#005587'
      ],
      // number of significant digits to use for display
      sigdigs: 2,
      layers: [
        {
          name: 'Mean annual flow',
          value: 'qmean',
          type: 'continuous',
          units: {
            short: 'cfs',
            long: 'cubic feet per second'
          }
        },
        {
          name: 'Spring high flow',
          value: 'q10',
          type: 'continuous',
          units: {
            short: 'cfs',
            long: 'cubic feet per second'
          }
        },
        {
          name: 'Summer low flow',
          value: 'q90',
          type: 'continuous',
          units: {
            short: 'cfs',
            long: 'cubic feet per second'
          }
        },
        {
          name: '2 year flood flow',
          value: 'q2y',
          type: 'continuous',
          units: {
            short: 'cfs',
            long: 'cubic feet per second'
          }
        },
        {
          name: 'Suspended sediment concentration',
          value: 'ssed',
          type: 'continuous',
          units: {
            short: 'mg/L',
            long: 'milligram per liter'
          }
        },
        {
          name: 'Total phosphorus concentration',
          value: 'ptot',
          type: 'continuous',
          units: {
            short: 'mg/L',
            long: 'milligram per liter'
          }
        },
        {
          name: 'Total nitrogen concentration',
          value: 'ntot',
          type: 'continuous',
          units: {
            short: 'mg/L',
            long: 'milligram per liter'
          }
        }
      ]
    },
    //carbon
    {
      name: 'carbon',
      title: 'Carbon',
      value: 'c',
      // color scale used by chroma.js (eg. chroma.scale('Viridis'))
      scale: 'YlOrBr',
      layers: [
        {
          name: 'Carbon',
          value: 'carb',
          type: 'continuous',
          units: {
            short: 'mT/ha',
            long: 'metric tonnes per hectare'
          }
        }
      ]
    },
    // economic
    {
      name: 'econ',
      title: 'Economic',
      value: 'e',
      // color scale used by chroma.js (eg. chroma.scale('Viridis'))
      scale: null,
      layers: [
        {
          name: 'Economic Valuation',
          value: 'econ',
          type: 'continuous',
          units: {
            short: `$`,
            long: `Dollars per Household per Month`
          },
          // value map for ecosystem service and data component
          // based on doc ("Economics method description"):
          // https://docs.google.com/document/d/1WB3TBh93J24gZBr2mivwzhyqSOWoK90vr7NwHHoTDW4
          esMap: [
            {
              title: 'Timber Production',
              name: 'timber',
              scale: 'YlGn'
            },
            {
              title: 'Game Species Habitat Quality',
              name: 'game',
              scale: 'OrRd'
            },
            {
              title: 'Carbon Sequestration',
              name: 'carbon',
              scale: 'YlOrBr'
            },
            {
              title: 'Nongame Species Habitat Quality',
              name: 'non_game',
              scale: 'RdPu'
            },
            {
              title: 'Water Quality',
              name: 'wq',
              scale: [
                '#c8bed4',
                '#a5b3d6',
                '#8da5ce',
                '#7798c4',
                '#628ab8',
                '#4f7dad',
                '#3a6fa0',
                '#246294',
                '#005587'
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default scenarioData
