<template>
  <Head>
    <title>
      ForCAST Methods: Hydrologic Modeling and Water Quality Assessment
    </title>
    <meta name="description" content="" />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">Water Quantity and Quality Modeling</h1>
      <h2>Overview</h2>
      <p>
        Simulations of the response of water quantity and quality to forest
        harvest scenarios and climate change scenarios were performed using
        Hydrologic Simulation Program - Fortran (HSPF) models for the watersheds
        that cover the study area: Lake Superior South, the St. Louis River, the
        Cloquet River, and the Mississippi River - Grand Rapids watershed
        (Figure 1). Three existing HSPF models were used as a basis – these
        models were created by Tetra Tech and RESPEC for the Minnesota Pollution
        Control Agency (MPCA), and include simulation of runoff and water
        quality (sediment, phosphorus, and nitrogen). The HSPF models use
        HUC-12s (USGS 12-digit Hydrologic Unit Codes) as sub-catchments (Figure
        1), which determines the minimum spatial scale of the model output data.
        The models run at sub-daily time steps using hourly climate input data;
        model outputs were saved at daily time steps. For water quality
        modeling, various external inputs of nutrients and contaminants, such as
        atmospheric deposition of nitrogen and phosphorus, are specified for
        each model using additional external input files. These inputs are part
        of the model calibration for historical conditions, and were not altered
        for the forest harvest and future climate scenarios.
      </p>

      <figure class="image">
        <img
          src="../assets/images/water_fig_1.jpg"
          alt="Map of coverage area including the Mississippi River - Grand Rapids Watershed, St. Louis Watershed, and Superior South Watershed"
        />
        <figcaption>
          <strong>Figure 1.</strong>
          Coverage of the study area using three existing HSPF models. The St.
          Louis model includes the St. Louis and Cloquet rivers. The HUC-12
          sub-catchments are shown in the upper figure, and the stream reach
          network is shown in the lower figure.
        </figcaption>
      </figure>
      <h2>Mapping of LANDIS forest types to HSPF Models</h2>
      <p>
        The MPCA HSPF models for the St. Louis and Superior South watersheds use
        only two basic forest land cover types (deciduous and evergreen). The
        Mississippi Grand Rapids model uses an additional level of complexity
        for forest specification, with young/old subtypes for deciduous and
        evergreen. For the purposes of this project, more flexibility in
        specifying forest cover was needed, to represent harvest and regrowth of
        forests and the corresponding changes in evapotranspiration rates. The
        overall process for modifying the existing HSPF models to accept input
        of LANDIS-generated forest data is summarized in Figure 2. The main
        modification of the existing HSPF models was to replace the existing
        forest classes with a total of six forest classes (3 evergreen, 3
        deciduous) plus a clearcut land class.
      </p>

      <figure class="image">
        <img
          src="../assets/images/water_fig_2.png"
          alt="Schematic explaining the process used  to modify  HSPF models. For forested hydrologic response units, adjust the model with the input of LANDIS rasters of evergreen, deciduous, and clearcut biomass.  In the case of non forested and forested wetland hydrologic response units, no change is necessary."
        />
        <figcaption>
          <strong>Figure 2.</strong>
          Schematic of the methodology for modifying the HSPF models to accept
          LANDIS forest data as inputs. HRU = hydrologic response unit.
        </figcaption>
      </figure>
      <p>
        For upland (non-wetland) forested areas, LANDIS classes are mapped to
        HSPF classes via the classification given in Table 1. It would also be
        possible to classify forests based on age classes, however, biomass and
        age were found to be fairly correlated to each other when the LANDIS
        data is summarized by sub-catchment (Figure 3). The spatial extent of
        forested wetlands was determined from the HRU maps of the existing
        models, and remained fixed. The forested wetland HRU were not adjusted
        for biomass changes or forest composition changes, based on information
        in Sebestyen (2011) that the hydrology of forested wetlands is
        relatively insensitive to harvest compared to upland forest.
      </p>
      <table class="table">
        <caption>
          <strong>Table 1.</strong>
          LANDIS and HSPF biomass classes. 1=Deciduous (Aspen/Birch dominant),
          2=Conifer
        </caption>
        <thead>
          <tr>
            <th>LANDIS Class</th>
            <th>HSPF Class</th>
          </tr>
        </thead>
        <tr>
          <td>Aspen-Birch</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Aspen</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Balsam Fir</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Balsam Poplar</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Black Spruce</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Elm-Ash-Cottonwood</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Jack Pine</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Maple Basswood</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Northern White-Cedar</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Oak</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Paper Birch</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Red Pine</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Tamarack</td>
          <td>2</td>
        </tr>
        <tr>
          <td>White Pine</td>
          <td>2</td>
        </tr>
        <tr>
          <td>White Spruce</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Red Maple</td>
          <td>1</td>
        </tr>
      </table>
      <figure class="image">
        <img
          src="../assets/images/water_fig_3.png"
          alt="Scatter plot showing Aspen/Birch biomass vs age.  A linear correlation between biomass and age is shown."
          style="width: 500px"
        />
        <figcaption>
          <strong>Figure 3.</strong>
          Aspen/birch biomass vs. Age for 2020 LANDIS outputs (blue circles) and
          2070 LANDIS outputs (red crosses), averaged by sub-catchment.
        </figcaption>
      </figure>

      <h2>Forest Biomass Classes</h2>
      <p>
        Based on data from the Marcell Experimental Forest (Verry 1976, Verry
        1987, Bernath et al. 1982), the following biomass classes and
        corresponding water use parameter values were established for deciduous
        (aspen) and coniferous (red pine). For an aspen forest, annual runoff
        depth increased by about 100 mm after harvesting. Post-harvest, runoff
        levels reduced to their original values after about 15 years and a
        biomass of about 60 tonne/hectare (6000 g/m&sup2;). For a red pine
        forest, runoff levels increased about 140 mm after harvest.
        Post-harvest, runoff levels reduced to their original values after about
        40 years and a biomass of about 120 tonne/hectare (12000 g/m&sup2;). For
        mature forests, red pine forests were found to have 60 mm less annual
        runoff compared to aspen.
      </p>
      <p>
        For setting the HSPF model parameters for each forest class, the overall
        changes in observed runoff need to be partitioned between changes in
        canopy evaporation and changes in forest transpiration. The canopy
        interception parameter controls how much rainfall is captured by the
        tree canopy and subsequently evaporated. The interception parameter is
        specified monthly (MON-INTERCEP), to consider differences between
        deciduous and evergreen trees. The LZET parameter influences ET by
        controlling how efficiently lower zone soil water can be used by plant
        roots for transpiration. According to Nisbet (2005), evaporative losses
        from canopy interception for evergreen forests are about double those of
        deciduous forests.
      </p>
      <p>
        A sensitivity analysis was used to determine how to adjust the HSPF
        parameters for each forest type/biomass class to obtain the targeted ET
        for each class. The sensitivity analysis was conducted using an HSPF
        model for the Knife River with 8 catchments, a river in the study area
        that was modeled in a previous study. The Knife river model was used to
        determine what values of canopy interception and the LZET parameter give
        runoff depths for deciduous, pine, and clearcut forests, as follows:
      </p>
      <ul>
        <li>
          It was assumed that the current MPCA HSPF canopy interception and LZET
          parameters for forest are representative of deciduous forest (the
          watershed currently is 26% deciduous, 12% evergreen, and 25% mixed
          forest).
        </li>
        <li>
          The Knife river model was run using the nominal MPCA HSPF canopy
          interception and LZET parameters (see Table 2 below).
        </li>
        <li>
          The Knife river model was run a second time using the MPCA HSPF canopy
          interception and LZET parameters corresponding to barren ground.
        </li>
        <li>
          To represent evergreen forest, the MPCA HSPF model was run a third
          time using LZET and canopy interception approximately double those of
          the MPCA HSPF forest parameters (Table 2).
        </li>
      </ul>
      <p>
        The simulated mean annual runoff for each case is given in Table 3, and
        the differences in runoff approximate the previously given literature
        for clearcutting aspen and pine forests, and replacing aspen with pine.
        For the Knife river model, the HSPF parameter values given in Table 2
        result in changes in runoff between deciduous, evergreen, and clearcut
        cases that are close to those given by Verry et al.
      </p>
      <table class="table">
        <caption>
          <strong>Table 2.</strong>
          Monthly LZET and canopy Interception parameters for the current MPCA
          HSPF model for the St. Louis-Cloquet rivers, and for a proposed
          evergreen class.
        </caption>
        <thead>
          <tr>
            <th class="thtitle" colspan="13">Monthly LZET</th>
          </tr>
          <tr>
            <th></th>
            <th>Jan</th>
            <th>Feb</th>
            <th>Mar</th>
            <th>Apr</th>
            <th>May</th>
            <th>Jun</th>
            <th>Jul</th>
            <th>Aug</th>
            <th>Sep</th>
            <th>Oct</th>
            <th>Nov</th>
            <th>Dec</th>
          </tr>
        </thead>
        <tr>
          <td>MPCA Barren</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.20</td>
        </tr>
        <tr>
          <td>MPCA Forest</td>
          <td>0.15</td>
          <td>0.15</td>
          <td>0.20</td>
          <td>0.20</td>
          <td>0.40</td>
          <td>0.66</td>
          <td>0.72</td>
          <td>0.72</td>
          <td>0.69</td>
          <td>0.56</td>
          <td>0.32</td>
          <td>0.15</td>
        </tr>
        <tr>
          <td>Evergreen</td>
          <td>0.40</td>
          <td>0.40</td>
          <td>0.40</td>
          <td>0.40</td>
          <td>0.64</td>
          <td>1.06</td>
          <td>1.15</td>
          <td>1.15</td>
          <td>1.10</td>
          <td>0.90</td>
          <td>0.51</td>
          <td>0.40</td>
        </tr>
        <thead>
          <tr>
            <th class="thtitle" colspan="13">Monthly Canopy Interception</th>
          </tr>
          <tr>
            <th></th>
            <th>Jan</th>
            <th>Feb</th>
            <th>Mar</th>
            <th>Apr</th>
            <th>May</th>
            <th>Jun</th>
            <th>Jul</th>
            <th>Aug</th>
            <th>Sep</th>
            <th>Oct</th>
            <th>Nov</th>
            <th>Dec</th>
          </tr>
        </thead>
        <tr>
          <td>MPCA Barren</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
        </tr>
        <tr>
          <td>MPCA Forest</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.03</td>
          <td>0.064</td>
          <td>0.107</td>
          <td>0.132</td>
          <td>0.149</td>
          <td>0.143</td>
          <td>0.104</td>
          <td>0.051</td>
          <td>0.03</td>
        </tr>
        <tr>
          <td>Evergreen</td>
          <td>0.2</td>
          <td>0.2</td>
          <td>0.2</td>
          <td>0.2</td>
          <td>0.225</td>
          <td>0.25</td>
          <td>0.275</td>
          <td>0.3</td>
          <td>0.29</td>
          <td>0.265</td>
          <td>0.225</td>
          <td>0.2</td>
        </tr>
      </table>
      <table class="table">
        <caption>
          <strong>Table 3.</strong>
          Simulated mean annual runoff from the Knife river HSPF model for 3
          land cover cases, and differences in runoff between land cover
          classes.
        </caption>

        <thead>
          <tr>
            <th>Land Cover</th>
            <th>Simulated Runoff (mm)</th>
            <th>Verry et al. Results (mm)</th>
          </tr>
        </thead>
        <tr>
          <td>MPCA Barren</td>
          <td>357</td>
          <td></td>
        </tr>
        <tr>
          <td>MPCA Forest</td>
          <td>266</td>
          <td></td>
        </tr>
        <tr>
          <td>Evergreen</td>
          <td>208</td>
          <td></td>
        </tr>
        <tr>
          <td>MPCA Barren – MPCA Forest</td>
          <td>91</td>
          <td>100</td>
        </tr>
        <tr>
          <td>MPCA Barren - Evergreen</td>
          <td>149</td>
          <td>140</td>
        </tr>
        <tr>
          <td>MPCA Forest - Evergreen</td>
          <td>58</td>
          <td>60</td>
        </tr>
      </table>
      <p>
        The LZET and canopy Interception parameters given in Table 2 represent
        mature forest and clearcut conditions. Parameter values for the biomass
        classes used to represent forest growth stages (Table 4) were linearly
        interpolated between the mature forest and clearcut cases, leading to
        the parameter values given in Table 5.
      </p>
      <p>
        The other HSPF hydrologic parameter that should be adjusted for
        different forest conditions is the snow shading parameter, which
        influences how fast snow melts due to solar radiation. The current MPCA
        HSPF model uses a snow shade coefficient of 0.25 for deciduous forests
        and 0.85 for evergreen forests. Using the Knife river HSPF model, and
        assuming a shading coefficient 0.25 for deciduous forests and 0 for
        clearcut land, it was found that the spring snowmelt peak increased
        about 30% due to clearcut. Verry et al. (1983) estimates a doubling in
        the snowmelt peak for clearcutting an aspen forest, suggesting that the
        HSPF model simulated snowmelt is under-responding to forest harvest.
        However, given a general lack of data on the subject, it will be assumed
        that the current MPCA HSPF model snow shading coefficients are
        representative of mature forests, and that they can be linearly
        interpolated for the biomass classes (Table 6).
      </p>
      <p>
        The HSPF Forest parameter, which influences winter evaporation, will be
        maintained at its current values in each model for deciduous and
        evergreen forests, but will be set to 0 for clearcut.
      </p>
      <table class="table">
        <caption>
          <strong>Table 4.</strong>
          Biomass classes for deciduous and evergreen forests. CC=clearcut.
        </caption>
        <thead>
          <tr>
            <th class="thtitle" colspan="2">Deciduous</th>
            <td class="noborder"></td>
            <th class="thtitle" colspan="2">Evergreen</th>
          </tr>
          <tr>
            <th>Biomass Class (g/m&sup2;)</th>
            <th>Name</th>
            <td class="noborder"></td>
            <th>Biomass Class (g/m&sup2;)</th>
            <th>Name</th>
          </tr>
        </thead>
        <tr>
          <td>0</td>
          <td>CC</td>
          <td class="noborder"></td>
          <td>0</td>
          <td>CC</td>
        </tr>
        <tr>
          <td>1 - 2000</td>
          <td>Dec1</td>
          <td class="noborder"></td>
          <td>1 - 2000</td>
          <td>Evr1</td>
        </tr>
        <tr>
          <td>2000 - 6000</td>
          <td>Dec2</td>
          <td class="noborder"></td>
          <td>2000 - 6000</td>
          <td>Evr2</td>
        </tr>
        <tr>
          <td>&gt;6000</td>
          <td>Dec3</td>
          <td class="noborder"></td>
          <td>&gt;6000</td>
          <td>Evr3</td>
        </tr>
      </table>

      <table class="table">
        <caption>
          <strong>Table 5.</strong>
          Monthly canopy interception and LZET parameters for the deciduous and
          evergreen biomass classes.
        </caption>
        <thead>
          <tr>
            <th class="thtitle" colspan="13">Canopy Interception (in)</th>
          </tr>
          <tr>
            <th>Class</th>
            <th>Jan</th>
            <th>Feb</th>
            <th>Mar</th>
            <th>Apr</th>
            <th>May</th>
            <th>Jun</th>
            <th>Jul</th>
            <th>Aug</th>
            <th>Sep</th>
            <th>Oct</th>
            <th>Nov</th>
            <th>Dec</th>
          </tr>
        </thead>
        <tr>
          <th>CC</th>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
        </tr>
        <tr>
          <th>Evr1</th>
          <td>0.050</td>
          <td>0.050</td>
          <td>0.050</td>
          <td>0.050</td>
          <td>0.057</td>
          <td>0.063</td>
          <td>0.069</td>
          <td>0.075</td>
          <td>0.073</td>
          <td>0.067</td>
          <td>0.057</td>
          <td>0.050</td>
        </tr>
        <tr>
          <th>Evr2</th>
          <td>0.120</td>
          <td>0.120</td>
          <td>0.120</td>
          <td>0.120</td>
          <td>0.135</td>
          <td>0.150</td>
          <td>0.165</td>
          <td>0.180</td>
          <td>0.174</td>
          <td>0.159</td>
          <td>0.135</td>
          <td>0.120</td>
        </tr>
        <tr>
          <th>Evr3</th>
          <td>0.173</td>
          <td>0.173</td>
          <td>0.173</td>
          <td>0.173</td>
          <td>0.195</td>
          <td>0.217</td>
          <td>0.238</td>
          <td>0.260</td>
          <td>0.251</td>
          <td>0.230</td>
          <td>0.195</td>
          <td>0.173</td>
        </tr>
        <tr>
          <th>Dec1</th>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.032</td>
          <td>0.054</td>
          <td>0.067</td>
          <td>0.075</td>
          <td>0.072</td>
          <td>0.052</td>
          <td>0.029</td>
          <td>0.030</td>
        </tr>
        <tr>
          <th>Dec2</th>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.054</td>
          <td>0.090</td>
          <td>0.111</td>
          <td>0.125</td>
          <td>0.120</td>
          <td>0.087</td>
          <td>0.043</td>
          <td>0.030</td>
        </tr>
        <tr>
          <th>Dec3</th>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.030</td>
          <td>0.064</td>
          <td>0.108</td>
          <td>0.133</td>
          <td>0.150</td>
          <td>0.144</td>
          <td>0.105</td>
          <td>0.051</td>
          <td>0.030</td>
        </tr>
        <thead>
          <tr>
            <th class="thtitle" colspan="13">LZET</th>
          </tr>
          <tr>
            <th>Class</th>
            <th>Jan</th>
            <th>Feb</th>
            <th>Mar</th>
            <th>Apr</th>
            <th>May</th>
            <th>Jun</th>
            <th>Jul</th>
            <th>Aug</th>
            <th>Sep</th>
            <th>Oct</th>
            <th>Nov</th>
            <th>Dec</th>
          </tr>
        </thead>
        <tr>
          <th>CC</th>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
        </tr>
        <tr>
          <th>Evr1</th>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.222</td>
          <td>0.367</td>
          <td>0.400</td>
          <td>0.400</td>
          <td>0.383</td>
          <td>0.311</td>
          <td>0.111</td>
          <td>0.111</td>
        </tr>
        <tr>
          <th>Evr2</th>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.461</td>
          <td>0.761</td>
          <td>0.830</td>
          <td>0.830</td>
          <td>0.795</td>
          <td>0.646</td>
          <td>0.369</td>
          <td>0.144</td>
        </tr>
        <tr>
          <th>Evr3</th>
          <td>0.347</td>
          <td>0.347</td>
          <td>0.347</td>
          <td>0.347</td>
          <td>0.694</td>
          <td>1.146</td>
          <td>1.250</td>
          <td>1.250</td>
          <td>1.198</td>
          <td>0.972</td>
          <td>0.556</td>
          <td>0.347</td>
        </tr>
        <tr>
          <th>Dec1</th>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.220</td>
          <td>0.348</td>
          <td>0.380</td>
          <td>0.380</td>
          <td>0.364</td>
          <td>0.296</td>
          <td>0.200</td>
          <td>0.200</td>
        </tr>
        <tr>
          <th>Dec2</th>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.339</td>
          <td>0.559</td>
          <td>0.610</td>
          <td>0.610</td>
          <td>0.585</td>
          <td>0.474</td>
          <td>0.271</td>
          <td>0.200</td>
        </tr>
        <tr>
          <th>Dec3</th>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.200</td>
          <td>0.411</td>
          <td>0.678</td>
          <td>0.740</td>
          <td>0.740</td>
          <td>0.709</td>
          <td>0.576</td>
          <td>0.329</td>
          <td>0.200</td>
        </tr>
      </table>

      <table class="table">
        <caption>
          <strong>Table 6.</strong>
          Snow shading coefficient (SNOW-PARM1 SHADE) for the forest biomass
          classes
        </caption>
        <thead>
          <tr>
            <th>Class</th>
            <th>Shade</th>
          </tr>
        </thead>
        <tr>
          <td>CC</td>
          <td>0.00</td>
        </tr>
        <tr>
          <td>Evr1</td>
          <td>0.20</td>
        </tr>
        <tr>
          <td>Evr2</td>
          <td>0.50</td>
        </tr>
        <tr>
          <td>Evr3</td>
          <td>0.75</td>
        </tr>
        <tr>
          <td>Dec1</td>
          <td>0.12</td>
        </tr>
        <tr>
          <td>Dec2</td>
          <td>0.21</td>
        </tr>
        <tr>
          <td>Dec3</td>
          <td>0.26</td>
        </tr>
      </table>
      <h2>HSPF Model Sediment and Nutrient Loading Coefficients</h2>
      <p>
        Existing literature was used to determine how the sediment coefficients
        in the HSPF model should be changed for the different forest classes.
        The sediment response has two components (McEachran et al. 2021):
      </p>
      <ol>
        <li>
          The changes in sediment loading from channel erosion associated with
          changes in flow, particularly changes in peak flow rates. The current
          MPCA HSPF models represent these erosion processes, and it is assumed
          no changes in the coefficients for these processes are needed.
        </li>
        <li>
          The changes in sediment loading due to changes in loading rates
          (mass/area/time) from land areas that are harvested. Published field
          data indicates that forest harvest can lead to a short term (a year or
          two) increase in sediment loading rates, but that the level of
          increase depends on the harvest practices, e.g. leaving stream buffer
          strips (McEachran et al. 2018), and for northeast Minnesota, forest
          harvest practices generally do not lead to increased sediment loading.
          As a result, the sediment loading rate coefficients in the HSPF model
          were not adjusted from their nominal values.
        </li>
      </ol>
      <p>
        Nutrient loading (phosphorus, nitrogen) is modeled in HSPF includes
        nutrient loading associated with sediment, along with atmospheric
        deposition and point sources. Point source inputs and atmospheric
        deposition inputs were not changed from the original model
        specifications for each of the three HSPF models. Similarly, the
        coefficients relating nutrient export from land surfaces to sediment
        export were not changed. As a result, changes calculated in nutrient
        loading due to forest harvest and climate change were due to changes in
        hydrology and bank erosion.
      </p>
      <h2>Future Climate Inputs to the HSPF Model</h2>
      <p>
        Future climate inputs to the HSPF models were constructed from two
        global climate model (GCM) outputs. The GCMs used match those used in
        the LANDIS simulations, and include one GCM model output from a low CO2
        emission scenario (PCM B1) and one GCM model output from a high emission
        scenario (GFDL A1F1) from the CMIP3 model runs (ref). The projected
        changes in mean annual air temperature and mean annual precipitation for
        these two CMIP3 models are compared to an ensemble of 20 GCMs from the
        more recent CMIP5 model runs. The GFDL A1F1 scenario is relatively wet
        and hot, while the PCM B1 scenario is relatively cool and dry. The GFDL
        and PCM scenarios do not consider a relatively hot and dry future, which
        would result in the lowest future streamflow.
      </p>
      <figure class="image">
        <img
          src="../assets/images/water_fig_4.png"
          style="width: 500px"
          alt="Scatter plot comparing mean annual precipitation change to mean annual air temperature change.  Points from the 20 GCMs appear randomly scattered. PCM(low)is at a low precip and temperature change.  CMIP3 and CMIP5 are at a high precip and low temperature change.  GDFL(high) is at a high precip and temperature change "
        />
        <figcaption>
          <strong>Figure 4.</strong>
          Projected change in mean annual precipitation versus projected change
          in mean annual air temperature for the two CMIP3 GCMs used in this
          study and for 20 GCMs from the CMIP5 model runs.
        </figcaption>
      </figure>

      <p>
        Downscaled GCM outputs for air temperature and precipitation were
        obtained from
        <a
          href="https://gdo-dcp.ucllnl.org/downscaled_cmip_projections/dcpInterface.html#Welcome"
          >https://gdo-dcp.ucllnl.org/downscaled_cmip_projections/dcpInterface.html#Welcome</a
        >
        for the PCM B1 model and
        <a href="https://cida.usgs.gov/gdp/">https://cida.usgs.gov/gdp/</a> for
        the GFDL A1F1 model. Non-downscaled model outputs for wind speed, solar
        radiation, and humidity were obtained from
        <a href="https://esgf-node.llnl.gov/search/cmip3/"
          >https://esgf-node.llnl.gov/search/cmip3/</a
        >.
      </p>
      <p>
        Monthly change increments were calculated for each of the 10-year future
        periods: 2020-2029, 2030-2039, 2040-2049, 2050-2059, 2060-2069,
        2070-2079, 2080-2089, and 2090-2099. For air temperature, increments
        were calculated as an additive correction, whereas for precipitation,
        wind speed, solar radiation, and humidity, multiplicative monthly
        corrections were calculated. GCM model outputs were downloaded for all
        future periods and for a historical period (2003-2013), and monthly
        increments were calculated as (future/historical) for air temperature
        and (future/historical) for the other parameters.
      </p>
      <p>
        Based on the GFDL model outputs for 2060-2069, the corrections given in
        Table 7 were applied to the historical climate data for a climate
        station in the St. Louis River watershed. For example, all air
        temperatures in May were increased by 2.8 ̊C and all precipitation events
        in May were increased by a factor of 1.02. A separate table of
        corrections was calculated for each climate station and each decade.
      </p>
      <p>
        Finally, the monthly corrections were used to generate a future climate
        file for each climate station and each decade.
      </p>

      <table class="table">
        <caption>
          <strong>Table 7.</strong>
          Example climate increments for climate station 1 in the St. Louis
          river watershed, for the GFDL A1F1 GCM. The air temperature increments
          are additive, the rest are multiplicative.
        </caption>
        <thead>
          <tr>
            <th></th>
            <th>Air Temp</th>
            <th>Precip</th>
            <th>Humidity</th>
            <th>Solar</th>
            <th>Wind</th>
          </tr>
        </thead>
        <tr>
          <th>Jan</th>
          <td>4.07</td>
          <td>1.21</td>
          <td>1.30</td>
          <td>1.45</td>
          <td>0.93</td>
        </tr>
        <tr>
          <th>Feb</th>
          <td>3.97</td>
          <td>1.29</td>
          <td>1.66</td>
          <td>1.38</td>
          <td>0.80</td>
        </tr>
        <tr>
          <th>Mar</th>
          <td>1.35</td>
          <td>0.91</td>
          <td>2.05</td>
          <td>1.32</td>
          <td>0.91</td>
        </tr>
        <tr>
          <th>Apr</th>
          <td>1.20</td>
          <td>1.38</td>
          <td>2.26</td>
          <td>1.19</td>
          <td>0.94</td>
        </tr>
        <tr>
          <th>May</th>
          <td>2.80</td>
          <td>1.02</td>
          <td>1.86</td>
          <td>1.24</td>
          <td>1.08</td>
        </tr>
        <tr>
          <th>Jun</th>
          <td>4.20</td>
          <td>0.85</td>
          <td>1.52</td>
          <td>1.11</td>
          <td>1.01</td>
        </tr>
        <tr>
          <th>Jul</th>
          <td>6.60</td>
          <td>0.45</td>
          <td>1.25</td>
          <td>0.85</td>
          <td>0.62</td>
        </tr>
        <tr>
          <th>Aug</th>
          <td>6.96</td>
          <td>0.45</td>
          <td>1.07</td>
          <td>0.76</td>
          <td>1.50</td>
        </tr>
        <tr>
          <th>Sep</th>
          <td>4.92</td>
          <td>0.72</td>
          <td>0.87</td>
          <td>0.55</td>
          <td>1.15</td>
        </tr>
        <tr>
          <th>Oct</th>
          <td>2.76</td>
          <td>1.55</td>
          <td>0.95</td>
          <td>0.52</td>
          <td>1.10</td>
        </tr>
        <tr>
          <th>Nov</th>
          <td>2.05</td>
          <td>1.14</td>
          <td>0.97</td>
          <td>0.72</td>
          <td>1.14</td>
        </tr>
        <tr>
          <th>Dec</th>
          <td>3.15</td>
          <td>0.98</td>
          <td>1.09</td>
          <td>1.20</td>
          <td>1.32</td>
        </tr>
      </table>
      <h2>HSPF Model Workflow</h2>
      <p>
        The following general workflow was used to create the final water
        simulation results for this project:
      </p>
      <ol>
        <li>
          Each of the three HSPF models was modified to include the additional
          forest classifications
        </li>
        <li>
          A custom R-script was used take the LANDIS model raster file outputs
          to calculate the fraction of each forest type in each sub-catchment of
          the HSPF models, and then create a text file containing a custom
          schematic table for insertion into each HSPF model input file (.uci
          file)
        </li>
        <li>
          Using the text file generated in step two), an HSPF model file was
          created for each decade of each forest harvest scenario (business as
          usual, 30% harvest increase, 30% harvest decrease, max carbon).
        </li>
        <li>
          Each of the HSPF model files was run over 10 years using the
          historical and future climate input files, a total of nine time steps
          (10 years per step over 2020-2100) for each forest harvest and future
          climate scenario combination.
        </li>
        <li>
          The HSPF simulation results were extracted from the binary output
          files, saved as text files, and summarized using another custom
          R-script.
        </li>
      </ol>
      <p>
        The water quantity and quality outputs from HSPF that were summarized
        for each stream reach and each scenario are given in Table 8. Note that
        these parameters all represent percent changes from historical to
        future. Since the original HSPF models were calibrated at a limited
        number of monitoring stations and there was limited data available on
        the water quantity and quality response to forest changes, we did not
        report flow and water quality parameters as absolute numbers, or
        comparing the model outputs to thresholds (such as water quality
        thresholds).
      </p>

      <table class="table">
        <caption>
          <strong>Table 8.</strong>
          Summary of flow and water quality parameters saved from HSPF for each
          scenario analysis.
        </caption>
        <thead>
          <tr>
            <th>Water Quantity</th>
            <th>Water Quality:</th>
          </tr>
        </thead>
        <tr>
          <td>
            % Change in 2-year flood flow <br />
            % Change in mean annual flow <br />
            % Change in spring high Flow (Q10) <br />
            % Change in summer low flow (Q90)
          </td>
          <td>
            % Change in total suspended sediment concentration <br />
            % Change in total phosphorus concentration <br />
            % Change in total phosphorus nitrogen concentration
          </td>
        </tr>
      </table>
      <h2>Sample Results</h2>
      <p>
        Figures 5, 6, and 7 give sample maps of summarized stream flow (Figures
        5 and 6) and water quality (Figure 7) parameters. For a given
        climate/forest management scenario, there is substantial variability in
        the response over the study area, depending on the original land cover,
        the forest and climate changes, and the stream size. Table 9 summarized
        the range of modeled responses for each variable and each scenario.
      </p>
      <figure class="image">
        <img
          src="../assets/images/water_fig_5.png"
          alt="Map of study watershed with stream reaches color coded to show the change in mean annual flow as described in caption "
        />
        <figcaption>
          <strong>Figure 5.</strong>
          Map of change in mean annual flow from 2020 to 2100 under the d30_high
          scenario.
        </figcaption>
      </figure>

      <figure class="image">
        <img
          src="../assets/images/water_fig_6.png"
          alt="Map of study watershed with stream reaches color coded to show the change in the 2 year flood flow as described in caption "
        />
        <figcaption>
          <strong>Figure 6.</strong>
          Map of change in the 2-year return period flood flow from 2020 to 2100
          under the d30_high scenario.
        </figcaption>
      </figure>

      <figure class="image">
        <img
          src="../assets/images/water_fig_7.png"
          alt="Map of study watershed with stream reaches color coded to show the change in mean annual flow as described in caption "
        />
        <figcaption>
          <strong>Figure 7.</strong>
          Map of change in the mean annual sediment concentration from 2020 to
          2100 under the d30_high scenario.
        </figcaption>
      </figure>

      <table class="table">
        <caption>
          <strong>Table 9.</strong>
          Summary tables of simulated % change in flow and sediment parameters,
          giving the minimum, maximum, and 10th, 25th, 50th, 75th, and 90th
          percentile responses amongst the 367 stream reaches.
        </caption>

        <thead>
          <tr>
            <th class="thtitle" colspan="8">Mean Annual Flow</th>
          </tr>
          <tr>
            <th></th>
            <th>min</th>
            <th>10th</th>
            <th>25th</th>
            <th>50th</th>
            <th>75th</th>
            <th>90th</th>
            <th>max</th>
          </tr>
        </thead>
        <tr>
          <th>s_cc</th>
          <td>-20.82</td>
          <td>-2.38</td>
          <td>-0.59</td>
          <td>0.07</td>
          <td>0.77</td>
          <td>2.02</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>s_high</th>
          <td>-74.65</td>
          <td>-1.50</td>
          <td>-0.33</td>
          <td>0.26</td>
          <td>1.32</td>
          <td>5.54</td>
          <td>39.74</td>
        </tr>
        <tr>
          <th>d30_cc</th>
          <td>-20.82</td>
          <td>-2.98</td>
          <td>-0.74</td>
          <td>0.04</td>
          <td>0.72</td>
          <td>1.87</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>d30_high</th>
          <td>-74.02</td>
          <td>-1.42</td>
          <td>-0.32</td>
          <td>0.26</td>
          <td>1.33</td>
          <td>6.19</td>
          <td>39.74</td>
        </tr>
        <tr>
          <th>i30_cc</th>
          <td>-20.82</td>
          <td>-2.30</td>
          <td>-0.57</td>
          <td>0.09</td>
          <td>0.79</td>
          <td>1.99</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>i30_high</th>
          <td>-74.57</td>
          <td>-1.41</td>
          <td>-0.30</td>
          <td>0.27</td>
          <td>1.35</td>
          <td>6.25</td>
          <td>39.74</td>
        </tr>
        <tr>
          <th>mc_cc</th>
          <td>-20.82</td>
          <td>-3.42</td>
          <td>-0.76</td>
          <td>0.04</td>
          <td>0.71</td>
          <td>1.83</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>mc_high</th>
          <td>-73.25</td>
          <td>-1.44</td>
          <td>-0.32</td>
          <td>0.26</td>
          <td>1.32</td>
          <td>5.73</td>
          <td>39.74</td>
        </tr>
      </table>
      <table class="table">
        <thead>
          <tr>
            <th class="thtitle" colspan="8">Spring q10</th>
          </tr>
          <tr>
            <th></th>
            <th>min</th>
            <th>10th</th>
            <th>25th</th>
            <th>50th</th>
            <th>75th</th>
            <th>90th</th>
            <th>max</th>
          </tr>
        </thead>
        <tr>
          <th>s_cc</th>
          <td>-20.82</td>
          <td>-2.07</td>
          <td>-0.54</td>
          <td>0.08</td>
          <td>0.77</td>
          <td>1.96</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>s_high</th>
          <td>-79.19</td>
          <td>-1.82</td>
          <td>-0.37</td>
          <td>0.22</td>
          <td>1.22</td>
          <td>6.45</td>
          <td>48.17</td>
        </tr>
        <tr>
          <th>d30_cc</th>
          <td>-20.82</td>
          <td>-2.56</td>
          <td>-0.66</td>
          <td>0.05</td>
          <td>0.72</td>
          <td>1.84</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>d30_high</th>
          <td>-74.95</td>
          <td>-1.83</td>
          <td>-0.37</td>
          <td>0.22</td>
          <td>1.21</td>
          <td>6.13</td>
          <td>50.12</td>
        </tr>
        <tr>
          <th>i30_cc</th>
          <td>-25.10</td>
          <td>-1.96</td>
          <td>-0.50</td>
          <td>0.09</td>
          <td>0.79</td>
          <td>1.98</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>i30_high</th>
          <td>-81.16</td>
          <td>-1.83</td>
          <td>-0.37</td>
          <td>0.22</td>
          <td>1.22</td>
          <td>6.59</td>
          <td>50.17</td>
        </tr>
        <tr>
          <th>mc_cc</th>
          <td>-20.82</td>
          <td>-2.80</td>
          <td>-0.69</td>
          <td>0.04</td>
          <td>0.72</td>
          <td>1.84</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>mc_high</th>
          <td>-71.10</td>
          <td>-1.88</td>
          <td>-0.37</td>
          <td>0.22</td>
          <td>1.20</td>
          <td>6.10</td>
          <td>52.70</td>
        </tr>
      </table>
      <table class="table">
        <thead>
          <tr>
            <th class="thtitle" colspan="8">Summer q90</th>
          </tr>
          <tr>
            <th></th>
            <th>min</th>
            <th>10th</th>
            <th>25th</th>
            <th>50th</th>
            <th>75th</th>
            <th>90th</th>
            <th>max</th>
          </tr>
        </thead>
        <tr>
          <th>s_cc</th>
          <td>-20.82</td>
          <td>-2.76</td>
          <td>-0.59</td>
          <td>0.07</td>
          <td>0.77</td>
          <td>2.11</td>
          <td>46.15</td>
        </tr>
        <tr>
          <th>s_high</th>
          <td>-100.00</td>
          <td>-37.60</td>
          <td>-0.91</td>
          <td>0.03</td>
          <td>0.70</td>
          <td>1.83</td>
          <td>40.23</td>
        </tr>
        <tr>
          <th>d30_cc</th>
          <td>-50.30</td>
          <td>-3.56</td>
          <td>-0.67</td>
          <td>0.05</td>
          <td>0.73</td>
          <td>1.91</td>
          <td>63.90</td>
        </tr>
        <tr>
          <th>d30_high</th>
          <td>-100.00</td>
          <td>-36.20</td>
          <td>-0.91</td>
          <td>0.03</td>
          <td>0.70</td>
          <td>1.83</td>
          <td>34.94</td>
        </tr>
        <tr>
          <th>i30_cc</th>
          <td>-34.06</td>
          <td>-2.67</td>
          <td>-0.55</td>
          <td>0.09</td>
          <td>0.80</td>
          <td>2.10</td>
          <td>42.68</td>
        </tr>
        <tr>
          <th>i30_high</th>
          <td>-100.00</td>
          <td>-36.26</td>
          <td>-0.91</td>
          <td>0.03</td>
          <td>0.70</td>
          <td>1.83</td>
          <td>38.42</td>
        </tr>
        <tr>
          <th>mc_cc</th>
          <td>-50.65</td>
          <td>-3.72</td>
          <td>-0.69</td>
          <td>0.05</td>
          <td>0.73</td>
          <td>1.92</td>
          <td>39.86</td>
        </tr>
        <tr>
          <th>mc_high</th>
          <td>-100.00</td>
          <td>-36.36</td>
          <td>-0.91</td>
          <td>0.03</td>
          <td>0.70</td>
          <td>1.83</td>
          <td>29.14</td>
        </tr>
      </table>
      <table class="table">
        <thead>
          <tr>
            <th class="thtitle" colspan="8">2-year flood</th>
          </tr>
          <tr>
            <th></th>
            <th>min</th>
            <th>10th</th>
            <th>25th</th>
            <th>50th</th>
            <th>75th</th>
            <th>90th</th>
            <th>max</th>
          </tr>
        </thead>
        <tr>
          <th>s_cc</th>
          <td>-20.82</td>
          <td>-2.46</td>
          <td>-0.60</td>
          <td>0.07</td>
          <td>0.78</td>
          <td>1.99</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>s_high</th>
          <td>-88.88</td>
          <td>-1.39</td>
          <td>-0.30</td>
          <td>0.28</td>
          <td>1.42</td>
          <td>17.12</td>
          <td>94.76</td>
        </tr>
        <tr>
          <th>d30_cc</th>
          <td>-29.55</td>
          <td>-3.25</td>
          <td>-0.73</td>
          <td>0.04</td>
          <td>0.72</td>
          <td>1.87</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>d30_high</th>
          <td>-88.38</td>
          <td>-1.39</td>
          <td>-0.30</td>
          <td>0.27</td>
          <td>1.40</td>
          <td>18.15</td>
          <td>120.66</td>
        </tr>
        <tr>
          <th>i30_cc</th>
          <td>-45.66</td>
          <td>-2.40</td>
          <td>-0.57</td>
          <td>0.09</td>
          <td>0.81</td>
          <td>2.03</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>i30_high</th>
          <td>-87.64</td>
          <td>-1.38</td>
          <td>-0.30</td>
          <td>0.28</td>
          <td>1.42</td>
          <td>18.13</td>
          <td>84.58</td>
        </tr>
        <tr>
          <th>mc_cc</th>
          <td>-24.66</td>
          <td>-3.61</td>
          <td>-0.74</td>
          <td>0.04</td>
          <td>0.71</td>
          <td>1.84</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>mc_high</th>
          <td>-87.99</td>
          <td>-1.38</td>
          <td>-0.30</td>
          <td>0.27</td>
          <td>1.39</td>
          <td>17.50</td>
          <td>90.24</td>
        </tr>
      </table>
      <table class="table">
        <thead>
          <tr>
            <th class="thtitle" colspan="8">Sediment Concentration</th>
          </tr>
          <tr>
            <th></th>
            <th>min</th>
            <th>10th</th>
            <th>25th</th>
            <th>50th</th>
            <th>75th</th>
            <th>90th</th>
            <th>max</th>
          </tr>
        </thead>
        <tr>
          <th>s_cc</th>
          <td>-20.82</td>
          <td>-1.46</td>
          <td>-0.39</td>
          <td>0.16</td>
          <td>0.86</td>
          <td>2.07</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>s_high</th>
          <td>-20.82</td>
          <td>-1.42</td>
          <td>-0.35</td>
          <td>0.20</td>
          <td>1.03</td>
          <td>3.07</td>
          <td>155.58</td>
        </tr>
        <tr>
          <th>d30_cc</th>
          <td>-25.11</td>
          <td>-1.79</td>
          <td>-0.44</td>
          <td>0.13</td>
          <td>0.84</td>
          <td>2.03</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>d30_high</th>
          <td>-88.01</td>
          <td>-1.51</td>
          <td>-0.33</td>
          <td>0.24</td>
          <td>1.24</td>
          <td>4.60</td>
          <td>268.61</td>
        </tr>
        <tr>
          <th>i30_cc</th>
          <td>-20.82</td>
          <td>-1.47</td>
          <td>-0.38</td>
          <td>0.16</td>
          <td>0.86</td>
          <td>2.04</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>i30_high</th>
          <td>-89.11</td>
          <td>-1.47</td>
          <td>-0.33</td>
          <td>0.25</td>
          <td>1.26</td>
          <td>4.82</td>
          <td>265.79</td>
        </tr>
        <tr>
          <th>mc_cc</th>
          <td>-30.75</td>
          <td>-1.99</td>
          <td>-0.44</td>
          <td>0.14</td>
          <td>0.85</td>
          <td>2.04</td>
          <td>29.14</td>
        </tr>
        <tr>
          <th>mc_high</th>
          <td>-87.67</td>
          <td>-1.49</td>
          <td>-0.33</td>
          <td>0.24</td>
          <td>1.24</td>
          <td>4.72</td>
          <td>299.40</td>
        </tr>
      </table>
      <h2>References</h2>
      <p>
        Bernath, S. C., Verry, E. S., Brooks, K. N., &amp; Ffolliott, P. F.
        (1982). Modeling water yield response to forest cover changes in
        northern Minnesota. In: Proceedings of the Canadian hydrology
        symposium:'82 hydrological processes of forested areas; 1982 June 14-15;
        Fredericton, NB.
      </p>
      <p>
        McEachran, Z. P., Slesak, R. A., &amp; Karwan, D. L. (2018). From skid
        trails to landscapes: vegetation is the dominant factor influencing
        erosion after forest harvest in a low relief glaciated landscape. Forest
        Ecology and Management, 430, 299-311.
      </p>
      <p>
        McEachran, Z. P., Karwan, D. L., &amp; Slesak, R. A. (2021). Direct and
        Indirect Effects of Forest Harvesting on Sediment Yield in Forested
        Watersheds of the United States. JAWRA Journal of the American Water
        Resources Association, 57(1), 1-31.
      </p>
      <p>
        Sebestyen, S. D., Verry, E. S., &amp; Brooks, K. N. (2011). Hydrological
        responses to changes in forest cover on uplands and peatlands. In:
        Peatland biogeochemistry and watershed hydrology at the Marcell
        Experimental Forest, CRC Press.
      </p>
      <p>
        Verry, E. S. (1976). Estimating water yield differences between hardwood
        &amp; pine forests: An application of net precipitation data. Research
        Paper NC-128, US Department of Agriculture, Forest Service, North
        Central Forest Experiment Station.
      </p>
      <p>
        Verry, E. S., Lewis, J. R., &amp; Brooks, K. N. (1983). Aspen
        clearcutting increases snowmelt and storm flow peaks in north central
        Minnesota, Journal of the American Water Resources Association, 19(1),
        59-67.
      </p>
      <p>
        Verry, E. S. (1987). The effect of aspen harvest and growth on water
        yield in Minnesota. In: Forest Hydrology and Watershed
        Management--Proceedings of the Vancouver Symposium; August 1987;
        Vancouver, BC. Wallinford, UK.
      </p>
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  components: {
    Head
  }
}
</script>

<style></style>
