<template>
  <div class="page-background"></div>
  <section class="hero">
    <div class="hero-body container mb-5 p-0">
      <img
        src="../assets/images/MNForests-Stream-unsplash.jpg"
        alt="Stream with rocky banks flowing through a mixed forest"
      />
      <div class="adv_page__intro-text">
        <div class="section__gold-bar-container">
          <div class="section__gold-bar"></div>
        </div>
        <h1 class="is-size-4">
          What will Minnesota's forests look like in 2100?
        </h1>
        <p class="is-size-6">
          Anthropogenic Climate change is driving changes to our forest
          landscapes. We can and do manipulate forest diversity, composition and
          structure through management decisions.
        </p>
      </div>
      <div class="container px-6 pt-5 pb-6">
        <ul class="mb-4">
          <li>What will happen if we can stabilize carbon emissions?</li>
          <li>
            What will happen if we bring in new industries or develop new
            products?
          </li>
          <li>
            What if carbon sequestration and carbon offsets are the products
            themselves?
          </li>
        </ul>
        <p>
          The <strong>ForCAST</strong> tool attempts to provide forestry
          professionals and land managers with an opportunity to evaluate how a
          changing climate and changing markets might affect Minnesota&rsquo;s
          forests and the ecosystem services that they provide at an area or
          regional level.
        </p>
      </div>
    </div>
  </section>
  <section>
    <div class="hero-body container px-6 py-5 mb-6">
      <h2 class="is-size-4">The ForCAST Process</h2>
      <div class="columns has-text-centered is-multiline">
        <div class="column is-one-quarter-desktop is-half-tablet p-3 pb-5">
          <img
            src="../assets/images/forest_map_thumbnail.jpg"
            alt="GIS map of the forest in northeaster Minnesota"
          />
          <p>
            <strong>
              Develop landscape-scale silviculture management and climate
              scenarios.</strong
            >
          </p>
        </div>
        <div class="column is-one-quarter-desktop is-half-tablet p-3 pb-5">
          <img
            src="../assets/images/landis_thumbnail.jpg"
            alt="grid of squares with groups of illustrated 3-d trees"
          />
          <p>
            Simulate future forest composition and structure

            <strong>LANDIS-II Spatiotempororal Modeling</strong>
          </p>
        </div>
        <div class="column is-one-quarter-desktop is-half-tablet p-3 pb-5">
          <img
            src="../assets/images/modeling_thumbnail.jpg"
            alt="screenshot 4 small maps of the watershed filled with different color patterns"
          />
          <p>
            <strong>Model and Evaluate Ecosystem Services</strong>
            from 2020 to 2100 at decadal time steps.
          </p>
        </div>
        <div class="column is-one-quarter-desktop is-half-tablet p-3 pb-5">
          <img
            src="../assets/images/tool_thumbnail.jpg"
            alt="screenshot of the ForCAST tool"
          />
          <p>
            Provide maps and data in an
            <router-link to="/tool"><strong>Online Tool.</strong></router-link>
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="px-5">
    <div class="container">
      <h2 class="is-size-4 mb-3">ForCAST Tool Limitations</h2>
      <p class="mb-3">
        ForCAST cannot provide land managers with definitive answers about what
        a management unit will or will not look like at some point in the
        future.
      </p>
      <p class="mb-3">
        Forest Landscape Modeling cannot be expected to accurately depict
        specific sites or individual stands or how climate change or proposed
        management activities will affect or impact an individual parcel.
      </p>
      <router-link
        to="/tool"
        class="button is-medium is-fullwidth is-primary my-5"
      >
        Use the ForCAST Tool</router-link
      >
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style scoped>
section {
  background-color: #f9f7f6 !important;
}
.hero-body {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  background: white;
}
.adv_page__intro-text {
  background: #fff;
  font-size: 0.85em;
  padding: 2em;
  position: relative;
}
.button {
  text-decoration: none !important;
}
@media screen and (min-width: 700px) {
  .adv_page__intro-text {
    padding: 1.5em 1em 0;
  }
}
/* .adv_page__intro-text {
  padding: 1.25em 1em 0;
} */
@media screen and (min-width: 1000px) {
  .adv_page__intro-text {
    font-size: 0.85em;
    padding: 2em;
    position: absolute;
    left: 70px;
    top: 70px;
    width: 380px;
  }
}
.section__gold-bar-container {
  left: 0;
  position: absolute;
  top: -0.5em;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .section__gold-bar-container {
    margin-top: -0.5em;
  }
  .hero-body {
    margin-top: 0 !important;
  }
}
.section__gold-bar {
  background: #fc3;
  height: 1em;
  margin: 0 auto;
  width: 4em;
}
.page-background {
  background: #7a0019;
  background: linear-gradient(180deg, #5b0013, #7a0019 50%, #900021);
  display: block;
  height: 325px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media screen and (min-width: 1100px) {
  .page-background {
    height: 357px;
  }
}
@media screen and (min-width: 1250px) {
  .page-background {
    height: 409px;
  }
}

@media screen and (min-width: 1300px) {
  .page-background {
    height: 426px;
  }
}
@media screen and (min-width: 1400px) {
  .page-background {
    height: 461px;
  }
}
@media screen and (min-width: 1450px) {
  .page-background {
    height: 475px;
  }
}

ul {
  margin-left: 12px;
}
li {
  margin-bottom: 5px;
  padding-left: 8px;
}
li::marker {
  color: #fc3;
  content: '◼';
}
</style>
