<template>
  <!-- info button -->
  <div class="level-left mb-4">
    <div class="">
      <div class="field">
        <label class="label">{{ scenario.label }}</label>
        <div class="control">
          <!-- scenario dropdown menu -->
          <div class="select is-small mr-2 is-pulled-left">
            <select v-model="selected" @change="onChange">
              <!-- placeholder -->
              <option value="" disabled hidden></option>
              <!-- dynamically render the remaining options -->
              <option
                v-for="option in scenario.options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <a
            class="button is-primary is-small"
            @click="onInfoClick"
            tabindex="0"
          >
            <span class="icon is-small"
              ><fa :icon="['fa', 'info-circle']" size="lg"
            /></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scenario: Object,
    selectedScenario: Object
  },
  emits: ['onScenarioInfoClick', 'onSelectionChange'],
  methods: {
    // when a new selection is made update the value in the scenario store
    onChange() {
      if (this.debug) {
        console.log('onSelectionChange', this.scenario.name, this.selected)
      }
      this.$emit('onSelectionChange', {
        type: this.scenario.name,
        value: this.selected
      })
    },
    // when info button is clicked
    onInfoClick() {
      this.$emit('onScenarioInfoClick', this.scenario.name)
    }
  },
  data() {
    return {
      debug: true,
      selected: this.selectedScenario.value
    }
  }
}
</script>

<style scoped>
.level-left {
  align-self: flex-end;
}
</style>
