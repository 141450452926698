// fontawesome-icons.js
// external file for importing fa icons into main.js
// ref: https://stackoverflow.com/a/69007553/6072959

// fontawesome import
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

// https://fontawesome.com/v5.15/icons?d=gallery&p=2&q=map&s=brands,regular,solid&m=free
import {
  faMapMarkedAlt,
  faCloudSunRain,
  faLayerGroup,
  faChartArea,
  faChartLine,
  faTools,
  faArrowsAltH,
  faTimes,
  faCalendar,
  faTree,
  faInfoCircle,
  faSpinner,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faChevronDown,
  faLink,
  faCopy,
  faSave,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
//import { faAngry } from '@fortawesome/free-regular-svg-icons'
//import { faFacebook } from '@fortawesome/free-brands-svg-icons'

library.add(
  faMapMarkedAlt,
  faCloudSunRain,
  faLayerGroup,
  faChartArea,
  faChartLine,
  faTools,
  faArrowsAltH,
  faTimes,
  faCalendar,
  faTree,
  faInfoCircle,
  faSpinner,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faChevronDown,
  faLink,
  faCopy,
  faSave,
  faDownload
)

export default FontAwesomeIcon
