// ref: https://gka.github.io/chroma.js
// for color
import chroma from 'chroma-js'

// file of general utility functions
const utilities = {
  // TODO: needs source
  round(num, places) {
    return +(Math.round(num + 'e+' + places) + 'e-' + places)
  },

  // round that preserves places
  // src: https://stackoverflow.com/a/32178833/6072959
  roundWithPadding(num, places) {
    return Number(
      Math.round(parseFloat(num + 'e' + places)) + 'e-' + places
    ).toFixed(places)
  },

  // cap number at certain number of significant digits
  // ref: https://stackoverflow.com/questions/36369239/how-can-i-round-to-an-arbitrary-number-of-significant-digits-with-javascript
  significantDigits(num, numDigits) {
    return Number(num.toPrecision(numDigits))
  },

  // range function for JavaScript
  // source: https://www.joshwcomeau.com/snippets/javascript/range/
  // modified to include end value
  range: (start, end, step = 1) => {
    let output = []
    if (typeof end === 'undefined') {
      end = start
      start = 0
    }
    for (let i = start; i <= end; i += step) {
      output.push(i)
    }
    return output
  },

  // does it go up or down?
  whichWay(val1, val2) {
    return val1 > val2 ? 'increase' : 'decrease'
  },

  // calculate percent change
  percentChange(val1, val2) {
    let pct = val2 == 0 ? 0 : (val1 - val2) / val2
    return pct.toLocaleString('en', { style: 'percent' })
  },

  // calculate percent change
  // return absolute value (eg. without the sign)
  percentChangeAbs(val1, val2) {
    let pct = val2 == 0 ? 0 : Math.abs((val1 - val2) / val2)
    return pct.toLocaleString('en', { style: 'percent' })
  },

  // sort an array of objects based on key
  sortArrayOfObjects(data, key) {
    return data.sort((a, b) => a[key] - b[key])
  },

  // returns values for a given year
  valuesForYear(data, year) {
    return data.find(d => d.year == year).values
  },

  // get chart colors from chroma based on scale
  // (usually this is a color brewer scale name)
  getChartColorsFromScale(scaleName) {
    const scale = chroma.scale(scaleName)
    return {
      background: chroma(scale(0.85).hex()).alpha(0.85),
      border: scale(1).hex()
    }
  }
}

export default utilities
