<template>
  <!-- 
    bulma modal 
    ref: https://bulma.io/documentation/components/modal/
  -->
  <div class="modal" :class="{ 'is-active': showModal }">
    <div v-if="showBackground" class="modal-background"></div>
    <div class="modal-card">
      <header v-if="showHeader" class="modal-card-head">
        <slot name="header">
          default header
          <!-- <p class="modal-card-title">Modal title</p>
        <button class="delete" aria-label="close"></button> -->
        </slot>
      </header>
      <section class="modal-card-body">
        <slot name="body"> default body </slot>
      </section>
      <footer v-if="showFooter" class="modal-card-foot">
        <label class="checkbox mr-3">
          <input type="checkbox" v-model="checked" class="mr-1" />
          <slot name="checkbox"></slot>
        </label>

        <button
          class="button is-primary"
          :disabled="!checked"
          @click="termButtonClick"
        >
          <slot name="button"></slot>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initShowModal: {
      type: Boolean,
      required: true
    },
    showFooter: {
      type: Boolean,
      required: false
    },
    showHeader: {
      type: Boolean,
      required: false
    },
    showBackground: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      showModal: this.initShowModal,
      checked: false
    }
  },
  methods: {
    // when the agree to term button gets clicked
    termButtonClick() {
      // close the modal
      this.showModal = false
      this.$parent.$emit('agreedToTerms')
    }
  }
}
</script>

<style></style>
