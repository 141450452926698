<template>
  <Head>
    <title>Contact ForCAST</title>
    <meta name="description" content="" />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">Contact</h1>
      <h2>Questions or Feature Requests</h2>
      <p>
        If you have questions or suggestions for the tool, please send us an
        email at
        <a href="mailto:nrri-gis@d.umn.edu?subject=ForCAST website feedback"
          >nrri-gis@d.umn.edu</a
        >
      </p>
      <h2>Submit a bug report</h2>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeiNk7lXy1y2jT0yre9_6ti-MbudMvjKfjFFgL08f-bMGZ0cg/viewform?embedded=true"
        width="640"
        height="1199"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Loading…</iframe
      >
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  components: {
    Head
  }
}
</script>

<style></style>
