<template>
  <l-map
    ref="themap"
    :zoom="zoom"
    :center="center"
    :max-bounds="maxBounds"
    :max-zoom="maxZoom"
    :min-zoom="minZoom"
    :options="{ beforeMapMount, useGlobalLeaflet: true, tap: false }"
    :use-global-leaflet="true"
    @ready="mapReady"
    @beforeMapMount="beforeMapMount"
    @viewreset="mapViewreset"
    @update:center="mapUpdateCenter"
  >
    <!-- foropt extent mask (WMS) -->
    <l-wms-tile-layer
      :base-url="geoserverURL"
      :name="foroptExtent.name"
      :version="foroptExtent.version"
      :format="foroptExtent.format"
      :transparent="foroptExtent.transparent"
      :layers="foroptExtent.layers"
      :z-index="foroptExtent.zIndex"
    />

    <!-- scale bar -->
    <l-control-scale
      position="bottomleft"
      :imperial="true"
      :metric="true"
    ></l-control-scale>
    <!-- subcatchments and tb layers -->
    <l-layer-group ref="geojsonLayerGroup" name="geojsonLayerGroup">
      <l-geo-json
        v-for="layer in geojsonLayers"
        :key="layer.name"
        :name="layer.name"
        :geojson="layer.geojson"
        :options="layer.options"
        ref="geojsonLayer"
      />
    </l-layer-group>

    <!-- basemap layers -->
    <l-layer-group layer-type="base">
      <l-tile-layer
        :url="basemap.url"
        layer-type="base"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>contributors'
        :name="basemap.name"
      ></l-tile-layer>
    </l-layer-group>
    <!-- loading spinner -->
    <span id="mapSpinner" class="icon-text" v-if="showSpinner">
      <span class="icon"> <fa :icon="['fa', 'spinner']" spin size="4x" /></span>
      <span class="text-icon">{{ spinnerMessage }}</span>
    </span>
  </l-map>
  <!-- side panel showing AOI info and selection buttons -->
  <div class="panel-wrapper">
    <div class="aoi-panel-wrapper">
      <aside class="panel slide-panel">
        <keep-alive>
          <aoi-selection-pane
            :layerData="layerData"
            :aoiCreated="aoiBounds ? true : false"
            :layersReady="layersReady"
            :aoiInStorage="isAoiSelected"
            @draw-button-clicked="onDrawButtonClicked"
            @geoman-button-clicked="onGeomanButtonClicked"
            @finish-button-clicked="onFinishButtonClicked"
            @save-button-clicked="onSaveButtonClicked"
            @cancel-button-clicked="$emit('closeAOI', 'aoi')"
          >
          </aoi-selection-pane>
        </keep-alive>
      </aside>
    </div>
  </div>
</template>

<script>
// leaflet CSS import
import 'leaflet/dist/leaflet.css'
// vue-leaflet imports
import {
  LMap,
  LTileLayer,
  LLayerGroup,
  LControlScale,
  LGeoJson,
  LWmsTileLayer
} from '@vue-leaflet/vue-leaflet/src/components'

import AoiSelectionPane from './AoiSelectionPane.vue'

// load mapData
import mapData from '@/data/mapData.js'

// load app state
import theStore from '../store/store.js'

// import layer styling funtionality
import { aoiStyles } from '@/modules/layerSymbology.js'

export default {
  name: 'AoiMap',
  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    LControlScale,
    LGeoJson,
    LWmsTileLayer,
    AoiSelectionPane
  },
  props: {
    aoiProp: Object
  },
  emits: ['closeAOI'],
  data() {
    return {
      debug: true,
      // map data
      // DEBUG: should be 8 in production
      zoom: 9,
      // zoom: 10,
      center: [47.1451155, -92.65666115],
      // should keep the map centered on the study area
      maxBounds: [
        [45.436, -95.771],
        [49.049, -89.306]
      ],
      maxZoom: 13,
      minZoom: 9,

      // geojson data loaded from geoserver
      geojsonLayers: [],

      // hidden (default) geojson style
      defaultGeojsonStyle: {
        stroke: false,
        fill: false
      },

      // visible geojson style
      visibleGeojsonStyle: {
        stroke: true,
        // color specified by layer
        weight: 1,
        fill: false
      },

      // map layer data for side panel
      layerData: {
        subcatchments: mapData.aoiMapLayers.find(
          lyr => lyr.value == 'subcatchments'
        ),
        tb: mapData.aoiMapLayers.find(lyr => lyr.value == 'tb')
      },

      // drawing data
      aoiBounds: null,
      aoiLayer: null,

      // map loading listeners for loading spinner
      isMapReady: false,
      layersReady: false,
      mapCentered: true
    }
  },

  computed: {
    themap() {
      return this.$refs.themap.leafletObject
    },
    basemap() {
      return theStore.store.state.basemap
    },
    foroptExtent() {
      return mapData.foroptExtent
    },
    geoserverURL() {
      return mapData.geoserverURL
    },
    isAoiSelected() {
      return theStore.store.isAoiSelected()
    },

    spinnerMessage() {
      return !this.layersReady ? 'Gathering Layer Data....' : 'Map Loading....'
    },

    // loading spinner - show spinner until:
    //   - map is ready
    //   - map has finished updating center
    //   - number of geojson layers ready equals number of geojson layers
    showSpinner() {
      this.log(
        'show spinner',
        this.isMapReady,
        this.mapCentered,
        this.layersReady
      )
      return !this.isMapReady || !this.mapCentered || !this.layersReady
    },

    // correct the ordering of AOI bounds by converting bounds to geojson
    // and then converting geojson coordinates back to leaflet latlngs
    aoiCorrectedBounds() {
      const geojsonFromBounds = window.L.rectangle(this.aoiBounds).toGeoJSON()
      return window.L.GeoJSON.coordsToLatLngs(
        geojsonFromBounds.geometry.coordinates,
        1
      )
    },

    // in order to restore aoi from prop the last point need to be removed
    // since it was stored in geojson format
    aoiReturnToRect() {
      let inner = this.aoiProp[0]
      return [inner.slice(0, -1)]
    }
  },

  async mounted() {
    this.log('mounted')
    // load geojson data from geoserver
    await this.setupGeojsonLayers()
    this.layersReady = true

    // if loading stored data (eg. from url params) then rehydrate
    // a rectangle from the bounds to be used for the drawing shape
    if (this.aoiProp && !this.aoiBounds) {
      this.rehydrateRectFromAOIProps()
    }
  },

  watch: {
    // watch for changes to AOI
    // this should initiate (re)drawing of geojson layers
    aoiBounds(bounds) {
      this.selectGeojson(bounds)
    }
  },

  methods: {
    // shorthand for console logging
    log(...message) {
      if (this.debug) {
        console.log('[aoi]', ...message)
      }
    },
    // handler for when save button is clicked on side panel
    // save area of interest to store
    onSaveButtonClicked() {
      // store AOI bounds using correct coordinate ordering
      theStore.store.updateAction('aoi', this.aoiCorrectedBounds)
      // DEBUG
      // convert bounds to geojson to see how the latlngs are ordered
      this.log(
        'aoi bounds',
        JSON.stringify(this.aoiBounds),
        'aoi bounds geojson',
        JSON.stringify(this.aoiCorrectedBounds)
      )
      // store subcatchment IDs
      theStore.store.updateAction(
        'subcatchments',
        this.layerData.subcatchments.IDs
      )
      // store township block IDs
      theStore.store.updateAction('townshipBlocks', this.layerData.tb.IDs)
      //if Scenario is not already selected, open the Scenario Selection page
      if (!theStore.store.isScenarioSelected()) {
        this.$emit('closeAOI', 'scenario')
      } else {
        // just close out AOI map panel
        this.$emit('closeAOI', 'aoi')
      }
    },

    // handler for draw button on side panel is clicked
    // enable draw
    onDrawButtonClicked() {
      // customize the tooltip text
      // ref: https://github.com/geoman-io/leaflet-geoman#customize
      const customTranslation = {
        tooltips: {
          firstVertex: 'Click to start drawing'
        }
      }

      this.themap.pm.setLang('customName', customTranslation, 'en')

      // set up global options
      this.themap.pm.setGlobalOptions({
        pathOptions: aoiStyles.draw,
        snappable: false,
        allowSelfIntersection: false
      })

      this.themap.pm.enableDraw('Rectangle')
      this.log('draw button clicked, enabling draw')
    },

    // handler for when geoman buttons on side panel is clicked
    // handle based on mode clicked
    onGeomanButtonClicked(mode) {
      // do nothing if AOI hasn't already been drawn
      // TODO: should probably have some user feedback to indicate this
      if (!this.aoiLayer) {
        return false
      }

      // handle buttons differently based on mode
      if (mode == 'edit') {
        this.aoiLayer.pm.enable()
        this.log('edit button clicked, enabling edit')
      } else if (mode == 'move') {
        this.aoiLayer.pm.enableLayerDrag()
        this.log('move button clicked, enabling move')
      } else if (mode == 'erase') {
        this.aoiRemove()
      }
    },

    // handler for finish task button click
    // disable tool for selected mode
    onFinishButtonClicked(mode) {
      // handle buttons differently based on mode
      if (mode == 'edit') {
        this.aoiLayer.pm.disable()
        this.log('edit button clicked, disabling edit')
      } else if (mode == 'move') {
        this.aoiLayer.pm.disableLayerDrag()
        this.log('move button clicked, disabling move')
      }
    },

    // load draw toolbar
    // ref: https://github.com/vue-leaflet/Vue2Leaflet/issues/331#issuecomment-462324766
    loadGeoman() {
      // set geoman optin
      window.L.PM.setOptIn(true)
      // get reference to map
      const map = this.$refs.themap.leafletObject

      // draw create listener
      map.on('pm:create', e => this.onDrawCreate(e))

      // add listener for when drawing starts
      // ref: https://github.com/geoman-io/leaflet-geoman#draw-mode
      map.on('pm:drawstart', this.onDrawStart)
    },

    // handler for when the area of interest is first created
    onDrawCreate(event, aoiLayerFromProp = null) {
      // use the aoi layer created from prop if it exists
      this.aoiLayer = aoiLayerFromProp ? aoiLayerFromProp : event.layer
      // newly drawn layers are ignored
      // ref: https://github.com/geoman-io/leaflet-geoman#opt-in
      this.aoiLayer.setStyle({ pmIgnore: false })
      window.L.PM.reInitLayer(this.aoiLayer)

      // attach listeners for modifying the AOI
      this.aoiLayer.on('pm:markerdrag', this.aoiShapeChangeHandler)
      this.aoiLayer.on('pm:drag', this.aoiShapeChangeHandler)

      // set bounds to layer's bounds
      // for some reason, this gives different results than when capturing
      // the bounds while actively drawing
      this.log('onDrawCreate', JSON.stringify(this.aoiLayer.getLatLngs()))
      this.aoiBounds = this.aoiLayer.getLatLngs()
    },

    // handler for the aoi getting removed
    // reset to empty bounds, reset layerData
    aoiRemove() {
      // disable layer
      this.aoiLayer.pm.remove()
      this.aoiBounds = null
      // reset layerData
      for (const lyr in this.layerData) {
        this.layerData[lyr].count = null
        this.layerData[lyr].area = null
        this.layerData[lyr].IDs = null
      }
    },

    // handler for the aoi shape changing
    // update the aoi bounds so that the geojson layers are updated as well
    aoiShapeChangeHandler() {
      this.aoiBounds = this.aoiLayer.getLatLngs()
    },

    // handler for when the area of interest is actively being drawn
    // should update AOI bounds
    onAOIDraw() {
      const workingAOI = this.themap.pm.Draw.Rectangle
      this.aoiBounds = workingAOI._layer.getLatLngs()
      // this.log('onAOIDraw', JSON.stringify(this.aoiBounds))
    },

    // draw start handler
    // setup aoiShapeChangeHandler handler by listening to rectangle hintMarker 'move'
    // which, in turn, is attached to the map's 'mousemove' event
    onDrawStart() {
      // listener for geoman drawing rectangle hint marker 'move' event
      // based on suggestions from this issue I posted in geoman-io/leaflet-geoman
      // https://github.com/geoman-io/leaflet-geoman/issues/1072
      this.themap.pm.Draw.Rectangle._hintMarker.on(
        'move',
        function () {
          // only update once rectangle is starting to draw
          if (
            window.L.DomUtil.hasClass(
              this.themap.pm.Draw.Rectangle._startMarker._icon,
              'visible'
            )
          ) {
            this.onAOIDraw()
          }
        },
        this
      )
    },

    // selects (makes visible) geojson layers if they overlap with aoi
    // in spatial relationship terms the overlap is between the aoi
    // and the bounding box of the geojson feature (and not the feature itself)
    selectGeojson(aoiBounds) {
      // create leaflet latlng bounds first
      // (enables use of .overlap() )
      const bounds = new window.L.latLngBounds(aoiBounds)
      let geojsonLayerGroup = this.$refs.geojsonLayerGroup.leafletObject
      // loop over geojson leaflet layers (via layerGroup)
      geojsonLayerGroup.eachLayer(geojsonLayer => {
        // check if aoi exists
        // reset style for entire layer if not
        if (!this.aoiBounds) {
          geojsonLayer.resetStyle()
          return false
        }
        // loop over the features of the geojson layer
        geojsonLayer.eachLayer(lyr => {
          let lyrBounds = lyr.getBounds()
          // check if aoi overlaps feature
          // if it does highlight it
          if (bounds.overlaps(lyrBounds)) {
            // get selected style function from layer config
            const selectedStyle = this.geojsonLayers.find(
              lyr => geojsonLayer.options.name == lyr.name
            ).selectedStyle
            // this.log(selectedStyle)
            // console.log('overlap')
            lyr.setStyle(selectedStyle())
            lyr.highlighted = true
          }
          // if it doesn't reset style
          else {
            // console.log('no overlap')
            geojsonLayer.resetStyle(lyr)
            lyr.highlighted = false
          }
        })
      })
      // after selection is made update the data for the side panel
      this.updateSelectedData()
    },

    // update count and area of each geojson layer for display in side panel
    updateSelectedData() {
      let geojsonLayerGroup = this.$refs.geojsonLayerGroup.leafletObject
      // loop over geojson leaflet layers (via layerGroup)
      geojsonLayerGroup.eachLayer(geojsonLayer => {
        let count = 0
        let area = 0
        let IDs = []
        // loop over the features of the geojson layer
        geojsonLayer.eachLayer(lyr => {
          // if feature is selected (highlighted)
          // update the total count, total area, and IDs for that layer
          if (lyr.highlighted) {
            count++
            // use sqkm attribute of Subcatchments
            // or assume 9 square miles for townships blocks
            area +=
              lyr.options.name == 'subcatchments'
                ? Math.round(lyr.feature.properties.area_km2 * 0.3861002)
                : 9
            let idField = lyr.options.name == 'subcatchments' ? 'catid' : 'tb'
            IDs.push(lyr.feature.properties[idField])
          }
        })
        // update data for layer
        this.layerData[geojsonLayer.options.name].count = count
        this.layerData[geojsonLayer.options.name].area = area
        this.layerData[geojsonLayer.options.name].IDs = IDs
      })
    },

    // get geojson data from geoserver to pass to l-geojsons
    async setupGeojsonLayers() {
      // loop over aoi map layers
      for (let layer of mapData.aoiMapLayers) {
        const url = this.generateWFSURL(layer.value)
        // get geojson from geoserver
        const response = await fetch(url)
        // set leaflet geojson value from geoserver response
        const geojson = await response.json()
        // add geojson data to layer
        this.geojsonLayers.push({
          geojson: geojson,
          name: layer.value,
          options: {
            style: this.defaultGeojsonStyle
          },
          // add separate style function that will be used when it overlaps with aoi
          selectedStyle: () => {
            let style = this.visibleGeojsonStyle
            style.color = layer.color
            return style
          }
        })
        this.log(`${layer.name} data loaded`)
      }
    },

    // generate URL to get geojson data back from geoserver
    generateWFSURL(layer) {
      const options = new URLSearchParams({
        service: 'WFS',
        version: '2.0.0',
        request: 'GetFeature',
        typeName: `foropt:${layer}`,
        srsName: 'EPSG:4326',
        outputFormat: 'application/json'
      })
      return this.geoserverURL + options
    },

    // map ready handler
    // initialize geoman at this point
    async mapReady() {
      this.log('map ready')
      this.isMapReady = true
      this.loadGeoman()
    },

    // if loading stored data (eg. from url params) then rehydrate
    // a rectangle from the bounds to be used for the drawing shape
    rehydrateRectFromAOIProps() {
      let rectOptions = aoiStyles.draw
      rectOptions.pmIgnore = false
      let rectFromProp = window.L.rectangle(this.aoiProp, rectOptions).addTo(
        this.themap
      )
      this.onDrawCreate(null, rectFromProp)
    },

    // before map mount handler is a good time to load geoman resources
    async beforeMapMount() {
      this.log('beforeMapMount')
      // load geoman (leaflet shape drawing) dependencies
      // the reason to wait until now is that leaflet is available
      // see https://github.com/vue-leaflet/vue-leaflet/issues/48
      await import('@geoman-io/leaflet-geoman-free')
      await import('@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css')
    },

    mapViewreset(event) {
      this.log('viewreset', event)
      this.mapCentered = false
    },
    mapUpdateCenter(event) {
      this.log('UpdateCenter', event)
      this.mapCentered = true
    }
  }
}
</script>

<style>
/* styles for map spinner */
#mapSpinner {
  z-index: 1000;
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 250px;
  margin-left: -125px;
}
#mapSpinner .icon {
  height: auto;
  width: auto;
  padding-bottom: 10px;
}

#mapSpinner .progress {
  height: 1.5rem;
}
/* text below spinner */
#mapSpinner .text-icon {
  font-size: 1.3333em;
  line-height: 0.625em;
  text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff,
    2px 2px 0 #fff, 0 2px 0 #fff, -2px 2px 0 #fff, -2px 0 0 #fff;
  font-weight: 600;
}

/* side panel styles */
.panel-wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}
.aoi-panel-wrapper {
  position: relative;
  float: right;
  z-index: 1100;
  height: 100%;
  margin-bottom: 0 !important;
  overflow-y: auto;
}
.slide-panel {
  background-color: white;
  width: 350px;
}
</style>
<style scoped>
@media screen and (max-width: 640px) {
  /* make aoi selection tools to be managable on narrow screens */
  .panel-wrapper {
    position: relative;
    margin-right: -80px;
  }
  .aoi-panel-wrapper {
    float: none;
  }
  .slide-panel {
    width: 100%;
  }
}
</style>
