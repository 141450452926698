<template>
  <Head>
    <title>ForCAST: Forest Change Assessment Simulation Tool</title>
    <meta name="description" content="" />
  </Head>
  <div class="columns is-fullheight-percent is-mobile mr-0 my-0 ml-0">
    <div class="column flex-wrapper">
      <div class="mapWrapper">
        <!-- slide out side panel -->
        <transition name="slide">
          <slide-panel
            v-if="showSlidePanel"
            :scenario="scenario"
            :show="showPanel"
            @closePanelClick="toggleSlidePanel($event)"
          ></slide-panel>
        </transition>
        <!-- area of interest map -->
        <keep-alive>
          <aoi-map
            v-if="showPanel == 'aoi'"
            :aoi-prop="aoiBounds"
            @closeAOI="toggleSlidePanel($event)"
          ></aoi-map>
        </keep-alive>
        <!-- main map -->
        <fo-map
          v-if="showPanel != 'aoi'"
          :panel="showPanel"
          @layer-change="onLayerChange"
        ></fo-map>
      </div>
      <!-- bread crumb bar indicates current scenario selection -->
      <bread-crumb
        :scenario="scenario"
        @openSidePanel="toggleOpenSlidePanel($event)"
      ></bread-crumb>
    </div>
    <!-- fixed width sidebar in Bulma -->

    <div
      class="column is-narrow pl-0 pr-0 pt-0 has-text-centered has-background-dark has-text-white is-relative"
    >
      <side-menu
        :showPanel="showPanel"
        @side-menu-click="toggleSlidePanel($event)"
      ></side-menu>
    </div>
  </div>

  <!-- agree to terms modal -->
  <div v-if="!agreesToTerms && DEBUG.agreeToTermsModal">
    <modal-w-disable-button
      init-show-modal
      show-header
      show-footer
      show-background
    >
      <template #header>
        <h1 class="modal-card-title">Welcome to ForCAST</h1>
      </template>

      <template #body>
        <p class="mb-3"><strong>Dislaimer:</strong></p>
        <ul>
          <li class="mb-3">
            The ForCAST tool was designed to provide land managers with a view
            to how a changing climate and changing markets for forest products
            may impact forests, wildlife habitat, and water quantity.
          </li>
          <li>
            While this tool may be useful for thinking about how a particular
            parcel fits into the landscape it is not meant to support decisions
            at small spatial or temporal scales.
          </li>
        </ul>
      </template>

      <template #button>Use the tool</template>
      <template #checkbox>I have read the disclaimer</template>
    </modal-w-disable-button>
  </div>
  <!-- vue3-tour component -->
  <v-tour name="toolTour" :steps="tourSteps" :options="tourOptions"></v-tour>
</template>

<script>
// components on this page
import FoMap from '../components/Map.vue'
import AoiMap from '../components/AoiMap.vue'
import SideMenu from '../components/SideMenu.vue'
import SlidePanel from '../components/SlidePanel.vue'
import BreadCrumb from '../components/BreadCrumb.vue'
import ModalWDisableButton from '../components/ModalWDisableButton.vue'
import { Head } from '@vueuse/head'

// data configurations
import mapData from '@/data/mapData.js'
import scenarioData from '@/data/scenarioData.js'

// app state
import theStore from '../store/store.js'

// import tour config
import tourConfig from '../data/tourData.js'
// require('@/assets/styles/vuetour.css')

export default {
  name: 'Tool',
  components: {
    ModalWDisableButton,
    FoMap,
    AoiMap,
    SideMenu,
    SlidePanel,
    BreadCrumb,
    Head
  },
  computed: {
    DEBUG: function () {
      return this.$DEBUG
    },

    // store stuff
    scenario() {
      return theStore.store.getScenarioAndLayer()
    },
    aoiBounds() {
      return theStore.store.getAOI().aoi
    },

    // tour config
    tourOptions() {
      return tourConfig.options
    },
    tourSteps() {
      return tourConfig.steps
    }
  },
  data() {
    return {
      // show <slide-panel> ?
      showSlidePanel: false,

      // show which panel on slide panel
      //DEBUG
      showPanel: null,

      // has a layer been selected yet?
      layerSelected: false
    }
  },
  emits: ['agreedToTerms'],
  props: {
    agreesToTerms: Boolean,
    // for loading tool from generated URL
    params: {
      type: String,
      required: false
    },
    showTour: Boolean
  },
  watch: {
    showTour: function (showTour) {
      console.log('watch show tour', showTour, this.params)
      // don't show tour if opening with params
      if (showTour && !this.params) {
        this.startTour()
      }
    }
  },
  methods: {
    toggleSlidePanel(item) {
      // if aoi ("Map Area") button on side panel is clicked
      // just open the AOI map and don't open the slide panel
      if (item != 'aoi') {
        /*
        toggle slide panel open/closed
        if panel is open,
          close it unless a different menu item is being selected
        if panel is closed,
          open it with that menu item
      */
        if (this.showSlidePanel) {
          this.showSlidePanel = this.showPanel == item ? false : true
        } else {
          this.showSlidePanel = true
        }
      }
      if (item == 'aoi') {
        this.showSlidePanel = false
      }
      // which panel should be shown
      // this.showPanel = item
      // set which panel should be shown, unless it's already showing that panel
      this.showPanel = this.showPanel == item ? null : item
    },
    toggleOpenSlidePanel(item) {
      this.showSlidePanel = true
      this.showPanel = item
    },

    // restore state for when the tool is loaded using a generated URL
    // with query parameters
    setState() {
      const notJustValue = [
        'aoi',
        'subcatchments',
        'townshipBlocks',
        'layerOpacity'
      ]

      // convert params back to objects
      const state = '' || JSON.parse(this.params)

      if (state) {
        // loop over params and update them in the store
        for (const key in state) {
          // if it's configured data then use the value to look it up
          // and restore all the data
          // otherwise just use it as is
          if (notJustValue.includes(key)) {
            theStore.store.updateAction(key, state[key])
          } else {
            // first check in scenarioData
            if (key in scenarioData) {
              let data = scenarioData[key].find(
                x => x.value == state[key].value
              )
              theStore.store.updateAction(key, data)
            }
            // then check in mapData
            else if (key in mapData) {
              let data = mapData[key].find(x => x.value == state[key].value)
              theStore.store.updateAction(key, data)
            }
            // layerCat and layer
            else if (key == 'layerCat') {
              let layerCat = scenarioData.components.find(
                x => x.value == state[key].value
              )
              // separate layers from rest of layerCat
              const { layers, ...everythingElse } = layerCat
              // store layerCat
              theStore.store.updateAction(key, everythingElse)

              // get layer data
              // carbon stores "biomass" as its value so handle differently
              let layer
              if (layerCat.value == 'c') {
                layer = layerCat.layers[0]
              } else {
                layer = layers.find(x => x.value == state.layer.value)
              }
              // store layer
              theStore.store.updateAction('layer', layer)
            }
          }
        }
      }
    },

    // layer change handler for map displaying a new layer
    onLayerChange() {
      this.layerSelected = true
    },

    startTour() {
      this.$tours['toolTour'].start()
    },
    stopTour() {
      this.$tours['toolTour'].stop()
    }
  },
  // beforeCreate() {
  //   console.log('beforeCreate: store:', theStore)
  // },
  created() {
    console.log('created: params', this.params)

    // set which panel should be shown
    this.showPanel = 'aoi'
    // check for url param props
    if (this.params) {
      // if using params, then go straight to map
      this.showPanel = null
      this.setState()
    }
  },
  // beforeMount() {
  //   console.log('beforeMount: store:', theStore)
  // },
  mounted() {
    // console.log('mounted: mapParams', this.mapParams)
    //   console.log('mounted: store:', theStore)
    // start tour should really happen after disclosure is clicked.
    // this.startTour()
  }
}
</script>

<style scoped>
.modal-card-title {
  font-weight: 600;
}
ul {
  list-style-type: disc;
}
li {
  margin-left: 20px;
}
.flex-wrapper {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  flex-shrink: 0;
}
.mapWrapper {
  height: 100%;
  position: relative;
  z-index: 15;
}
l-map {
  height: 100%;
  width: 100%;
}

/*
  before the element is shown, start off the screen to the right
  ref: https://css-snippets.com/slide-in-panel/
*/
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  right: -100%;
}

.icon-text .icon:not(:last-child) {
  margin-right: 0 !important;
}
</style>
