// vue-router imports
import { createRouter, createWebHistory } from 'vue-router'
// local views
import Home from '../views/Home.vue'
import Project from '../views/Project.vue'
import People from '../views/People.vue'
import Funding from '../views/Funding.vue'
import Contact from '../views/Contact.vue'
import Landscape from '../views/Landscape-modeling.vue'
import Wildlife from '../views/Wildlife-modeling.vue'
import Water from '../views/Water-modeling.vue'
import Ecosystem from '../views/Ecosystem-valuation.vue'
import Help from '../views/Help.vue'
import Tool from '../views/Tool.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/project-overview',
    name: 'Project',
    component: Project
  },
  {
    path: '/people',
    name: 'People',
    component: People
  },
  {
    path: '/funding',
    name: 'Funding',
    component: Funding
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/landscape-modeling',
    name: 'Landscape',
    component: Landscape
  },
  {
    path: '/wildlife-modeling',
    name: 'Wildlife',
    component: Wildlife
  },
  {
    path: '/water-modeling',
    name: 'Water',
    component: Water
  },
  {
    path: '/ecosystem-valuation',
    name: 'Ecosystem',
    component: Ecosystem
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/tool',
    name: 'Tool',
    component: Tool,
    // map params to props
    props: route => ({
      params: route.query.p
    })
  }
]

// specify base property for web history
// should match vue.config.js publicPath
// ref: https://next.router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
const history = createWebHistory()
// we don't need this anymore since we are serving direct from domain mnforcast.org
// process.env.NODE_ENV === 'production'
//   ? createWebHistory('/for-opt/')
//   : createWebHistory()

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash
      }
    } else {
      return { top: 0 }
    }
  },
  history: history,
  routes
})

export default router
