<template>
  <aside class="menu is-fullheight">
    <!-- solid style -->
    <ul class="menu-list is-size-3">
      <li>
        <a
          class="icon-text has-text-white"
          :class="{ current: showPanel == 'aoi' }"
          tabindex="0"
          @click="sideMenuClick('aoi')"
          @keyup.enter="sideMenuClick('aoi')"
        >
          <span class="icon">
            <fa :icon="['fa', 'map-marked-alt']" />
          </span>
          <span class="text-icon is-size-7">Map Area</span>
        </a>
      </li>
      <li>
        <a
          class="icon-text has-text-white"
          :class="{ current: showPanel == 'scenario' }"
          tabindex="0"
          @click="sideMenuClick('scenario')"
          @keyup.enter="sideMenuClick('scenario')"
          :disabled="!aoiSelected()"
        >
          <span class="icon">
            <fa :icon="['fa', 'cloud-sun-rain']" />
          </span>
          <span class="text-icon is-size-7">Scenario Selection</span>
        </a>
      </li>
      <li>
        <a
          class="icon-text has-text-white"
          :class="{ current: showPanel == 'layers' }"
          tabindex="0"
          @click="sideMenuClick('layers')"
          @keyup.enter="sideMenuClick('layers')"
          :disabled="!scenarioSelected()"
          ><span class="icon"><fa :icon="['fa', 'layer-group']" /></span
          ><span class="text-icon is-size-7"
            >Model<br />
            Output</span
          ></a
        >
      </li>
      <li>
        <a
          class="icon-text has-text-white"
          :class="{ current: showPanel == 'charts' }"
          tabindex="0"
          @click="sideMenuClick('charts')"
          @keyup.enter="sideMenuClick('charts')"
          :disabled="!layerSelected()"
          ><span class="icon"><fa :icon="['fa', 'chart-line']" /></span
          ><span class="text-icon is-size-7">Charts</span></a
        >
      </li>
      <!-- <li>
        <a
          class="icon-text has-text-white"
          :class="{ current: showPanel == 'reports' }"
          tabindex="0"
          @click="sideMenuClick('reports')"
          @keyup.enter="sideMenuClick('reports')"
          ><span class="icon"><fa :icon="['fa', 'chart-area']" /></span
          ><span class="text-icon is-size-7">Data &amp; Reports</span></a
        >
      </li> -->
      <li>
        <a
          class="icon-text has-text-white"
          :class="{ current: showPanel == 'tools' }"
          tabindex="0"
          @click="sideMenuClick('tools')"
          @keyup.enter="sideMenuClick('tools')"
          ><span class="icon"><fa :icon="['fa', 'tools']" /></span
          ><span class="text-icon is-size-7">Tools</span></a
        >
      </li>
      <!-- <li>
        <a class="icon-text has-text-white" tabindex="0"
          ><span class="icon"><fa :icon="['fa', 'arrows-alt-h']" /></span
          ><span class="text-icon is-size-7">Split Screen</span></a
        >
      </li> -->
    </ul>
  </aside>
  <!-- <button
    type="button"
    class="button reset is-small mt-4 is-grey-dark"
    tabindex="0"
  >
    Reset
  </button> -->
  <!-- scenario notification -->
  <div
    id="scenarioNotification"
    class="notification is-warning is-size-7"
    v-if="showScenarioWarning"
  >
    <button class="delete" @click="showScenarioWarning = false"></button>
    You can't access the Scenario Selections until a Map Area has been selected.
  </div>
  <!-- layer notification -->
  <div
    id="layerNotification"
    class="notification is-warning is-size-7"
    v-if="showLayerWarning"
  >
    <button class="delete" @click="showLayerWarning = false"></button>
    You can't access the Map Layers until a Scenario has been selected.
  </div>

  <!-- chart notification -->
  <div
    id="chartNotification"
    class="notification is-warning is-size-7"
    v-if="showChartWarning"
  >
    <button class="delete" @click="showChartWarning = false"></button>
    You can't access the Charts until a Map Layer has been selected.
  </div>
</template>

<script>
// app state
import theStore from '../store/store.js'
export default {
  props: {
    // sets which button is active
    showPanel: String
  },
  data() {
    return {
      showChartWarning: false,
      showLayerWarning: false,
      showScenarioWarning: false
    }
  },
  methods: {
    aoiSelected() {
      return theStore.store.isAoiSelected()
    },
    scenarioSelected() {
      return theStore.store.isScenarioSelected()
    },
    layerSelected() {
      return theStore.store.isLayerSelected()
    },
    // triggers when menu is clicked
    sideMenuClick(item) {
      // check if attempting to access charts without a layer selected
      if (item == 'charts' && !this.layerSelected()) {
        this.showChartWarning = true
        return false
      }
      // check if attempting to access layers without a scenario selected
      if (item == 'layers' && !this.scenarioSelected()) {
        this.showLayerWarning = true
        return false
      }
      // check if attempting to scenario without an aoi selected
      if (item == 'scenario' && !this.aoiSelected()) {
        this.showScenarioWarning = true
        return false
      }
      // emit click up to parent
      this.$emit('sideMenuClick', item)
    }
  },
  emits: ['sideMenuClick']
}
</script>

<style lang="scss" scoped>
aside.menu {
  border-top: solid 1px #4d0a13;
}
// styling to get icon text to appear below icon
a.icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.text-icon {
  width: 80px;
  line-height: 10pt;
  padding-top: 4px;
}

#chartNotification,
#layerNotification,
#scenarioNotification {
  position: absolute;
  right: 80px;
  width: 187px;
  height: auto;
  z-index: 999;
  color: #000;
}
#chartNotification {
  top: 290px;
}
#layerNotification {
  top: 220px;
}
#scenarioNotification {
  top: 150px;
}
a[disabled='true'] span {
  color: darkgray;
}
a[disabled='true']:hover {
  background: #363636 !important;
  cursor: default;
}
</style>
