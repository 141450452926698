<template>
  <p class="panel-heading">
    Area of Interest Selection
    <button
      v-if="aoiInStorage"
      class="delete is-pulled-right"
      @click="$emit('cancelButtonClicked')"
    >
      Close Panel
    </button>
  </p>
  <div class="container p-4">
    <!-- workflow description -->
    <div class="block content is-size-7 mb-0">
      <!-- geoman draw button -->
      <div class="field has-addons">
        <p class="control">
          <button
            class="button"
            @click="drawButtonClicked"
            :disabled="!layersReady || mode != null"
          >
            <span class="icon">
              <i class="geoman-icon geoman-rectangle"></i>
            </span>
            <span>Create</span>
          </button>
        </p>
      </div>

      <!-- geoman edit buttons -->
      <div class="field has-addons" v-if="aoiCreated">
        <p class="control">
          <button
            :disabled="mode != null || !layersReady"
            class="button"
            @click="geomanButtonClicked('edit')"
          >
            <span class="icon">
              <i class="geoman-icon geoman-edit-vertex"></i>
            </span>
            <span>Edit</span>
          </button>
          <!-- finish button -->
          <button
            v-if="mode == 'edit'"
            class="button is-primary"
            @click="finishButtonClicked('edit')"
          >
            <span>Finish</span>
          </button>
        </p>
        <p class="control">
          <button
            :disabled="mode != null || !layersReady"
            class="button"
            @click="geomanButtonClicked('move')"
          >
            <span class="icon">
              <i class="geoman-icon geoman-move"></i>
            </span>
            <span>Move</span>
          </button>
          <!-- finish button -->
          <button
            v-if="mode == 'move'"
            class="button is-primary"
            @click="finishButtonClicked('move')"
          >
            <span>Finish</span>
          </button>
        </p>
        <p class="control">
          <button
            :disabled="mode != null || !layersReady"
            class="button"
            @click="geomanButtonClicked('erase')"
          >
            <span class="icon">
              <i class="geoman-icon geoman-eraser"></i>
            </span>
            <span>Remove</span>
          </button>
        </p>
      </div>
    </div>

    <!-- 
      reporting results of drawing
      the intention here is to help the user manage the AOI
      to meet the minimum area requirement of 72 square miles
     -->
    <table v-if="aoiCreated" class="table is-fullwidth is-size-7">
      <thead>
        <tr>
          <th></th>
          <th>Count</th>
          <th>Area (sq mi)</th>
        </tr>
      </thead>
      <tbody>
        <tr :style="{ color: layerData.subcatchments.color }">
          <td>Subcatchments</td>
          <td>{{ layerData.subcatchments.count }}</td>
          <td>{{ layerData.subcatchments.area.toLocaleString() }}</td>
        </tr>
        <tr :style="{ color: layerData.tb.color }">
          <td><span>&#188;</span> Townships</td>
          <td>{{ layerData.tb.count }}</td>
          <td>{{ layerData.tb.area.toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 
    don't show the warning or the save/cancel buttons 
    until an AOI is drawn and it is an acceptable size
  -->

    <!-- minimum size warning -->
    <div
      class="notification is-warning is-light"
      v-if="!acceptableSize && aoiCreated"
    >
      Area of interest must have a minimum size of 72 square miles.
    </div>
    <!-- save/cancel buttons -->
    <fieldset :disabled="!acceptableSize || !aoiCreated || mode">
      <div class="field is-grouped mt-2">
        <div class="control">
          <button class="button is-link" @click="$emit('saveButtonClicked')">
            Save
          </button>
        </div>
        <div v-if="aoiInStorage" class="control">
          <button class="button is-light" @click="$emit('cancelButtonClicked')">
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </div>

  <!-- draw notification -->
  <div
    id="drawNotification"
    class="notification is-warning is-size-7"
    v-if="showDrawWarning"
  >
    <button class="delete" @click="showDrawWarning = false"></button>
    You must remove the current AOI of interest before creating a new one.
  </div>

  <div class="card accordion-block">
    <a
      href="#"
      class="card-header"
      @click="$event.currentTarget.classList.toggle('is-open')"
      >Using Selection Tools
      <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
    /></a>
    <div class="card-content is-size-7-plus">
      <p><strong>Create an area of interest (AOI)</strong>.</p>
      <p class="mb-3">
        Your AOI will be used to render the modeling data and create statistical
        summaries. The AOI will be comprised of
        <span :style="{ color: layerData.subcatchments.color }">
          subcatchment (water)
        </span>
        and
        <span :style="{ color: layerData.tb.color }"
          ><span>&#188;</span> township (mammal and bird)
        </span>
        spatial units.
      </p>
      <p class="mb-3">
        <strong>Click Create</strong> and draw a rectangle on the map. As you
        draw, the spatial units that will be included in your AOI will appear.
        Once selected you can <strong>Edit</strong> its shape,
        <strong>Move</strong> it around, <strong>Rotate</strong> it, or
        <strong>Erase</strong> it and start over. When you are done, click
        <strong>Save</strong> to save your AOI.
      </p>
      <p><strong>AOI Requirements</strong></p>
      <ul>
        <li>Must be contiguous in shape</li>
        <li>Must have a minimum size of 72 square miles</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
    'drawButtonClicked',
    'geomanButtonClicked',
    'finishButtonClicked',
    'saveButtonClicked',
    'cancelButtonClicked'
  ],
  props: {
    layerData: Object,
    aoiCreated: Boolean,
    layersReady: Boolean,
    aoiInStorage: Boolean
  },
  computed: {
    acceptableSize() {
      return (
        this.layerData.subcatchments.area > 72 && this.layerData.tb.area > 72
      )
    }
  },
  data() {
    return {
      debug: true,

      // geoman tool mode
      mode: null,

      showDrawWarning: false
    }
  },
  methods: {
    // handle draw button being clicked
    drawButtonClicked() {
      // check if AOI is already drawn,
      // give message to delete previous shape first
      if (this.aoiCreated) {
        this.showDrawWarning = true
      } else {
        this.$emit('drawButtonClicked')
      }
    },

    // handle geoman button being clicked
    geomanButtonClicked(mode) {
      // set current mode
      this.mode = mode
      // fire off event
      this.$emit('geomanButtonClicked', mode)

      // if it's erase reset the mode here because there is no finish button
      if (mode == 'erase') {
        this.mode = null
      }
    },

    // finish task button clicked
    finishButtonClicked(mode) {
      // reset mode
      this.mode = null
      // fire off event
      this.$emit('finishButtonClicked', mode)
    }
  }
}
</script>

<style scoped>
#drawNotification {
  position: absolute;
  top: 164px;
  right: 96px;
  width: 150px;
  height: auto;
}
.card.accordion-block {
  border-top: 1px solid #ccc;
}
</style>
