// layer symbology for the Forest Optimization Tool

// ref: https://gka.github.io/chroma.js
// for color
import chroma from 'chroma-js'

const debug = false

// symbology definitions

// for use with geoRasterLayer, continuous values
function continuousColor(pixelValues, georaster, scaleName) {
  // style raster using a scale
  // code based on https://github.com/GeoTIFF/georaster-layer-for-leaflet-example/blob/master/examples/color-scale.html
  // style should probably be specified in mapData.js

  // explicitly calculate min, max, range since
  // georaster was using noDataValue in stored values
  // ref: https://stackoverflow.com/a/47691794/6072959
  // FIXME: no good, due to noDataValue
  // just using zero for the time being
  // but these rasters should be re-formatted to a different bit depth and noDataValue
  // let max = Math.max(...[].concat(...this.georaster.values[0]))
  // let min = Math.min(...[].concat(...this.georaster.values[0]))
  let max = georaster.maxs[0]
  let min = georaster.mins[0]
  let range = max - min

  if (debug) {
    console.log(min, max, range)
  }

  let pixelValue = pixelValues[0] // there's just one band in this raster

  // no color for no data
  if (pixelValue === georaster.noDataValue) return null

  // scale to 0 - 1 used by chroma
  let scaledPixelValue = (pixelValue - min) / range

  const scale = chroma.scale(scaleName)
  let color = scale(scaledPixelValue).hex()

  if (debug) {
    console.log('_continuousColor :>> ', pixelValue, scaledPixelValue, color)
  }

  return color
}

// for use with geoRasterLayer, categorical values
// ideally rely on a value map
// otherwise just use random color
function categoricalColor(pixelValues, georaster, layerData) {
  let pixelValue = pixelValues[0] // there's just one band in this raster

  // no color for no data
  if (
    pixelValue === georaster.noDataValue ||
    layerData.noDataValues.includes(pixelValue)
  )
    return null
  // otherwise assign color based on value map
  let color = layerData.colors[pixelValue - 1]

  if (debug) {
    console.log('_continuousColor :>> ', pixelValue, color)
  }

  return color
}

const geojsonStyles = {
  stylePoly: function (features, year, scaleName, opacity = 0.7) {
    // sort out parameters for color scale
    // based on geojson's return values
    const scale = chroma.scale(scaleName)

    // get list of values and then find min, max
    const values = features.map(function (feat) {
      return feat.properties[year]
    })
    const min = Math.min(...values)
    const max = Math.max(...values)

    // set style feature for geojson
    const style = feature => {
      // assign color based on value
      // for use with chroma.js color scale
      // scale to 0 - 1 used by chroma
      let scaledValue = (feature.properties[year] - min) / (max - min)
      let color = scale(scaledValue).hex()
      return {
        fillColor: color,
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: opacity
      }
    }

    return style
  },

  styleLine: function (features, year, scaleName, opacity = 1) {
    // sort out parameters for color scale
    // based on geojson's return values
    const scale = chroma.scale(scaleName)

    // get list of values and then find min, max
    const values = features.map(function (feat) {
      return feat.properties[year]
    })
    const min = Math.min(...values)
    const max = Math.max(...values)

    // TODO: connect streams together

    // set style feature for geojson
    const style = feature => {
      // assign color based on value
      // for use with chroma.js color scale
      // scale to 0 - 1 used by chroma
      let scaledValue = (feature.properties[year] - min) / (max - min)
      let color = scale(scaledValue).hex()
      // reaches
      return {
        color: color,
        weight: 8,
        opacity: opacity
      }
    }

    return style
  }
}

const aoiStyles = {
  draw: {
    color: '#3388ff',
    weight: 3,
    dashArray: '4 6',
    fillColor: '#3388ff',
    fillOpacity: 0.2
  },
  display: {
    color: '#3388ff',
    weight: 3,
    dashArray: '5 10'
  }
}

export { continuousColor, categoricalColor, geojsonStyles, aoiStyles }
