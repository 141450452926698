<template>
  <div class="container p-4">
    <div v-for="scenario in scenarios" :key="scenario.key">
      <scenario-select
        :scenario="scenario"
        :selectedScenario="initialSelection[scenario.name]"
        @onScenarioInfoClick="getScenarioInfo"
        @onSelectionChange="updateSelectedScenario($event)"
      ></scenario-select>
    </div>
    <!-- selection warning -->
    <!-- <div class="notification is-warning is-light" v-if="!allSelected">
      You must have all scenarios selected before proceeding.
    </div> -->
    <fieldset>
      <div class="field is-grouped mt-2">
        <div class="control">
          <button
            class="button is-link"
            @click="onSave"
            :disabled="!allSelected"
          >
            Save
          </button>
        </div>
        <div class="control">
          <button class="button is-light" @click="onCancel">Cancel</button>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
// scenario store
import theStore from '../store/store.js'
// scenario data
import scenarioData from '../data/scenarioData.js'
// select components
import ScenarioSelect from './ScenarioSelect.vue'
export default {
  components: {
    ScenarioSelect
  },
  emits: ['infoButtonClick', 'saveButtonClick'],
  methods: {
    // go grab the text file for the layer in question
    getInfoForScenario(scenarioName) {
      try {
        return require(`@/content/${scenarioName}.txt`).default
      } catch (err) {
        let noInfo = 'No additional information is available'
        return noInfo
      }
    },

    // show scenario info panel
    getScenarioInfo(scenarioName) {
      let infoTitle = this.getScenario(scenarioName).info.title
      let infoContent = this.getInfoForScenario(scenarioName)
      let infoData = { title: infoTitle, body: infoContent }
      if (this.debug) {
        console.log('infoContent: ', infoContent)
        console.log('infoTitle: ', infoTitle)
      }
      this.$emit('infoButtonClick', { data: infoData, toggle: true })
    },

    // get scenario by name
    getScenario(name) {
      return this.scenarios.filter(function (scenario) {
        return scenario.name == name
      })[0]
    },

    // get scenario data by value
    getScenarioData(scenario, value) {
      return scenarioData[scenario].filter(obj => obj.value == value)[0]
    },

    // update scenario on selection change
    updateSelectedScenario(scenario) {
      // get scenario data first
      const data = this.getScenarioData(scenario.type, scenario.value)
      if (this.debug) {
        console.log('onSelectionChange', scenario, data)
      }
      this.selected[scenario.type] = data
    },

    // when scenario is saved, update value in store
    // and close panel
    onSave: function () {
      // loop through scenarios and save them
      for (const scenario in this.selected) {
        theStore.store.updateAction(scenario, this.selected[scenario])
        // send results to Google Tag Manager
        this.$gtm.trackEvent({
          event: 'Form Submission',
          category: 'Climate-Scenario-Selected',
          label: scenario,
          value: this.selected[scenario].name
        })
      }
      //if Model Output not already selected, open the Model Output page
      if (!theStore.store.isLayerSelected()) {
        this.$parent.$emit('closePanelClick', 'layers')
      }

      // else {
      //   // just close out Scenario pane
      //   this.$parent.$emit('closePanelClick', 'scenario')
      // }
      //close info window
      this.$emit('saveButtonClick')
    },

    // cancel just closes the panel
    onCancel: function () {
      this.$parent.$emit('closePanelClick', 'scenario')
    }
  },
  computed: {
    // returns true if all scenarios have been selected
    allSelected() {
      let selected_values = Object.values(this.selected)
      // check to see if the value of any of scenario selections are null
      if (selected_values.some(item => item.value === null)) {
        return false
      } else {
        return true
      }
    },
    initialSelection() {
      return theStore.store.state
    }
  },
  data() {
    return {
      debug: false,

      selected: {
        climate: theStore.store.state.climate,
        forestMgmt: theStore.store.state.forestMgmt,
        year: theStore.store.state.year
      },

      // scenario data
      // options are pulled in from scenario data
      scenarios: [
        {
          name: 'climate',
          label: 'Select Climate Scenario',
          icon: 'cloud-sun-rain',
          options: scenarioData.climate,
          info: {
            title: 'Climate Scenarios',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Pellentesque risus mi</strong>, tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla. Nullam gravida purus diam, et dictum <a>felis venenatis</a> efficitur. Aenean ac <em>eleifend lacus</em>, in mollis lectus. Donec sodales, arcu et sollicitudin porttitor, tortor urna tempor ligula, id porttitor mi magna a neque. Donec dui urna, vehicula et sem eget, facilisis sodales sem'
          }
        },
        {
          name: 'forestMgmt',
          label: 'Select Forest Management Scenario',
          icon: 'tree',
          options: scenarioData.forestMgmt,
          info: {
            title: 'Forest Management Scenarios',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Pellentesque risus mi</strong>, tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla. Nullam gravida purus diam, et dictum <a>felis venenatis</a> efficitur. Aenean ac <em>eleifend lacus</em>, in mollis lectus. Donec sodales, arcu et sollicitudin porttitor, tortor urna tempor ligula, id porttitor mi magna a neque. Donec dui urna, vehicula et sem eget, facilisis sodales sem'
          }
        },
        {
          name: 'year',
          label: 'Select Year',
          icon: 'calendar',
          options: scenarioData.year,
          info: {
            title: 'Year Scenarios',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Pellentesque risus mi</strong>, tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla. Nullam gravida purus diam, et dictum <a>felis venenatis</a> efficitur. Aenean ac <em>eleifend lacus</em>, in mollis lectus. Donec sodales, arcu et sollicitudin porttitor, tortor urna tempor ligula, id porttitor mi magna a neque. Donec dui urna, vehicula et sem eget, facilisis sodales sem'
          }
        }
      ]
    }
  }
}
</script>

<style></style>
