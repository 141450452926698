/* FeatureGroup manages and supports FoGeoJson, which, in turn, is how the model
groups that are vector data (water, mammals, etc) are rendered */
<template>
  <l-feature-group :visible="visible" layer-type="overlay" ref="featureGroup">
    <!-- load list of layers, only visible one is where it matches the current name -->
    <l-geo-json
      v-for="lyr in layers"
      :key="lyr.name"
      :visible="currentName == lyr.name"
      :geojson="lyr.geojson"
      :options="lyr.geojsonOptions"
      :name="lyr.name"
      @ready="geojsonReady"
      ref="geojsonLayer"
    ></l-geo-json>
  </l-feature-group>
</template>

<script>
// vue-leaflet featuer group
import {
  LFeatureGroup,
  LGeoJson
} from '@vue-leaflet/vue-leaflet/src/components'
// import support funtionality
import utils from '@/modules/foVectorLayers.js'
// import layer styling funtionality
import { geojsonStyles } from '@/modules/layerSymbology.js'

export default {
  components: { LFeatureGroup, LGeoJson },
  props: {
    config: Object,
    scenario: Object,
    themap: Object,
    ids: Array,
    visible: Boolean,
    isLayerChange: Boolean,
    opacity: Number
  },
  emits: ['currentLayer'],
  data() {
    return {
      debug: false,
      // geojson layers in this feature group
      layers: []
    }
  },
  computed: {
    // name of current scenario's layer if available
    currentName: function () {
      return this.scenario
        ? `${this.scenario.layer.value}_${this.scenario.climate.value}_${this.scenario.forestMgmt.value}`
        : null
    },

    // currently selected geojson if available
    currentLayer: function () {
      // get name for current scenario's layer
      const name = this.currentName

      // return layer if it already exists, otherwise return undefined
      return name ? this.layers.find(lyr => lyr.name == name) : null
    }
  },
  watch: {
    // watch for changes to scenario and layer
    scenario: {
      deep: true,

      // scenario watch handler
      // load geojson data if it hasn't been loaded yet
      // otherwise update style for layer to reflect current year
      handler() {
        let layer = this.currentLayer

        // if layer already exists
        // update style for layer to reflect current year
        if (layer) {
          if (this.debug) {
            console.log(
              `scenario watch handler. ${this.currentName} already exists`
            )
          }
          this.updateLayerStyle(layer)
          // update selected layer on map
          this.$emit('currentLayer', layer)
        }
        // if it doesn't exist get the data from geoserver
        else {
          if (this.debug) {
            console.log(
              `scenario watch handler. ${this.currentName} doesn't exist`
            )
          }
          this.getGeojsonData()
        }
      }
    },

    // layer opacity watching for updates from parent
    opacity: {
      deep: true,
      handler() {
        let layer = this.currentLayer

        // if we have a layer loaded update it's styles using new opacity value
        if (layer) {
          this.updateLayerStyle(layer)
        }
      }
    }
  },

  // load geojson data on mount if possible
  mounted() {
    console.log('mounted')
    // check if scenario exists
    if (this.scenario) {
      this.getGeojsonData()
    }
  },

  methods: {
    // grab geojson data from geoserver using current scenario
    // just run this once for each scenario/layer
    async getGeojsonData() {
      const scenarioValues = {
        climate: this.scenario.climate.value,
        forestMgmt: this.scenario.forestMgmt.value,
        layer: this.scenario.layer.value,
        // handle array in geoserver sql viewparams
        ids: this.ids.toString().replace(/,/g, '\\,')
      }
      // call view param function from utils as specified in config
      const viewparams = utils[this.config.viewparamFn](scenarioValues)
      if (this.debug) {
        console.log('viewparams :>> ', viewparams)
      }
      const url = utils.generateWFSURL(this.config.sqlview, viewparams)
      // get geojson from geoserver
      const response = await fetch(url)
      // set leaflet geojson value from geoserver response
      const geojson = await response.json()
      // get name for layer
      const name = this.currentName
      // get downstream data for water layers
      let valDS, refValues
      if (this.scenario.layerCat.value == 'w') {
        valDS = await utils.calcFlowWeightedMean(scenarioValues)
        refValues = await utils.calcFlowWeightedMean(scenarioValues, true)
      }
      // get baseline values for other layer categories
      else {
        refValues = await utils.getDataAllYears(
          scenarioValues,
          this.scenario.layerCat.value
        )
      }

      // layer definition sent to FoGeoJson
      const layer = {
        // store scenario's layer with layer
        layerData: this.scenario.layer,
        name: name,
        // layer's category
        layerCat: this.scenario.layerCat,
        values: utils.geojsonValues(geojson, this.scenario.year.value),
        // actual geojson data
        geojson: geojson,
        // reference values for layer
        refValues: refValues,
        // layer for all years for water
        waterData: valDS || null,
        // style function for layer
        geojsonOptions: {
          // render features for selected year using specified symbology
          style: geojsonStyles[this.config.style](
            geojson.features,
            this.scenario.year.value,
            this.scenario.layerCat.scale,
            this.opacity
          )
        }
      }
      this.layers.push(layer)
      if (this.debug) {
        console.log(`adding ${name} to layers`)
      }

      // update selected layer on map
      this.$emit('currentLayer', layer)
    },

    geojsonReady(layer) {
      if (this.debug) {
        console.log('geojson ready :>> ', layer)
      }
      // zoom to bounds, if it was a layer change
      // add padding so we can see the whole shape
      if (this.isLayerChange) {
        this.themap.fitBounds(layer.getBounds(), {
          padding: [20, 20]
        })
      }
    },

    // update style for layer to reflect changes to year or layer opacity
    updateLayerStyle(layer) {
      const style = geojsonStyles[this.config.style](
        layer.geojson.features,
        this.scenario.year.value,
        this.scenario.layerCat.scale,
        this.opacity
      )
      // get current layer
      let currLyr = this.$refs.geojsonLayer.find(
        gLyr => gLyr.name == this.currentName
      )
      // set new style
      currLyr.leafletObject.setStyle(style)
    }
  }
}
</script>

<style></style>
