// storing the current app state

import { reactive } from 'vue'

const store = {
  debug: false,
  state: reactive({
    // user scenario - default values
    // prime with scenario for debugging
    climate: { name: 'None selected', value: null },
    forestMgmt: { name: 'None selected', value: null },
    year: { name: 'None Selected', value: null },
    // DEBUG - these values are just for testing
    // climate: { value: 'low', name: 'Low Emission' },
    // forestMgmt: { value: 'd30', name: '30% Decrease Harvest' },
    // year: { name: '2090', value: '2090' },

    // map layer - default values
    layer: { name: 'None selected', value: null },
    layerCat: { name: 'None selected', value: null },

    // layer data - default values
    // [array] contains the values for current layer for each year
    // format -> { year: ..., values: ... }
    layerData: null,

    // [array] reference data for current layer
    // based on baseline scenario
    refData: null,

    // currently selected basemap
    // start with osm as the default?
    basemap: {
      name: 'Streets',
      value: 'streets',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>contributors',
      image: 'osm.jpg'
    },

    // layer opacity updated from map tools
    layerOpacity: 0.7,

    // area of interest data
    // aoi bounds
    aoi: null,
    // DEBUG
    // aoi: [
    //   [
    //     { lat: 47.156104775044035, lng: -92.92236328125001 },
    //     { lat: 47.156104775044035, lng: -92.40600585937501 },
    //     { lat: 46.924007100770275, lng: -92.40600585937501 },
    //     { lat: 46.924007100770275, lng: -92.92236328125001 }
    //   ]
    // ],
    // subcatchment IDs
    subcatchments: null,
    // subcatchments: [
    //   5287, 5257, 5258, 5259, 5266, 5269, 5270, 5271, 5272, 5273, 5274, 5283,
    //   5277, 5278, 5279, 5288, 5290, 5291, 5319, 5320, 5322, 5344
    // ],
    // township block IDs
    townshipBlocks: null
    // townshipBlocks: [
    //   163001, 163002, 163003, 163004, 164001, 164002, 164003, 164004, 165001,
    //   165002, 165003, 165004, 166001, 166002, 166003, 166004, 167001, 167003,
    //   185001, 185002, 185003, 185004, 186001, 186002, 186003, 186004, 187001,
    //   187002, 187003, 187004, 188001, 188002, 188003, 188004, 189001, 189003,
    //   207001, 207002, 207003, 207004, 208001, 208002, 208003, 208004, 209001,
    //   209002, 209003, 209004, 210001, 210002, 210003, 210004, 211001, 211003
    // ]
  }),

  // generic update store value
  updateAction(property, value) {
    if (this.debug) {
      console.log(
        'set new store property :>> ',
        property,
        JSON.stringify(value)
      )
    }
    this.state[property] = value
  },

  // get current scenario
  getScenario() {
    return {
      climate: this.state.climate,
      forestMgmt: this.state.forestMgmt,
      year: this.state.year
    }
  },

  // get scenario and layer state
  // some components need both
  getScenarioAndLayer() {
    const data = this.getScenario()
    data.layer = this.state.layer
    data.layerCat = this.state.layerCat
    return data
  },

  // get current aoi state
  getAOI() {
    return {
      aoi: this.state.aoi,
      subcatchments: this.state.subcatchments,
      townshipBlocks: this.state.townshipBlocks
    }
  },

  // get current layer
  getLayer() {
    return this.state.layer, this.state.layerCat
  },

  // return layer name (as seen in Geoserver and original GeoTIFF)
  // based on scenario data
  // file naming scheme doc:
  // https://docs.google.com/document/d/1jQ_Hhs-xcSU2K-hlA4UXjRVTfzImIH_pkdG0sloOjT8
  getLayerName() {
    return `${this.state.layerCat.value}_${this.state.forestMgmt.value}_${this.state.climate.value}_${this.state.year.value}_${this.state.layer.value}`
  },

  // get full layer name (for pretty printing)
  getFullLayerName() {
    return `${this.state.forestMgmt.name} - ${this.state.climate.name} - ${this.state.year.name} - ${this.state.layer.name}`
  },
  isAoiSelected() {
    if (
      this.state.aoi &&
      this.state.subcatchments &&
      this.state.townshipBlocks
    ) {
      return true
    } else {
      return false
    }
  },
  // check if scenario have been selected and stored
  isScenarioSelected() {
    if (
      this.state.forestMgmt.value &&
      this.state.climate.value &&
      this.state.year.value
    ) {
      return true
    } else {
      return false
    }
  },

  // check if layer has been selected and stored
  isLayerSelected() {
    if (this.state.layer.value) {
      return true
    } else {
      return false
    }
  },

  // return layer opacity
  getLayerOpacity() {
    return this.state.layerOpacity
  }
}

export default { store }
