/*
  This is not going to produce and return an actual renderable layer
  like `foRasterLayer.js` does.
  Rather, this is mostly utility functions that don't need to clog
  up component files
*/

// load mapData
import mapData from '@/data/mapData.js'

// utility functions
import utilities from '../modules/utilities.js'

const utils = {
  // const debug = false

  // generate URL to get geojson data back from geoserver via sqlview
  generateWFSURL(sqlview, viewparams) {
    const options = new URLSearchParams({
      service: 'WFS',
      version: '2.0.0',
      request: 'GetFeature',
      typeName: `foropt:${sqlview}`,
      srsName: 'EPSG:4326',
      // maxFeatures: '50',
      outputFormat: 'application/json',
      viewparams: viewparams
    })
    return mapData.geoserverURL + options
  },

  // generate viewparam WFS param
  // ref: https://docs.geoserver.org/master/en/user/data/database/sqlview.html
  setWildlifeViewParams(scenario) {
    let viewparams = ''
    // // add scenario view params
    for (const key in scenario) {
      viewparams += `${key}:${scenario[key]};`
    }

    return viewparams
  },

  // generate viewparam WFS param
  // ref: https://docs.geoserver.org/master/en/user/data/database/sqlview.html
  setWaterViewParams(scenario) {
    let viewparams = ''

    // set view params based on scenario
    viewparams += `layer:${scenario.layer};`
    viewparams += `ids:${scenario.ids};`
    viewparams += `table:w_${scenario.forestMgmt}_${scenario.climate};`

    return viewparams
  },

  // get values from geojson data for specific field
  geojsonValues(geojson, col) {
    // return array of values for that col
    return geojson.features.map(function (feat) {
      return feat.properties[col]
    })
  },

  // return object with values for each year
  geojsonValuesAllYears(geojson) {
    // get range of years
    const years = utilities.range(2020, 2100, 10)

    // iterate over years
    return years.map(yr => {
      return {
        year: yr,
        values: this.geojsonValues(geojson, yr)
      }
    })
  },

  // generate viewparam WFS param
  // ref: https://docs.geoserver.org/master/en/user/data/database/sqlview.html
  setRefvalueViewParams(scenario, category) {
    let viewparams = ''

    // set view params based on scenario
    viewparams += `layer:${scenario.layer};`
    viewparams += `ids:${scenario.ids};`

    // add table for wildlife groups
    const tableName = category == 'b' ? 'b_hsi' : 'm_hsi'
    viewparams += `table:${tableName};`

    return viewparams
  },

  // get reference values from geoserver for specific layer
  async getDataAllYears(scenarioValues, category = null, water = false) {
    // set viewparams and sqlview name based on whether this is water layer
    const [viewparams, sqlview] = (() => {
      if (water) {
        // set view params get name of sqlview from config
        return [
          this.setWaterViewParams(scenarioValues),
          mapData.waterConfig.dsView
        ]
      } else {
        // set view params get name of sqlview from config
        return [
          this.setRefvalueViewParams(scenarioValues, category),
          mapData.refValueSqlViews[category]
        ]
      }
    })()

    // generate geoserver URL
    const url = utils.generateWFSURL(sqlview, viewparams)
    // get data from geoserver
    const response = await fetch(url)
    // set leaflet geojson value from geoserver response
    const data = await response.json()

    return this.geojsonValuesAllYears(data)
  },

  // calculate the flow-weighted mean using SQL view
  async calcFlowWeightedMean(scenarioValues, ref = false) {
    // modify scenario for refData
    if (ref) {
      scenarioValues.climate = 'cc'
      scenarioValues.forestMgmt = 's'
    }
    // get data from SQL view and return it
    const dsData = await this.getDataAllYears(scenarioValues, null, true)
    console.log('calcFlowWeightedMean', dsData)
    return dsData
  }
}

export default utils
