<template>
  <div class="container">
    <!-- basemap selection -->
    <div class="card accordion-block">
      <a href="#" class="card-header" @click="toggleSubMenu($event)"
        >Basemaps
        <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
      /></a>
      <div class="card-content basemaps">
        <!-- create one for each basemap -->
        <div v-for="basemap in basemapLayers" :key="basemap.name" class="mb-3">
          <basemap-thumbnail
            :basemap="basemap"
            @selectBasemap="selectBasemap"
          ></basemap-thumbnail>
        </div>
      </div>
    </div>
    <!-- layer appearance -->
    <div class="card accordion-block">
      <a href="#" class="card-header" @click="toggleSubMenu($event)"
        >Layer Opacity<fa
          :icon="['fa', 'chevron-down']"
          class="is-pulled-right mr-2"
      /></a>
      <div class="card-content"><layer-viz></layer-viz></div>
    </div>
    <!-- save map settings -->
    <div class="card accordion-block">
      <a href="#" class="card-header" @click="toggleSubMenu($event)"
        >Share <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
      /></a>
      <div class="card-content">
        <save-settings :activateSave="layerSelected($event)"></save-settings>
      </div>
    </div>
    <!-- download data -->
    <div class="card accordion-block">
      <a href="#" class="card-header" @click="toggleSubMenu($event)"
        >Download Data
        <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
      /></a>
      <div class="card-content">
        <p class="mb-3">
          <strong>Bird Habitat Model Output</strong><br />
          <a
            href="https://mnforcast.org/downloads/final_gdb_birds.gdb.zip"
            target="_blank"
            >ESRI File Geodatabase</a
          ><br />
          <a
            href="https://mnforcast.org/downloads/ForCAST_bird_outputs.html"
            target="_blank"
            >Metadata</a
          >
        </p>

        <p class="mb-3">
          <strong>Landscape Model Output</strong><br />
          <a
            href="https://mnforcast.org/downloads/final_gdb_land.gdb.zip"
            target="_blank"
            >ESRI File Geodatabase</a
          ><br />
          <a
            href="https://mnforcast.org/downloads/ForCAST_landscape_outputs.html"
            target="_blank"
            >Metadata</a
          >
        </p>

        <p class="mb-3">
          <strong>Mammal Habitat Model Output</strong><br />
          <a
            href="https://mnforcast.org/downloads/final_gdb_mammals.gdb.zip"
            target="_blank"
            >ESRI File Geodatabase</a
          ><br />
          <a
            href="https://mnforcast.org/downloads/ForCAST_mammal_outputs.html"
            target="_blank"
            >Metadata</a
          >
        </p>

        <p>
          <strong>Water Model Output</strong><br />
          <a
            href="https://mnforcast.org/downloads/final_gdb_water.gdb.zip"
            target="_blank"
            >ESRI File Geodatabase</a
          ><br />
          <a
            href="https://mnforcast.org/downloads/ForCAST_water_outputs.html"
            target="_blank"
            >Metadata</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// get basemap data from map data config
import mapData from '@/data/mapData.js'

// map store
import theStore from '../store/store.js'

// import local components
import BasemapThumbnail from './BasemapThumbnail.vue'
import SaveSettings from './SaveSettings.vue'
import LayerViz from './LayerViz.vue'

export default {
  components: {
    BasemapThumbnail,
    SaveSettings,
    LayerViz
  },
  data() {
    return {
      // initial value - gets populated in mouted hook
      basemapLayers: []
    }
  },
  computed: {
    // url to download data from ckan
    dataDownloadURL() {
      return mapData.dataDownloadURL
    }
  },
  mounted() {
    // set basemaps from map data config
    this.basemapLayers = mapData.basemap
  },
  methods: {
    layerSelected() {
      return theStore.store.isLayerSelected()
    },
    // triggers when basemap is clicked
    selectBasemap(basemap) {
      console.log(
        'basemap selected',
        theStore.store.state.basemap.name,
        basemap.name
      )
      // check if it's a new basemap
      if (
        theStore.store.state.basemap &&
        basemap.name != theStore.store.state.basemap.name
      ) {
        // if it is, then update the store
        theStore.store.updateAction('basemap', basemap)
      }
    },
    toggleSubMenu(item) {
      item.currentTarget.classList.toggle('is-open')
    }
  }
}
</script>

<style scoped>
.container {
  width: 330px;
}
.card-content.basemaps > div {
  display: inline-block;
  width: 50%;
}
</style>
