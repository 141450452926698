/* eslint-disable vue/no-v-for-template-key */
<template>
  <l-map
    ref="themap"
    :zoom="zoom"
    :center="center"
    :max-bounds="maxBounds"
    :max-zoom="maxZoom"
    :min-zoom="minZoom"
    :options="{ beforeMapMount, useGlobalLeaflet: true }"
    :use-global-leaflet="true"
    @ready="mapReady"
    @beforeMapMount="beforeMapMount"
    @viewreset="mapViewreset"
    @update:center="mapUpdateCenter"
  >
    <!-- control for legend -->
    <l-control position="topleft">
      <!-- legend -->
      <map-legend
        :layer="selectedLayer"
        @mouseenter="focusScroll"
        @mouseleave="focusMap"
      ></map-legend>
    </l-control>
    <!-- scale bar -->
    <l-control-scale
      position="bottomleft"
      :imperial="true"
      :metric="true"
    ></l-control-scale>
    <!-- aoi rectangle -->
    <l-rectangle
      v-if="bounds"
      ref="aoi"
      :bounds="bounds"
      :color="aoiStyle.color"
      :weight="aoiStyle.weight"
      :dash-array="aoiStyle.dashArray"
      @ready="aoiReady"
    ></l-rectangle>
    <!-- mammal layers -->
    <fo-feature-group
      :visible="scenario.layerCat.value == 'm'"
      :config="mammalConfig"
      :scenario="mammalScenario"
      :themap="themap"
      :ids="townshipBlocks"
      :is-layer-change="isLayerChange"
      :opacity="layerOpacity"
      @current-layer="onGeojsonLayerChange"
    >
    </fo-feature-group>
    <!-- bird layers -->
    <fo-feature-group
      :visible="scenario.layerCat.value == 'b'"
      :config="birdConfig"
      :scenario="birdScenario"
      :themap="themap"
      :ids="townshipBlocks"
      :is-layer-change="isLayerChange"
      :opacity="layerOpacity"
      @current-layer="onGeojsonLayerChange"
    >
    </fo-feature-group>
    <!-- water layers -->
    <fo-feature-group
      :visible="scenario.layerCat.value == 'w'"
      :config="waterConfig"
      :scenario="waterScenario"
      :themap="themap"
      :ids="subcatchments"
      :is-layer-change="isLayerChange"
      :opacity="layerOpacity"
      @current-layer="onGeojsonLayerChange"
    >
    </fo-feature-group>
    <!-- raster layers GeoTiff -->
    <l-feature-group
      :visible="
        scenario.layerCat.value == 'l' || scenario.layerCat.value == 'c'
      "
      layer-type="overlay"
      ref="rasterLayers"
    >
    </l-feature-group>
    <!-- basemap layers -->
    <l-layer-group layer-type="base">
      <!-- 
        FIXME: attribution should not be hardcoded
        still waiting to see if this is a bug or what the fix could be
        ref: https://discord.com/channels/680736948935327767/680738284753780756/905858771828477953
       -->
      <l-tile-layer
        :url="basemap.url"
        layer-type="base"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>contributors'
        :name="basemap.name"
      ></l-tile-layer>
    </l-layer-group>

    <!-- loading spinner -->
    <span id="mapSpinner" class="icon-text" v-if="showSpinner">
      <!-- <progress v-if="isRaster" class="progress" value="0" max="100"></progress> -->
      <span class="icon"> <fa :icon="['fa', 'spinner']" spin size="4x" /></span>
      <span class="text-icon">{{ spinnerMessage }}</span>
    </span>
  </l-map>
</template>

<script>
// leaflet CSS import
import 'leaflet/dist/leaflet.css'
// vue-leaflet imports
import {
  LMap,
  LTileLayer,
  LLayerGroup,
  LFeatureGroup,
  LControlScale,
  LControl,
  LRectangle
} from '@vue-leaflet/vue-leaflet/src/components'

// local component imports
// import FoGeoJson from './FoGeoJson.vue'
import MapLegend from './MapLegend.vue'
import FoFeatureGroup from './FeatureGroup.vue'

// load mapData
import mapData from '@/data/mapData.js'

// geoRasterLayer factory
import foRasterLayer from '@/modules/foRasterLayer.js'

// import vector support funtionality
import vectorUtils from '@/modules/foVectorLayers.js'

// import economic data support funtionality
import econUtils from '@/modules/econdata.js'

// store
import theStore from '../store/store.js'

// import layer styling funtionality
import { aoiStyles } from '@/modules/layerSymbology.js'

export default {
  name: 'FoMap',
  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    LFeatureGroup,
    LControlScale,
    LControl,
    LRectangle,
    MapLegend,
    FoFeatureGroup
  },
  emits: ['layerChange'],
  props: {
    panel: String
  },
  data() {
    return {
      debug: true,
      // map data
      zoom: 10,
      center: [46.85737385116763, -92.28858947753905],
      // should keep the map centered on the study area
      maxBounds: [
        [45.436, -95.771],
        [49.049, -89.306]
      ],
      maxZoom: 13,
      minZoom: 9,

      // will be loaded by foRasterLayer.js
      geoRasterLayers: [],

      // manage selected layer
      selectedLayer: null,
      // when scenario is updated and triggers a watch,
      // was it the layer that changed or the scenario?
      isLayerChange: true,

      //
      // geojson layer data
      //
      // mammal data
      mammalConfig: mapData.mammalConfig,
      mammalScenario: null,
      // bird data
      birdConfig: mapData.birdConfig,
      birdScenario: null,
      // water data
      waterConfig: mapData.waterConfig,
      waterScenario: null,

      // aoi data
      aoiStyle: aoiStyles.display,

      // map loading listeners for loading spinner
      isMapReady: false,
      layersReady: true,
      mapCentered: true,

      // layer opacity updated from map tools
      layerOpacity: 0
    }
  },

  computed: {
    // scenario state from store
    scenario: function () {
      return theStore.store.getScenarioAndLayer()
    },
    themap: function () {
      return this.$refs.themap.leafletObject
    },
    bounds: function () {
      return theStore.store.state.aoi
    },
    mapLayer: function () {
      return theStore.store.state.mapLayer
    },
    basemap: function () {
      return theStore.store.state.basemap
    },
    townshipBlocks: function () {
      return theStore.store.state.townshipBlocks
    },
    subcatchments: function () {
      return theStore.store.state.subcatchments
    },
    isAoiSelected() {
      return theStore.store.isAoiSelected()
    },
    isScenarioSelected() {
      return theStore.store.isScenarioSelected()
    },
    isLayerSelected() {
      return theStore.store.isLayerSelected()
    },
    // is the current layer a raster layer
    isRaster() {
      if (this.scenario.layerCat) {
        return ['l', 'c'].includes(this.scenario.layerCat.value)
      } else {
        return false
      }
    },
    // layer opacity updated from map tools
    layerOpacityFromStore() {
      return theStore.store.getLayerOpacity()
    },

    // message displayed with spinner
    spinnerMessage() {
      return !this.layersReady
        ? 'Gathering 80 years of data....'
        : 'Map Loading....'
    },

    // loading spinner - show spinner until:
    //   - map is ready
    //   - map has finished updating center
    //   - number of geojson layers ready equals number of geojson layers
    showSpinner() {
      this.log(
        'show spinner',
        this.isMapReady,
        this.mapCentered,
        this.layersReady
      )
      return !this.isMapReady || !this.mapCentered || !this.layersReady
    }
  },

  watch: {
    // watch for a layer change
    // add new layer to map
    scenario: {
      deep: true,
      handler: function (newScenario, oldScenario) {
        this.log('scenario watch')
        // exit early if component isn't selected yet
        // also check if watch is getting triggered without scenario actually changing
        if (
          !newScenario.layer.value ||
          JSON.stringify(newScenario) == JSON.stringify(oldScenario)
        ) {
          return false
        }

        // check for layer change
        this.isLayerChange = newScenario.layer.value != oldScenario.layer.value
        this.log(
          'isLayerChange',
          this.isLayerChange,
          newScenario.layer.value,
          oldScenario.layer.value
        )
        this.addScenarioLayer(newScenario)
      }
    },

    // layer opacity watching for updates from store should update this.layerOpacity
    layerOpacityFromStore: {
      deep: true,
      handler: function (newLO, oldLO) {
        this.log('layer opacity watcher', oldLO, newLO)
        console.log(
          'layerOpacityFromStore, Map.vue. oldLO: ',
          oldLO,
          'newLO: ',
          newLO
        )
        this.layerOpacity = newLO
        if (this.selectedLayer) {
          this.updateGeorasterLayerOpacity()
        }
      }
    },

    // watch panel prop for changes
    panel(currPanel, prevPanel) {
      this.log('watch panel', prevPanel, currPanel)
      if (currPanel == 'charts') {
        // get height of chart pane
        const slidePanel = document.getElementsByClassName('slide-panel')[0]
        // slide panel didn't exist when loading from URL params so use 300 as default
        const chartHeight = slidePanel ? slidePanel.offsetHeight : 250
        // pan the map up by the height of the chart pane
        // and divide it by four (whole value was too much)
        // panBy method takes an L.point
        this.themap.panBy([0, chartHeight / 4])
      }
      // if it's not charts just center the map
      else {
        this.themap.fitBounds(this.bounds, { padding: [20, 20] })
      }
    }
  },

  async beforeMount() {
    // console.log('beforeMount')
  },

  async mounted() {
    this.log('map mounted')
  },

  created() {
    this.log('map created')
    this.reloadLayer()
    // set initial layer opacity from store
    this.layerOpacity = this.layerOpacityFromStore
    console.log('Map.vue created this.layerOpacity: ', this.layerOpacity)
  },

  methods: {
    // shorthand for console logging
    log(...message) {
      if (this.debug) {
        console.log('[main]', ...message)
      }
    },

    // load map layer based on scenario
    addScenarioLayer(newScenario) {
      this.log('watch scenario', JSON.stringify(newScenario))

      // reset selectedLayer
      this.selectedLayer = null
      // reset layers ready
      this.layersReady = false

      /*
        route the process to the correct layer handler
        based on the type of scenario selected
        l    = Landscape
        c    = Carbon
        m    = Mammals
        b    = Birds
        w    = Water
        e    = Economics
      */
      switch (newScenario.layerCat.value) {
        //  forest or landscape
        case 'l':
          this.addSelectedGeorasterToMap(newScenario)
          break
        //  carbon
        case 'c':
          this.addSelectedGeorasterToMap(newScenario, true)
          break
        // mammals
        case 'm':
          // send new scenario to mammal layers
          this.mammalScenario = newScenario
          break
        // birds
        case 'b':
          // send new scenario to bird layers
          this.birdScenario = newScenario
          break
        // water
        case 'w':
          // send new scenario to water layers
          this.waterScenario = newScenario
          break
        // economic
        case 'e':
          // send new scenario to water layers
          this.getEconData()
          break
      }
    },

    async mapReady() {
      this.log('map ready')
      this.isMapReady = true
    },

    async beforeMapMount() {
      // console.log('beforeMapMount')
      // import georaster requirements
      // eslint-disable-next-line no-undef
    },

    async aoiReady() {
      // center map on AOI
      this.themap.fitBounds(this.bounds, { padding: [20, 20] })
      this.log('aoi ready')
    },

    // geojson layer change handler
    onGeojsonLayerChange(layer) {
      // update selected layer
      this.selectedLayer = layer
      this.log('onGeojsonLayerChange', layer)

      // update layer values in store for charting
      const layerData = layer.waterData
        ? layer.waterData
        : vectorUtils.geojsonValuesAllYears(layer.geojson)
      theStore.store.updateAction('layerData', layerData)

      // update reference values for given layer
      theStore.store.updateAction('refData', layer.refValues)

      this.layersReady = true
      this.$emit('layerChange')
    },

    // creates geoRasterLayer - should only have to do this once
    async createGeoRasterLayer(carbon = false) {
      const scenario = theStore.store.getScenarioAndLayer()
      // 2.1. create instance of foRasterLayer
      // bounds is a nested array of Leaflet LatLngs
      let rastLayer = foRasterLayer(
        scenario,
        this.bounds[0],
        this.layerOpacity,
        carbon
      )
      // 2.2. generate geoRasterLayer
      try {
        await rastLayer.createGeoRasterLayer()
      } catch (error) {
        console.error('addSelectedGeorasterToMap', error)
      }
      // 2.3. register it with foMap
      this.geoRasterLayers.push(rastLayer)
      return rastLayer
    },

    // add selected geoRasterLayer to map
    async addSelectedGeorasterToMap(scenario, carbon = false) {
      this.log('addSelectedGeorasterToMap', JSON.stringify(scenario))
      // 1. check if foRasterLayer is already registered
      let rastLayer = this.geoRasterLayers.find(
        lyr => JSON.stringify(lyr.scenario) == JSON.stringify(scenario)
      )
      this.log(
        'rastLayer',
        JSON.stringify(scenario.layer),
        //JSON.stringify(rastLayer)
        rastLayer
      )

      // 2. if layer doesn't exist, create it
      rastLayer = rastLayer
        ? rastLayer
        : await this.createGeoRasterLayer(carbon)

      // 2.1 update selected layer
      this.selectedLayer = rastLayer

      // 3.1. update layer data in store
      theStore.store.updateAction('layerData', rastLayer.allYears)
      // 3.2. update reference values for given layer
      theStore.store.updateAction('refData', rastLayer.refValues)

      // 4. remove existing layers
      this.$refs.rasterLayers.leafletObject.clearLayers()

      // 5. load layer
      this.$refs.rasterLayers.leafletObject.addLayer(rastLayer.geoRasterLayer)
      this.$refs.rasterLayers.leafletObject.bringToFront()
      // for some reason when loading map from url params
      // raster layers didn't show up until the opacity was set
      // so just force this through
      rastLayer.geoRasterLayer.setOpacity(this.layerOpacity)
      // 6. zoom to bounds, if it was a layer change
      // just use aoi, because they are essentially the same
      if (this.isLayerChange) {
        this.themap.fitBounds(this.bounds, { padding: [20, 20] })
      }
      this.layersReady = true

      // 7. emit layer change event after layer is loaded into map
      this.$emit('layerChange')
    },

    updateGeorasterLayerOpacity() {
      // get raster layers
      let rastLayers = this.$refs.rasterLayers.leafletObject.getLayers()
      // if no raster layers exit early
      if (!rastLayers.length) return false

      // get raster layer
      let lyr = rastLayers[0]
      // set layer opacity
      lyr.setOpacity(this.layerOpacity)
    },

    mapViewreset(event) {
      this.log('viewreset', event)
      this.mapCentered = false
    },
    mapUpdateCenter(event) {
      this.log('UpdateCenter', event)
      this.mapCentered = true
    },
    //disable the map scroll if someone is inside the legend
    //re-enable when they mouse back out
    focusScroll() {
      this.themap.scrollWheelZoom.disable()
    },
    focusMap() {
      this.themap.scrollWheelZoom.enable()
    },
    // reload layer when switching back to map
    reloadLayer() {
      this.log('check if layer should be reloaded')
      // this.log('check if layer should be reloaded', this.aoiUpdated)
      // exit early if there is no scenario or layer yet or if aoi hasn't been updated
      if (
        // !this.aoiUpdated ||
        !this.isLayerSelected ||
        !this.isScenarioSelected
      ) {
        this.log('no scenario or no layer or no aoi update')
        return false
      }
      this.log('yes, we have scenario and layer, load it')
      // re-add data layer using new bounds
      this.addScenarioLayer(this.scenario)
    },

    // calculate economic data on the server and return it to be rendered in charts
    async getEconData() {
      const econdata = await econUtils.getEconData(
        mapData.econConfig.sqlview, // sqlview
        theStore.store.getAOI(), // aoi
        theStore.store.getScenario() // scenario
      )
      // store the data
      theStore.store.updateAction('layerData', econdata)
      // open charts panel straight away
      this.$parent.toggleSlidePanel('charts')
      // set layersReady (so spinner stops)
      this.layersReady = true
      // tell Tool.vue that layer has been selected
      this.$emit('layerChange')
    }
  }
}
</script>

<style scoped>
.geojson-labels {
  color: #79004f;
  background: none;
  font-family: sans-serif;
  font-weight: 501;
  font-size: 12px;
  border: none;
  box-shadow: none;
  width: 84px;
  white-space: normal;
  line-height: 16px;
}
</style>
