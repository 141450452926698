<template>
  <footer
    class="footer column is-narrow has-background-dark has-text-white py-5 is-size-7"
  >
    <div class="container">
      <div class="columns px-5">
        <div class="column is-half">
          <h3 class="is-size-5">Natural Resources Research Institute</h3>
          <p class="mb-3">
            <a href="mailto:nrriinfo@d.umn.edu">nrriinfo@d.umn.edu</a><br />
            (800) 234-0054<br />
            (218) 788-2694<br />
            5013 Miller Trunk Highway<br />
            Duluth, MN 55811
          </p>
          <p>
            ©{{ new Date().getFullYear() }} Regents of the University of
            Minnesota. All rights reserved. The University of Minnesota is an
            equal opportunity educator and employer.
          </p>
          <p>
            <a href="https://privacy.umn.edu/" target="_blank" rel="noopener"
              >Privacy Statement</a
            >
          </p>
        </div>

        <div class="column is-half">
          <div>
            <h3 class="is-size-5">About the Project</h3>
            <img
              src="../assets/images/enrtf_logo_reversed.svg"
              width="150"
              class="is-pulled-left mr-3"
              alt="Environment and Natural Resources Trust Fund"
            />
            <p class="is-size-6-minus">
              Funding for this project was provided by the Minnesota Environment
              and Natural Resources Trust Fund as recommended by the
              Legislative-Citizen Commission on Minnesota Resources (LCCMR).
              <router-link to="/funding">Read more.</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style></style>
