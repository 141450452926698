<template>
  <div class="block">
    <!-- layer opacity slider -->
    <div class="label">Alter the map layer opacity</div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              class="slider is-fullwidth is-medium is-circle is-primary"
              name="opacitySlider"
              step="1"
              min="0"
              max="100"
              type="range"
              v-model="sliderValue"
              @input="sliderUpdate"
            />
          </div>
        </div>
      </div>
      <div class="field-label is-normal pt-4">
        <label class="label" for="opacitySlider">{{ sliderValue }}%</label>
      </div>
    </div>
  </div>
</template>

<script>
// scenario store
import theStore from '../store/store.js'

export default {
  created() {
    // get initial opacity value from store
    this.sliderValue = theStore.store.state.layerOpacity * 100
  },
  data() {
    return {
      sliderValue: null
    }
  },
  methods: {
    sliderUpdate() {
      theStore.store.updateAction('layerOpacity', this.sliderValue / 100)
    }
  }
}
</script>

<style scoped>
/* https://www.cssportal.com/style-input-range/ */
input[type='range'] {
  height: 23px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: none;
  background: #7a0019;
  border-radius: 5px;
  border: none;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: none;
  border: 1px solid #696969;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.5px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #7a0019;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: none;
  background: #7a0019;
  border-radius: 5px;
  border: none;
}
input[type='range']::-moz-range-thumb {
  box-shadow: none;
  border: 1px solid #696969;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #7a0019;
  border: none;
  border-radius: 10px;
  box-shadow: none;
}
input[type='range']::-ms-fill-upper {
  background: #7a0019;
  border: none;
  border-radius: 10px;
  box-shadow: none;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: none;
  border: 1px solid #696969;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: #7a0019;
}
input[type='range']:focus::-ms-fill-upper {
  background: #7a0019;
}
</style>
