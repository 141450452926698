<template>
  <header class="column is-narrow p-0">
    <nav
      class="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="container">
        <div class="navbar-brand">
          <router-link
            class="navbar-item is-size-4 has-text-weight-semibold"
            to="/"
          >
            <img
              src="../assets/images/umn_block_m.svg"
              width="50"
              height="50"
              class="d-inline-block align-top mr-4"
              alt="University of Minnesota block M logo"
            />
            <span class="organization is-size-7"
              >Natural Resources Research Institute</span
            >
            <h1 v-if="$route.name == 'Tool'" class="identity">
              ForCAST
              <span class="tagline is-size-6 is-family-primary">
                Forest Change Assessment Simulation Tool
              </span>
            </h1>

            <div v-if="$route.name != 'Tool'" class="identity">
              ForCAST
              <span class="tagline is-size-6 is-family-primary">
                Forest Change Assessment Simulation Tool
              </span>
            </div>
          </router-link>

          <a
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            :class="{ 'is-active': isActive }"
            @click="isActive = !isActive"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div class="navbar-menu" :class="{ 'is-active': isActive }">
          <div class="navbar-end">
            <router-link to="/" class="navbar-item">Home</router-link>

            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link" href="#">About</a>

              <div class="navbar-dropdown">
                <router-link
                  to="/project-overview"
                  class="navbar-item"
                  @click="removeFocus()"
                >
                  Project Overview
                </router-link>
                <router-link
                  to="/people"
                  class="navbar-item"
                  @click="removeFocus()"
                  >People</router-link
                >
                <router-link
                  to="/funding"
                  class="navbar-item"
                  @click="removeFocus()"
                  >Funding</router-link
                >
                <hr class="navbar-divider" />
                <router-link
                  to="/contact"
                  class="navbar-item"
                  @click="removeFocus()"
                  >Contact</router-link
                >
              </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link" href="#">Methods</a>

              <div class="navbar-dropdown">
                <router-link
                  to="/landscape-modeling"
                  class="navbar-item"
                  @click="removeFocus()"
                  >Landscape Modeling</router-link
                >
                <router-link
                  to="/wildlife-modeling"
                  class="navbar-item"
                  @click="removeFocus()"
                  >Habitat Modeling</router-link
                >
                <router-link
                  to="/water-modeling"
                  class="navbar-item"
                  @click="removeFocus()"
                  >Water Modeling</router-link
                >
                <router-link
                  to="/ecosystem-valuation"
                  class="navbar-item"
                  @click="removeFocus()"
                  >Ecosystem Services Valuation</router-link
                >
              </div>
            </div>

            <!-- help dropdown -->
            <div
              class="navbar-item has-dropdown is-hoverable"
              id="helpDropdown"
            >
              <a class="navbar-link" href="#">Help</a>

              <div class="navbar-dropdown">
                <!-- show tour button -->
                <a
                  class="navbar-item"
                  v-if="$route.name == 'Tool'"
                  @click="this.$tours['toolTour'].start()"
                  >Tool Introduction</a
                >
                <router-link
                  to="/help"
                  class="navbar-item"
                  @click="removeFocus()"
                  >FAQ</router-link
                >
              </div>
            </div>

            <div class="buttons ml-3">
              <router-link to="/tool"
                ><button class="button is-dark" tabindex="0">
                  <span class="icon"
                    ><fa :icon="['fa', 'map-marked-alt']" /></span
                  ><span>ForCAST Tool</span>
                </button></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    //this allows drop-down menus to close after an item has been selected
    removeFocus() {
      document.activeElement.blur()
    }
  }
}
</script>
<style scoped>
.tool .navbar .container {
  max-width: 95%;
}
.navbar-brand {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
.organization {
  position: absolute;
  top: 6px;
  left: 78px;
  color: #aaa;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
.identity {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  line-height: 0.65em;
  margin-top: 16px;
}
.button.is-dark {
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 1024px) {
  /* .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block !important;
  }
  .navbar-item.is-hoverable:focus-within .navbar-dropdown {
    display: none;
  } */
}
</style>
