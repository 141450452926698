<template>
  <div class="container p-4">
    <aside class="menu">
      <ul class="accordion menu-list">
        <li v-for="category in categories" :key="category.name">
          <a
            href="#"
            class="has-submenu pl-2"
            :class="setActiveParentClass(category, currentLayerCat)"
            @click="toggleSubMenu($event)"
            >{{ category.title }}
            <fa :icon="['fa', 'chevron-down']" class="is-pulled-right mr-2"
          /></a>

          <ul class="submenu">
            <li v-for="layer in category.layers" :key="layer.value">
              <a
                v-if="notBreak(layer.name)"
                :class="setActiveItemClass(layer, currentLayer)"
                @mouseleave="onLayerMouseLeave"
                @click="onLayerClick(category, layer), onLayerMouseEnter(layer)"
                >{{ layer.name }}
              </a>
              <hr v-else />
            </li>
          </ul>
        </li>
      </ul>
    </aside>
    <div class="block has-text-info mt-4 mb-2" v-if="selectedLayer">
      <span class="has-text-weight-semibold">{{ selectedLayer.name }}</span>
      layer was selected. Save this selection?
    </div>
    <fieldset :disabled="selectedLayer == null">
      <div class="field is-grouped mt-2">
        <div class="control">
          <button class="button is-link" @click="onSave">Save</button>
        </div>
        <div class="control">
          <button class="button is-light" @click="onCancel">Cancel</button>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
// scenario store
import theStore from '../store/store.js'

// scenario data
import scenarioData from '../data/scenarioData.js'

// layer info
// import layerInfo from '../data/componentInfo.json'

export default {
  computed: {
    currentLayer() {
      return theStore.store.state.layer.name
    },
    currentLayerCat() {
      return theStore.store.state.layerCat.name
    }
  },
  methods: {
    // when layer is saved, update value in store
    // and close panel
    toggleSubMenu(item) {
      item.currentTarget.classList.toggle('is-open')
    },
    setActiveParentClass(category, current) {
      if (category.name == current) {
        return 'is-open'
      }
    },
    setActiveItemClass(layer, current) {
      if (
        this.selectedLayer == layer ||
        (layer.name == current && this.selectedLayer == null)
      ) {
        return 'is-active'
      }
    },
    onSave: function () {
      theStore.store.updateAction('layer', this.selectedLayer)
      theStore.store.updateAction('layerCat', this.selectedLayerCategory)
      // send results to Google Tag Manager
      this.$gtm.trackEvent({
        event: 'Form Submission',
        category: 'Model-Output-Selected',
        label: this.selectedLayerCategory,
        value: this.selectedLayer.name
      })
      this.selectedLayer = null
      let infoData = { data: { title: '', body: '' }, toggle: false }

      this.$emit('onLayerMouseLeave', infoData)
      //this.$parent.$emit('closePanelClick', 'layers')
    },

    // cancel just closes the panel
    onCancel: function () {
      this.$parent.$emit('closePanelClick', 'layers')
    },

    // go grab the text file for the layer in question
    getInfoForLayer(layer) {
      try {
        return require(`@/content/${layer.value}.txt`).default
      } catch (err) {
        let noInfo = 'No additional information is available'
        return noInfo
      }
    },

    // hover handlers
    // send layer info to panel on mouse enter
    onLayerMouseEnter: function (layer) {
      // get info from layerInfo json
      // const info = layerInfo[layer.value]
      let infoTitle = layer.name
      let infoContent = this.getInfoForLayer(layer)
      if (this.debug) {
        console.log('infoTitle: ', infoTitle)
        console.log('infoContent', infoContent)
      }
      let infoData = { title: infoTitle, body: infoContent }
      this.$emit('onLayerMouseEnter', { data: infoData, toggle: true })
    },
    // reset panel on mouse leave
    // onLayerMouseLeave: function () {
    //   let infoData = { data: { title: '', body: '' }, toggle: false }
    //   this.$emit('onLayerMouseLeave', infoData)
    // },

    // handle when a layer is clicked in the menu
    // should set selected layer and selected category
    // category needs component data (see `scenarioData.js`)
    // but not all of the layer data (that is store by selectedLayer)
    onLayerClick(category, layer) {
      this.selectedLayer = layer
      // copy category without layers
      // eslint-disable-next-line no-unused-vars
      const { layers, ...everythingElse } = category
      this.selectedLayerCategory = everythingElse
    },
    notBreak(value) {
      let result = true
      if (value === 'break') {
        result = false
      }
      return result
    }
  },
  emits: ['onLayerMouseEnter', 'onLayerMouseLeave'],
  //emits: ['onLayerMouseEnter'],
  data() {
    return {
      debug: false,
      selectedLayer: null,
      selectedLayerCategory: null,

      // layer data
      categories: scenarioData.components
    }
  }
}
</script>

<style scoped>
.has-text-info {
  max-width: 260px;
}
.menu {
  overflow-y: auto;
}
hr {
  margin: 0.5rem 0;
}
</style>
