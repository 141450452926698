/* 
  econdata.js

  request economic value data fromm postgres (via Geoserver)
  and return it to app (to be charted)
*/

// supporting vector functions
import vectorUtils from './foVectorLayers.js'

// utility functions
import utilities from '../modules/utilities.js'

const econdata = {
  // format array of ids and return it as a string with commas escaped
  formatIDsForSQLView(ids) {
    return ids.toString().replace(/,/g, '\\,')
  },

  // generate viewparam WFS param
  // ref: https://docs.geoserver.org/master/en/user/data/database/sqlview.html
  setViewParams(aoiData, scenario, year) {
    // get bounds from aoi data
    const bounds = aoiData.aoi[0]

    let viewparams = ''

    // set view params based on scenario
    viewparams += `year:${year};`
    viewparams += `climate:${scenario.climate.value};`
    viewparams += `forestMgmt:${scenario.forestMgmt.value};`
    // set view params for AOI
    // bounds
    viewparams += `minx:${bounds[0].lng};`
    viewparams += `miny:${bounds[0].lat};`
    viewparams += `maxx:${bounds[2].lng};`
    viewparams += `maxy:${bounds[2].lat};`
    // subcatchments
    viewparams += `subcatchment_ids:${this.formatIDsForSQLView(
      aoiData.subcatchments
    )};`
    // townshipBlocks
    viewparams += `tb_ids:${this.formatIDsForSQLView(aoiData.townshipBlocks)};`

    return viewparams
  },

  // get econ data from postgres sqlview
  async getDataFromServer(sqlview, aoiData, scenario, year) {
    // generate view params
    const viewparams = this.setViewParams(aoiData, scenario, year)
    // generate geoserver URL
    const url = vectorUtils.generateWFSURL(sqlview, viewparams)
    // get data from geoserver
    const response = await fetch(url)
    // set leaflet geojson value from geoserver response
    const data = await response.json()
    // grab feature properties
    const econdata = data.features[0].properties
    // add sum of all values
    const sum = Object.values(econdata).reduce((a, b) => a + b, 0)
    econdata['sum'] = sum
    // return data back to map
    // console.log('econdata', econdata)
    return econdata
  },

  // primary function called from Map.vue to gather and assemble econ data
  async getEconData(sqlview, aoiData, scenario) {
    // get range of years
    const years = utilities.range(2020, 2100, 10)

    // iterate over years and get econ data for each year
    // use Promise.all to wait for all the requests to resolve before moving on
    // ref: https://stackoverflow.com/a/59471024/6072959
    const econData = years.map(async yr => {
      return {
        year: yr,
        values: await this.getDataFromServer(sqlview, aoiData, scenario, yr)
      }
    })
    return await Promise.all(econData)
  }
}

export default econdata
