<template>
  <Head>
    <title>ForCAST Methods: Ecosystem Services Valuation</title>
    <meta name="description" content="" />
  </Head>
  <section class="section">
    <div class="container general-content">
      <h1 class="title">Ecosystem Services Valuation</h1>
      <h2>Background</h2>
      <p>
        Forest provides ecosystem services that enable and enrich human life.
        The range of ecosystem services includes clean air and water to timber
        products. Human actions have a great impact not only on the quality of
        the forest but also the ecosystem services provided by a forest. Forest
        management practices have a significant implication for the forest
        ecosystem (Duncker et al., 2012). The most direct product provided to
        humans from forests is woody biomass or timber. Minnesota&rsquo;s annual
        wood production was 3.1 million cords in 2017. Apart from wood
        production, removing and storing carbon is the second most vital
        ecosystem service provided by forest. The forest management can
        influence carbon sequestration in forested landscapes (Bravo et al.,
        2017; Jandl et al., 2007; Ontl et al., 2020; Profft, Mund, Weber,
        Weller, &amp; Schulze, 2009). Forest management can impact habitat
        suitability at landscape-scale (Shifley, Thompson, Dijak, &amp; Fan,
        2008; Shifley, Thompson, Dijak, Larson, &amp; Millspaugh, 2006; Wu, Li,
        Hu, Xiu, &amp; Yan, 2018). Forest performs an important duty of water
        regulation. Forest management has an impact on water quality (Abildtrup,
        Garcia, &amp; Stenger, 2013), water quantity (Filoso, Bezerra, Weiss,
        &amp; Palmer, 2017), water-based recreation, probability of flood
        (Kelly, McGuire, Miniat, &amp; Vose, 2016), and soil erosion (Hartanto,
        Prabhu, Widayat, &amp; Asdak, 2003). Forest management can also
        influence aesthetic amenities (Panagopoulos, 2009) and cultural
        subsistence (Pohjanmies, Triviño, Le Tortorec, Salminen, &amp;
        Mönkkönen, 2017).
      </p>
      <p>
        Often forest management is intended for a single purpose. In order to
        get an integrated assessment of forest management, both market and
        non-market ecosystem services need to be considered. The objective of
        this project is to find values of ecosystem goods and services provided
        by forest resources and compare different forest management plans
        integrating all these non-market ecosystem service values into decision
        making. There are many ways of estimating the value of goods that are
        not traded in the market. One prominent way economists often use in
        choice experiments is where a good (in our case forest management plan)
        is divided into several attributes (in our case ecosystem services) and
        then the value of each attribute is calculated.
      </p>
      <h2>Choice Experiment Theory</h2>
      <p>
        Lancaster&rsquo;s modern consumer theory and random utility-maximizing
        theory are the underlying theory of discrete choice experiment.
        Lancaster (1966) states that the good itself does not provide utility,
        rather the characteristics of the good rise in utility. This theory
        allows one to decompose a good into several attributes and obtain the
        value of each attribute. For example, forest resources have several
        attributes such as timber production and carbon storage, among others.
        According to Lancaster (1966), value of the good (forest resources) is
        the sum of values of all the attributes (timber production, carbon
        storage and so on).
      </p>
      <p>
        Random utility maximizing (RUM) is a variant of the utility-maximizing
        theory of economics. It states that individual rational agents choose a
        good whose overall characteristics raises the utility to the maximum and
        the variation of individual choice can be captured through random
        factors.
      </p>
      <h2>Overview of Survey</h2>
      <p>
        The survey was conducted in 11 counties in northern Minnesota: St.
        Louis, Lake, Cook, Carlton, Pine, Chisago, Isanti, Kanabec, Aitkin,
        Millie Lacs and Itasca. The selected 11 counties represent roughly 9% of
        the total population of Minnesota (US Census Bureau 2018).
      </p>
      <figure class="image">
        <img
          src="../assets/images/econ_fig_1.jpg"
          alt="Map of Minnesota with 11 counties mentioned above labeled and the extent of the ForCAST watersheds shaded"
        />
        <figcaption>
          <strong>Figure 1.</strong>
          Eleven counties of the economic survey and the ForCAST tool project
          watersheds.
        </figcaption>
      </figure>

      <p>
        The total population of the selected 11 counties is 477,099 (US Census
        Bureau, 2018). The required sample size is 384 with a 95% confidence
        level and 5% margin of error. The response rate of surveys vary greatly.
        Assuming an anticipated response rate of around 5%, we purchased a
        clustered random contact list of 8011 from a renowned third party market
        research company who ensures its quality.
      </p>
      <p>
        This survey is designed to answer specific questions regarding Minnesota
        residents&rsquo; willingness to pay (WTP) for ecosystem services
        provided by forest resources in Minnesota. The forest resources provide
        various ecosystem services including wood products, carbon storage,
        water regulation, aesthetic and cultural values, recreation and so on.
        The goal of the analysis is to reduce the number of attributes to a
        manageable number considering what can be modeled and have relevance to
        forest management in Minnesota. We have conducted qualitative analysis,
        such as expert opinion and focused group discussions, to develop the
        survey questionnaire and list of attributes. Figure 2 represents a
        sample choice experiment question.
      </p>
      <figure class="image">
        <img
          src="../assets/images/econ_fig_2.png"
          alt="Sample of survey with six scenarios and three choices for each.  Carbon sequestration, plan 1: 20% decrease, plan 2: 20% increase, plan 3: no change.  Water quality plan 1: 20% decrease, plan 2: no change, plan 3: 20% increase. Game species habitat plan 1:20% decrease, plan 2: 10% decrease, plan 3: 10% increase. Non-game species habitat plan 1: no change, plan 2: 20% decrease, plan 3: 20% increase. Your monthly contribution plan 1: $80, plan 2: $5, plan 3: $30"
        />
        <figcaption>
          <strong>Figure 2.</strong>
          Sample choice experiment question
        </figcaption>
      </figure>

      <h2>Results and interpretations</h2>
      <p>
        The primary result of the analysis is households willingness to pay for
        each ecosystem service included in the survey. In our modeling approach,
        we assume that there is a non-linear relationship between households'
        willingness to pay with the level of ecosystem services. We used robust
        and several different modeling approaches and our final valuation of
        ecosystem services are in Table 1.
      </p>

      <table class="table">
        <caption>
          <strong>Table 1.</strong>
          Willingness to pay for different forest ecosystem services
        </caption>
        <thead>
          <tr>
            <th>Ecosystem services by level</th>
            <th>$/household/month</th>
          </tr>
        </thead>
        <tr>
          <td>Timber Production increase 10%</td>
          <td>7.49</td>
        </tr>
        <tr>
          <td>Timber Production increase 50%</td>
          <td>0.49</td>
        </tr>
        <tr>
          <td>Timber Production decrease 10%</td>
          <td>-9.58</td>
        </tr>
        <tr>
          <td>Timber Production decrease 50%</td>
          <td>-33.17</td>
        </tr>
        <tr>
          <td>Carbon sequestrations increase 20%</td>
          <td>18.92</td>
        </tr>
        <tr>
          <td>Carbon sequestrations increase 50%</td>
          <td>21.63</td>
        </tr>
        <tr>
          <td>Carbon sequestrations decrease 20%</td>
          <td>-27.30</td>
        </tr>
        <tr>
          <td>Carbon sequestrations decrease 50%</td>
          <td>-37.91</td>
        </tr>
        <tr>
          <td>Water quality increase 50%</td>
          <td>21.84</td>
        </tr>
        <tr>
          <td>Water quality increase 20%</td>
          <td>21.62</td>
        </tr>
        <tr>
          <td>Water quality decrease 20%</td>
          <td>-60.11</td>
        </tr>
        <tr>
          <td>Water quality decrease 50%</td>
          <td>-107.61</td>
        </tr>
        <tr>
          <td>Game species habitat quality increase 10%</td>
          <td>1.95</td>
        </tr>
        <tr>
          <td>Game species habitat quality increase 20%</td>
          <td>3.71</td>
        </tr>
        <tr>
          <td>Game species habitat quality decrease 10%</td>
          <td>-16.57</td>
        </tr>
        <tr>
          <td>Game species habitat quality decrease 20%</td>
          <td>-30.44</td>
        </tr>
        <tr>
          <td>Nongame species habitat quality increase 10%</td>
          <td>1.15</td>
        </tr>
        <tr>
          <td>Nongame species habitat quality increase 20%</td>
          <td>-1.74</td>
        </tr>
        <tr>
          <td>Nongame species habitat quality decrease 10%</td>
          <td>-13.33</td>
        </tr>
        <tr>
          <td>Nongame species habitat quality decrease 20%</td>
          <td>-24.39</td>
        </tr>
      </table>

      <p>
        Our results show that households are willing to pay $7.5/month if the
        forest management yields an increase of timber production by 10%.
        However, there is a significant fall in household willingness to pay
        when timber production increases by 50%. That is consistent with the
        decreasing marginal utility of timber. The result presented in Table 1
        can be used in analyzing a forest management plan (policy scenario) with
        combination modeling results for each of these ecosystem services. Table
        2 shows two policy scenarios and compares their net economic value
        considering all the ecosystem services impacted.
      </p>

      <table class="table">
        <caption>
          <strong>Table 2. </strong>
          Forest management plan comparison using all ecosystem service values
        </caption>
        <thead>
          <tr>
            <th>Climate description</th>
            <th>Harvest description</th>
            <th>Carbon change</th>
            <th>Timber change</th>
            <th>Game change</th>
            <th>non_game change</th>
            <th>WQ change</th>
            <th>economic value in $/ household /month</th>
            <th>number of households in 11 counties</th>
            <th>yearly values</th>
          </tr>
        </thead>
        <tr>
          <td>Scenario A: High climate change</td>
          <td>Decrease probability by 30%</td>
          <td>-54.15%</td>
          <td>-45.29%</td>
          <td>4.92%</td>
          <td>7.91%</td>
          <td>-14.40%</td>
          <td>-111.19</td>
          <td>199,087</td>
          <td>$265 million</td>
        </tr>
        <tr>
          <td>Scenario B: Current Climate</td>
          <td>Decrease probability by 30%</td>
          <td>44.77%</td>
          <td>-38.75%</td>
          <td>3.75%</td>
          <td>4.79%</td>
          <td>3.25%</td>
          <td>-0.55</td>
          <td>199,087</td>
          <td>$1.3 million</td>
        </tr>
      </table>

      <p>
        We showed two forest management plans in Table 2. Scenario A is subject
        to high climate change and a decrease in probability of timber harvest
        by 30% that leads to respective changes in ecosystem services in that
        row. Notice that the probability decrease of timber harvest by 30% leads
        to an actual decrease of 45% in harvestable timber due to modeling
        uncertainty. Overall, Scenario A has a negative yearly value of $265
        million where Scenario B has a negative yearly value of $1.3 million. A
        manager will more likely choose Scenario B compared with Scenario A.
      </p>
      <h2>References</h2>
      <p>
        Abildtrup, J., Garcia, S., &amp; Stenger, A. (2013). The effect of
        forest land use on the cost of drinking water supply: A spatial
        econometric analysis. Ecological Economics, 92, 126–136.
        <a href="https://doi.org/10.1016/j.ecolecon.2013.01.004"
          >https://doi.org/10.1016/j.ecolecon.2013.01.004</a
        >
      </p>
      <p>
        Bravo, F., del Río, M., Bravo-Oviedo, A., Ruiz-Peinado, R., del Peso,
        C., &amp; Montero, G. (2017). Forest Carbon Sequestration: The Impact of
        Forest Management. In Managing Forest Ecosystems: The Challenge of
        Climate Change (pp. 251–275).
        <a href="https://doi.org/10.1007/978-3-319-28250-3_13"
          >https://doi.org/10.1007/978-3-319-28250-3_13</a
        >
      </p>
      <p>
        Duncker, P. S., Raulund-Rasmussen, K., Gundersen, P., Katzensteiner, K.,
        De Jong, J., Ravn, H. P., … Spiecker, H. (2012). How forest management
        affects ecosystem services, including timber production and economic
        return: Synergies and trade-offs. Ecology and Society, 17(4).
        <a href="https://doi.org/10.5751/ES-05066-170450"
          >https://doi.org/10.5751/ES-05066-170450</a
        >
      </p>
      <p>
        Filoso, S., Bezerra, M. O., Weiss, K. C. B., &amp; Palmer, M. A. (2017).
        Impacts of forest restoration on water yield: A systematic review. PLOS
        ONE, 12(8), e0183210.
        <a href="https://doi.org/10.1371/journal.pone.0183210"
          >https://doi.org/10.1371/journal.pone.0183210</a
        >
      </p>
      <p>
        Hartanto, H., Prabhu, R., Widayat, A. S. E., &amp; Asdak, C. (2003).
        Factors affecting runoff and soil erosion: Plot-level soil loss
        monitoring for assessing sustainability of forest management. Forest
        Ecology and Management, 180(1–3), 361–374.
        <a href="https://doi.org/10.1016/S0378-1127(02)00656-4"
          >https://doi.org/10.1016/S0378-1127(02)00656-4</a
        >
      </p>
      <p>
        Jandl, R., Lindner, M., Vesterdal, L., Bauwens, B., Baritz, R.,
        Hagedorn, F., … Byrne, K. A. (2007). How strongly can forest management
        influence soil carbon sequestration? Geoderma, 137, 253–268.
        <a href="https://doi.org/10.1016/j.geoderma.2006.09.003"
          >https://doi.org/10.1016/j.geoderma.2006.09.003</a
        >
      </p>
      <p>
        Kelly, C. N., McGuire, K. J., Miniat, C. F., &amp; Vose, J. M. (2016).
        Streamflow response to increasing precipitation extremes altered by
        forest management. Geophysical Research Letters, 43(8), 3727–3736.
        <a href="https://doi.org/10.1002/2016GL068058"
          >https://doi.org/10.1002/2016GL068058</a
        >
      </p>
      <p>
        Lancaster, K. J. (1966). A New Approach to Consumer Theory. Journal of
        Political Economy, 74(2), 132–157.
        <a href="https://doi.org/10.1086/259131"
          >https://doi.org/10.1086/259131</a
        >
      </p>
      <p>
        Ontl, T. A., Janowiak, M. K., Swanston, C. W., Daley, J., Handler, S.,
        Cornett, M., … Patch, N. (2020). Forest Management for Carbon
        Sequestration and Climate Adaptation. Journal of Forestry, 118(1),
        86–101.
        <a href="https://doi.org/10.1093/jofore/fvz062"
          >https://doi.org/10.1093/jofore/fvz062</a
        >
      </p>
      <p>
        Panagopoulos, T. (2009, August 15). Linking forestry, sustainability and
        aesthetics. Ecological Economics, Vol. 68, pp. 2485–2489.
        <a href="https://doi.org/10.1016/j.ecolecon.2009.05.006"
          >https://doi.org/10.1016/j.ecolecon.2009.05.006</a
        >
      </p>
      <p>
        Pohjanmies, T., Triviño, M., Le Tortorec, E., Salminen, H., &amp;
        Mönkkönen, M. (2017, December 1). Conflicting objectives in production
        forests pose a challenge for forest management. Ecosystem Services, Vol.
        28, pp. 298–310.
        <a href="https://doi.org/10.1016/j.ecoser.2017.06.018"
          >https://doi.org/10.1016/j.ecoser.2017.06.018</a
        >
      </p>
      <p>
        Profft, I., Mund, M., Weber, G. E., Weller, E., &amp; Schulze, E. D.
        (2009). Forest management and carbon sequestration in wood products.
        European Journal of Forest Research, 128(4), 399–413.
        <a href="https://doi.org/10.1007/s10342-009-0283-5"
          >https://doi.org/10.1007/s10342-009-0283-5</a
        >
      </p>
      <p>
        Shifley, S. R., Thompson, F. R., Dijak, W. D., &amp; Fan, Z. (2008).
        Forecasting landscape-scale, cumulative effects of forest management on
        vegetation and wildlife habitat: A case study of issues, limitations,
        and opportunities. Forest Ecology and Management, 254(3), 474–483.
        <a href="https://doi.org/10.1016/j.foreco.2007.08.030"
          >https://doi.org/10.1016/j.foreco.2007.08.030</a
        >
      </p>
      <p>
        Shifley, S. R., Thompson, F. R., Dijak, W. D., Larson, M. A., &amp;
        Millspaugh, J. J. (2006). Simulated effects of forest management
        alternatives on landscape structure and habitat suitability in the
        Midwestern United States. Forest Ecology and Management, 229(1–3),
        361–377.
        <a href="https://doi.org/10.1016/j.foreco.2006.04.030"
          >https://doi.org/10.1016/j.foreco.2006.04.030</a
        >
      </p>
      <p>
        US Census Bureau (2018). 2018 American Community Survey 1-Year
        Estimates. Available at
        <a href="https://data.census.gov">https://data.census.gov</a>
      </p>
      Wu, W., Li, Y., Hu, Y., Xiu, C., &amp; Yan, X. (2018). Impacts of Changing
      Forest Management Areas on Forest Landscapes and Habitat Patterns in
      Northeastern China. Sustainability, 10(4), 1211.
      <a href="https://doi.org/10.3390/su10041211"
        >https://doi.org/10.3390/su10041211</a
      >
    </div>
  </section>
</template>

<script>
import { Head } from '@vueuse/head'
export default {
  components: {
    Head
  }
}
</script>

<style></style>
